import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ForgotPass from './ForgotPass';
import Login from './Login';
import SignUp from './SignUp';
import ResetPassword from './ResetPassword';
import CreateAccount from './CreateAccount';
import ValidateEmail from './ValidateEmail';
import { accountService } from '../../Services';
import ModalPlatform from './ModalPlatform';

import ModalAccActivation from './Activation/ModalActivation';
import ModalPlatformCreate from './ModalPlatformCreate';



import { Pricing } from '../index';
export default function Account({ path, referPageUrl }) {
  const [modal, setModal] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [referId, setReferId] = useState(null);

  const [subModal, setSubModal] = useState(null);
  useEffect(() => {
    if (modal) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [modal]);
  let activeRoleId = "";
  if (accountService.userValue) {
    activeRoleId =
      accountService.userValue['data']['roles'].length > 0
        ? accountService.userValue['data']['roles'].filter(
          item => item['id'] == accountService.userValue['data']['active_role']
        )[0]['id']
        : '';
    if (activeRoleId == 1) {
      return <Navigate to='/influencer/dashboard' replace={true} />;
    } else if (activeRoleId == 3) {
      return <Navigate to='/brand/dashboard' replace={true} />;
    } else if (activeRoleId == 2) {
      return <Navigate to='/admin/dashboard' replace={true} />;
    } else if (activeRoleId == "") {
      return <Navigate to='/account/login' replace={true} />;
    }
  }

  return (
    <>
      <Routes>
        <Route path='/login' element={<Login setModal={setModal} referPageUrl={referPageUrl} />} />
        <Route path='/pricing' element={<Pricing referPageUrl={referPageUrl} setRoleId={setRoleId} />} />
        <Route path='/sign-up' element={<SignUp />} />
        <Route path='/signup' element={<CreateAccount setModal={setModal} setRoleId={setRoleId} setReferId={setReferId} />} />
        <Route path='/forgot-pass' element={<ForgotPass setModal={setModal} setRoleId={setRoleId} />} />
        <Route path='/reset-password' element={<ResetPassword setModal={setModal} setRoleId={setRoleId} />} />
        <Route path='/validateEmail' element={<ValidateEmail />} />

        <Route path='/*' element={<Login setModal={setModal} />} />
      </Routes>
      <AnimatePresence>
        {(modal === 'terms' || modal === 'termsCreate' || modal === "pricingTermsCreate") && (
          <ModalAccActivation
            roleId={roleId}

            close={() => {
              if (modal === 'terms') {
                setModal(null);
              } else if (modal === 'termsCreate') {
                //console.log(roleId)
                if (roleId !== "I'm a influencer") {
                  setModal('platformCreate');
                } else {
                  setModal(null);
                }

              } else if (modal === "pricingTermsCreate") {
                setModal('pricingPlatformCreate');
              }
            }}
          />
        )}
        {modal === 'platformCreate' && (
          <ModalPlatformCreate
            roleId={roleId}
            setRoleId={setRoleId}
            referId={referId}
            close={() => setModal(null)}
            setModal={setModal}

          />
        )}

        {
          (subModal === 'terms') && (
            <ModalAccActivation
              roleId={roleId}
              close={() => {
                if (subModal === 'terms') {
                  setSubModal(null);
                }
              }}
            />
          )
        }
      </AnimatePresence >
    </>
  );
}
import React, { useState, useEffect, useRef } from "react";
import CronDetailItem from "./CronDetailItem";
import { adminService } from "../../Services";

export default function CronDetail({ tabData, addToast, toastList }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [cronDetails, setCronDetails] = useState([]);
  useEffect(() => {
    async function fetchCronDetails() {
      try {
        //console.log(tabData.id);
        const cronData = await adminService.getCronIdLog(tabData.id);
        //setCronDetails([{ start: 'Mar 20 :10:00:01 ', end: 'Mar 21 :10:00:01', status: 'Mar 21 :11:00:01', log: 'Finished' }]);
        setCronDetails(cronData.data);
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchCronDetails();
  }, []);
  return (
    <>
      {isLoaded &&
        <div className="url__table">
          <table>
            <thead>
              <tr>
                <th>Start </th>
                <th>
                  <div className="url__table-filter">
                    End
                  </div>
                </th>
                <th>Status</th>
                <th>Download Log</th>
              </tr>
            </thead>
            {isLoaded && cronDetails.length > 0 ? (
              <tbody>
                {cronDetails.map((item, index) => {
                  return (
                    <CronDetailItem
                      key={index}
                      {...item}
                      addToast={addToast}
                      toastList={toastList}
                    />
                  );
                })}
              </tbody>
            ) : <tbody><tr> <td colSpan='4' style={{ textAlign: 'center' }}>
              No records
            </td></tr></tbody>}
          </table>
        </div>
      }
    </>
  );
}

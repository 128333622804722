import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import CryptoJS from "crypto-js";
import { TextInput, TextArea, SelectInput } from "../../../../Components";
import { storageHandler } from '../../../../Helpers';

import {
    productService,
    uploadService
} from '../../../../Services';

const initForm = {
    product: "",
    pieceOfContent: '',
    time: '',
    timespan: "",
    rate: "",
    platform_user_id: "",
};
const timeFrame = [{ 'id': 'seconds', 'value': 'Seconds' }, { 'id': 'minutes', 'value': 'Minutes' }, { 'id': 'hours', 'value': 'Hours' }]
export default function AddOffer({ user, setShowCart, instModul, fcbkModul, tiktokModul, youtubeModul, setModal, setTab, setToastList, toastList, setTabModal, setCartItem, cartItem, influencerProfile }) {
    const tagsWrapper = useRef(null);
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const inpRef = useRef(null);
    const createList = useRef(null);
    const fileRef = useRef(null);
    const [isAsset, setisAsset] = useState(false);
    const [form, setForm] = useState(initForm);
    const [error, setError] = useState(false);
    const [productList, setProductList] = useState([]);
    const [followersList, setFollowersList] = useState([]);
    const [selproduct, setSelProduct] = useState("");
    const [selPlatform, setSelPlatform] = useState("");
    const [seltimespam, setSelTimeSpan] = useState("");
    const [showFollowersDropdown, setShowFollowersDropdown] = useState(false);
    const [followersDropdown, setFollowersDropdown] = useState([]);
    const [singleAccountPlatform, setSingleAccountPlatform] = useState([]);


    const updateForm = (data) => {
        setForm({ ...form, ...data });
    };

    const setInput = (key) => (event) =>
        updateForm({ [key]: event.target.value });



    //console.log(productList)
    useEffect(() => {
        let platform = [];
        let platform_id_list = []
        //console.log(instModul)
        followersList.push({ 'instagram': [], 'facebook': [], 'youtube': [], 'tiktok': [] });
        platform_id_list.push({ 'instagram': [], 'facebook': [], 'youtube': [], 'tiktok': [] });
        if (instModul.length > 0) {
            instModul[0].rates.map(item => {
                platform.push({ 'id': item.id, 'value': item.rateName, 'label': item.rateName, 'platform': 'instagram' });
            })
            if (instModul.length > 1) {
                // let followersList = [];

                //console.log(followersList)
                instModul.map(followersData => {
                    //console.log(followersData)
                    followersList[0].instagram.push({ 'value': followersData.followers_count + ' Followers', 'id': followersData.platform_id, 'label': followersData.followers_count + ' Followers' })
                })


                //platform.push(instModul[0].rates)
            } else {
                platform_id_list[0].instagram.push({ 'id': instModul[0].platform_id })
                //setSelPlatform(instModul[0].platform_id)
            }

        }
        if (fcbkModul.length > 0) {
            fcbkModul[0].rates.map(item => {
                platform.push({ 'id': item.id, 'value': item.rateName, 'label': item.rateName, 'platform': 'facebook' });
            })
            //console.log(fcbkModul)
            if (fcbkModul.length > 1) {
                //followersList.push({ 'faceBookFollowers': [] });
                fcbkModul.map(followersData => {
                    followersList[0].facebook.push({ 'value': followersData.followers_count + ' Followers', 'id': followersData.platform_id, 'label': followersData.followers_count + ' Followers' })
                })

            } else {
                platform_id_list[0].facebook.push({ 'id': fcbkModul[0].platform_id })
            }

        }
        if (youtubeModul.length > 0) {
            youtubeModul[0].rates.map(item => {
                platform.push({ 'id': item.id, 'value': item.rateName, 'label': item.rateName, 'platform': 'youtube' });
            })
            if (youtubeModul.length > 1) {
                //followersList.push({ 'youtubeFollowers': [] });
                youtubeModul.map(followersData => {
                    followersList[0].youtube.push({ 'value': followersData.followers_count + ' Followers', 'id': followersData.platform_id, 'label': followersData.followers_count + ' Followers' })
                })
            } else {
                platform_id_list[0].youtube.push({ 'id': youtubeModul[0].platform_id })
            }

        }
        if (tiktokModul.length > 0) {
            tiktokModul[0].rates.map(item => {
                platform.push({ 'id': item.id, 'value': item.rateName, 'label': item.rateName, 'platform': 'tiktok' });
            })
            if (tiktokModul.length > 1) {
                //followersList.push({ 'tiktokFollowers': [] });
                tiktokModul.map(followersData => {
                    followersList[0].tiktok.push({ 'value': followersData.followers_count + ' Followers', 'id': followersData.platform_id, 'label': followersData.followers_count + ' Followers' })
                })
            } else {
                platform_id_list[0].tiktok.push({ 'id': tiktokModul[0].platform_id })
            }

        }
        //console.log(platform)
        setProductList(platform)
        setFollowersList(followersList)
        setSingleAccountPlatform(platform_id_list)
        //console.log(followersList)
        setShowCart(true)
    }, []);

    const encryptData = (data, secretKey) => {
        return CryptoJS.AES.encrypt(data, secretKey).toString();
    };


    const submitBtnClick = () => {
        //console.log(form)

        if (form.product == "" || form.time == "" || form.timespan == "" || form.rate == "" || form.pieceOfContent == '') {
            setError(true);
            //addToast({ error: true, date: new Date(), msg: 'Enter details to add List.' });
        } else {
            if (showFollowersDropdown === true) {
                if (form.platform_user_id === "") {
                    setError(true);
                }
            }
            // saveList(form);
            let productId = productList.filter((item) =>
                item.id === form.product
            )
            //console.log(productId)
            let followersCount = showFollowersDropdown ? selPlatform.value.split(" ") : [];
            let newObject = ({
                'offer_type': 'custom',
                'followers_count': showFollowersDropdown ? followersCount[0] : '',
                'influencerAccountCount': showFollowersDropdown ? followersDropdown.length : 1,
                'platform_user_id': form.platform_user_id,
                'description': form.pieceOfContent + " " + productId[0].value + " (" + form.time + " " + form.timespan + ")" + (showFollowersDropdown ? "- Followerscount -" + followersCount[0] : ''),

                'profileName': influencerProfile.firstName + " " + influencerProfile.lastName,
                'profileImage': influencerProfile.profile_image,
                'price': form.rate, 'to_user_id': influencerProfile.id, 'product': productId[0].value, 'product_id': form.product,
                'time': form.time, 'timespan': form.timespan, 'pieceOfContent': form.pieceOfContent,
                'quantity': form.pieceOfContent,
                'to_user_type': influencerProfile.inf_user_type,
                'cart_item_addedBy': user.data.id,
            })

            setCartItem( // Replace the state
                [ // with a new array
                    ...cartItem, // that contains all the old items
                    newObject // and one new item at the end
                ]
            );
            let encrypteddata = (encryptData(JSON.stringify([ // with a new array
                ...cartItem, // that contains all the old items
                newObject // and one new item at the end
            ]), secretKey));
            //setCartItem(prevArray => [...prevArray, newObject]);
            /*storageHandler.setCartItem(JSON.stringify([ // with a new array
                ...cartItem, // that contains all the old items
                newObject // and one new item at the end
            ]));*/
            storageHandler.setCartItem(encrypteddata);
            setModal(null)

        }
    };

    /*async function fetchProductData(platform) {
        try {
            const res = await productService.getAll({});
            let prd = [];
            console.log(res)
            res.data.map(item => {
                prd.push({ 'id': item.id, 'value': item.name, 'label': item.name });
            })
            //setProductList(prd)
        } catch (err) {
            addToast({
                error: true,
                msg: err,
                id: `${Date.now()}-${toastList.length}`
            });
        }
    }
    useEffect(() => {
        fetchProductData();
    }, []);*/
    const addToast = data => {
        const newToastList = [...toastList];
        if (data.msg) {
            if (data.msg[data.msg.length - 1] === ".")
                data.msg = data.msg.slice(0, -1);
        }
        newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
        setToastList(newToastList);
    };


    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                createList.current.click();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [form]);
    const changeSpanTime = (item) => {
        setSelTimeSpan(item);
        updateForm({ 'timespan': item.id })


    };
    const changeSelProduct = (item) => {
        setSelProduct(item);
        updateForm({ 'product': item.id, 'platform_user_id': '' });

        //console.log(form)
        if (followersList[0][item.platform].length > 0) {

            setShowFollowersDropdown(true)
            //console.log(followersList[0])
            setFollowersDropdown(followersList[0][item.platform])
        } else {
            // setFollowersDropdown(platform_id_list[0][item.platform])
            let platformId = singleAccountPlatform[0][item.platform][0].id;
            //updateForm({ 'product': item.id, 'platform_user_id': '' });
            setShowFollowersDropdown(false)
            updateForm({ 'product': item.id, 'platform_user_id': platformId });
        }
        //console.log(item)

    };
    const changeSelHandle = (item) => {
        setSelPlatform(item);
        updateForm({ 'platform_user_id': item.id })


    };

    //console.log(selPlatform)

    return (
        <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            exit={{ opacity: 0, y: 30 }}
            className="assetsAdd"
        >
            <div className="assetsAdd__form">
                <div className="assetsAdd__form-title">
                    <h3 className="big">Create a Custom Offer</h3>
                </div>
                <div className="assetsAdd__form-inner">
                    <div className="assetsAdd__form-head">
                        <SelectInput
                            errorText="Please enter what kind of content do you need?"
                            list={productList}
                            selected={productList.filter(item => item.id === form.product)[0]}
                            onChange={changeSelProduct}
                            label="What kind of content do you need?"
                            error={error}
                            value={form.product}
                        />
                    </div>
                </div>
                {showFollowersDropdown &&
                    <div className="assetsAdd__form-inner">
                        <div className="assetsAdd__form-head">
                            <SelectInput
                                errorText="Please select account"
                                list={followersDropdown}
                                selected={followersDropdown.filter(item => item.id === form.platform_user_id)[0]}
                                onChange={changeSelHandle}
                                label="Select Account"
                                error={error}
                                value={form.platform_user_id}
                            />
                        </div>
                    </div>}
                <div className="assetsAdd__form-inner">
                    <div className="assetsAdd__form-head">
                        <TextInput
                            onChange={setInput("pieceOfContent")}
                            type="number"
                            label="How many pieces of content do you need?"
                            errorText="Please enter how many pieces of content do you need"
                            readOnly={false}
                            error={error}
                            value={form.pieceOfContent}
                        />
                    </div>
                </div>
                <div className="assetsAdd__form-inner">
                    <div className="addOffer">
                        <TextInput
                            onChange={setInput("time")}
                            type="number"
                            label="How long should the content be?"
                            errorText="Please enter how long should the content be"
                            readOnly={false}
                            error={error}
                            value={form.time}
                        />
                        <SelectInput
                            onChange={changeSpanTime}
                            label="TimeFrame"
                            list={timeFrame}

                            error={error}
                            value={form.timespan}

                        />
                    </div>
                </div>
                <div className="assetsAdd__form-inner">
                    <div className="assetsAdd__form-head">
                        <TextInput
                            onChange={setInput("rate")}
                            type="number"
                            label="Set a price for your offer"
                            errorText="Please enter Price for your offer"
                            readOnly={false}
                            error={error}
                            value={form.rate}
                        />
                    </div>
                </div>
            </div>
            <div class="campaignsCreate__btns">
                <div class="campaignsCreate__btns-row"></div>
                <button ref={createList} className="button primary" onClick={submitBtnClick}  >
                    Add To Cart
                </button></div>
        </motion.div>
    );
}

import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '../../Components';
import { Pagination } from '../../Components';
import { Profile } from '../../Components';
import { arrowRight, arrowTop, download, Toast } from '../../Components';
import { YearToDate } from '../../Components';
import CardDash from './Card/CardDash';
import DashboardItem from './DashboardItem';
import { NotificationItem } from '../../Components';
import { useNavigate } from 'react-router-dom';
//import { useNavigate } from "react-router-dom";
import { ModalOpportunity } from '../../Pages';
import { AnimatePresence } from 'framer-motion';
import { influencerService } from '../../Services';
import { utils } from '../../Helpers';
const ytdList = [
  { id: '0', value: 'Year to date' },
  { id: '1', value: 'Past 1 month' },
  { id: '6', value: 'Past 6 months' },
  { id: '12', value: 'Past 12 months' }
];

export default function Dashboard({
  NotifModul,
  setNotifModul,
  deleteNotif,
  user,
  profiles,
  setModalSwitch,
  updateNotificationStatus,
  setModalNotification,
  setModalNotificationData,
  accountCountNotification
}) {
  // eslint-disable-next-line
  const [dateRange, setDateRange] = useState(0);
  const [dashboardData, setDashboardData] = useState({});
  const [dashboardEarning, setDashboardEarning] = useState({});
  const [isloaded, setIsLoaded] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [page, setPage] = useState(1);
  const [featuredOpportunities, setFeaturedOpportunities] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');
  const [isEarningLoaded, setIsEarningLoaded] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [modal, setModal] = useState(null);
  const [accountCount, setAccountCount] = useState(null);
  const [max, setMax] = useState(null);
  const width = useMediaQuery();
  const influencerName = user ? (user.data ? user.data.firstName : null) : null;
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchDashboardData() {
      try {
        //console.log("in here")
        const res = await influencerService.getDashboardData({});
        setDashboardData(res);
        setAccountCount(res.account_count ? res.account_count : 1);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchDashboardData();
  }, []);

  useEffect(() => {
    async function fetchDashboardEarningData() {
      try {
        const res = await influencerService.getDashboardData({
          page: page,
          pageSize: 5,
          range: dateRange
        });
        setDashboardEarning(res.earnings.data);
        if (res.earnings.meta && res.earnings.meta.pagination) {
          setPaginationData(res.earnings.meta.pagination);
        }
        setIsEarningLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchDashboardEarningData();
  }, [page, dateRange]);
  useEffect(() => {

    if (dashboardData.opportunities) {
      let allOpportunities = dashboardData.opportunities;
      let featuredOpportunities = allOpportunities.filter(
        item => item.is_featured && item.is_featured === 1
      );
      if (featuredOpportunities.length === 0) {
        featuredOpportunities = allOpportunities.splice(0, 3);
      }
      const max =
        width <= 1180 && featuredOpportunities.length > 2
          ? 2
          : featuredOpportunities.length > 3
            ? 3
            : featuredOpportunities.length;
      setMax(max);
      setFeaturedOpportunities(featuredOpportunities.splice(0, max));
      console.log(featuredOpportunities);
      setIsLoaded(true);
    }
  }, [dashboardData, width]);


  const downloadCsv = async () => {
    try {
      const response = await influencerService.getDashboardData({
        page: page,
        pageSize: 5,
        range: dateRange,
        fileType: 'csv'
      });
      utils.downloadFile(response, "dashboardEarning_page_" + page + ".csv");
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }

  const onPaginate = page => {
    setPage(page);
  };

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };

  const updateOnCollaborate = async () => {
    try {
      const res = await influencerService.getDashboardData({});
      let allOpportunities = res.opportunities;
      setDashboardData(res);
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        date: new Date()
      });
    }
  };

  const maxPlaceholder = width <= 1180 ? 2 : 3;
  const NotifMax = width <= 1180 && NotifModul.length > 2 ? 2 : 3;
  const NotifMaxPlaceholder = width <= 1180 ? 2 : 3;
  const showNotifPlacholder = NotifMaxPlaceholder - NotifModul.length;
  const changeMedium = item => {
    setDateRange(item.id);
    setPage(1);
    setSortOrder('desc');
  };
  return (
    <>
      <div className='main'>
        <div className='main__inner'>
          <div className='main__inner-title'>
            <h2>Hey {influencerName}!</h2>
            <Profile
              user={user}
              setModal={setModalSwitch}
              profiles={profiles}
              NotifModul={NotifModul}
              setNotifModul={setModalNotification}
            />
          </div>
          {isloaded && (
            <div className='dashboard'>
              <div className='dashboard__inner'>
                <div className='dashboard__row'>
                  <div className='dashboard__col blue'>
                    <div className='dashboard__col-title'>
                      <h5>Current opportunities</h5>
                      <button
                        onClick={() => navigate('/influencer/opportunities')}
                        className='button tertiary'
                      >
                        View all {arrowRight}
                      </button>
                    </div>
                    <div className='dashboard__col-content'>
                      <div className='dashboard__col-content-item'>
                        <h2>
                          {dashboardData
                            ? dashboardData.profileSummary.totalOpportunity
                            : '0'}
                        </h2>
                        <p className='sm'>Total</p>
                      </div>
                      <div className='dashboard__col-content-item'>
                        <h2>
                          {dashboardData
                            ? dashboardData.profileSummary.opportunity
                            : '$0'}
                        </h2>
                        <p className='sm'>Total revenue</p>
                      </div>
                    </div>
                  </div>
                  <div className='dashboard__col green'>
                    <div className='dashboard__col-title'>
                      <h5>Active collaborations</h5>
                      <button
                        onClick={() => navigate('/influencer/collaborations', { state: { 'engStatus': 'active' } })}
                        className='button tertiary'
                      >
                        View all {arrowRight}
                      </button>
                    </div>
                    <div className='dashboard__col-content'>
                      <div className='dashboard__col-content-item'>
                        <h2>
                          {dashboardData
                            ? dashboardData.profileSummary.totalEarnings
                            : '0'}
                        </h2>
                        <p className='sm'>Total</p>
                      </div>
                      <div className='dashboard__col-content-item'>
                        <h2>
                          {dashboardData
                            ? dashboardData.profileSummary.earnings
                            : '$0'}
                        </h2>
                        <p className='sm'>Total revenue</p>
                      </div>
                    </div>
                  </div>
                  <div className='dashboard__col yellow'>
                    <div className='dashboard__col-title'>
                      <h5>Completed collaborations</h5>
                      <button className='button tertiary'
                        onClick={() => navigate('/influencer/collaborations', { state: { 'engStatus': 'paid' } })}
                      >
                        View all {arrowRight}
                      </button>
                    </div>
                    <div className='dashboard__col-content'>
                      <div className='dashboard__col-content-item'>
                        <h2>{dashboardData ? dashboardData.profileSummary.completedCollabration : '0'}</h2>
                        <p className='sm'>Total</p>
                      </div>
                      <div className='dashboard__col-content-item'>
                        <h2>{dashboardData ? dashboardData.profileSummary.completedCollabrationRevenue : '0'}</h2>
                        <p className='sm'>Total revenue</p>
                      </div>
                    </div>
                  </div>
                </div>
                {featuredOpportunities && (
                  <div className='dashboard__cards-outer'>
                    <div className='dashboard__cards-title'>
                      <h4>
                        <span>Featured</span> brand opportunities
                      </h4>
                      {featuredOpportunities && (
                        <button
                          onClick={() => navigate('/influencer/opportunities')}
                          className='button tertiary'
                        >
                          View all {arrowRight}
                        </button>
                      )}
                    </div>
                    <div className='dashboard__cards'>
                      {featuredOpportunities.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <CardDash
                              titleOverview='Overview'
                              type='dash'
                              openModal={() => {
                                setModalData(item);
                                setModal('opportunity');
                              }}
                              index={index}
                              accountCount={accountCount}
                              key={index}
                              last={featuredOpportunities.length === 1}
                              {...item}
                            ></CardDash>

                          </React.Fragment>
                        );
                      })}
                      {maxPlaceholder - max === 1 && (
                        <div className='dashboard__cards-placeholder'>
                          <div className='dashboard__cards-placeholder-image'>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                '/images/placeholder/cardPlaceholder.png'
                              }
                              alt='cardPlaceholder'
                            />
                          </div>
                          <h6>More opportunities coming soon!</h6>
                        </div>
                      )}
                      {maxPlaceholder - max === 2 && (
                        <>
                          <div className='dashboard__cards-placeholder'>
                            <div className='dashboard__cards-placeholder-image'>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  '/images/placeholder/cardPlaceholder.png'
                                }
                                alt='cardPlaceholder'
                              />
                            </div>
                            <h6>More opportunities coming soon!</h6>
                          </div>
                          <div className='dashboard__cards-placeholder'>
                            <div className='dashboard__cards-placeholder-image'>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  '/images/placeholder/cardPlaceholder.png'
                                }
                                alt='cardPlaceholder'
                              />
                            </div>
                            <h6>More opportunities coming soon!</h6>
                          </div>
                        </>
                      )}
                      {maxPlaceholder - max === 3 && (
                        <>
                          <div className='dashboard__cards-placeholder'>
                            <div className='dashboard__cards-placeholder-image'>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  '/images/placeholder/cardPlaceholder.png'
                                }
                                alt='cardPlaceholder'
                              />
                            </div>
                            <h6>More opportunities coming soon!</h6>
                          </div>
                          <div className='dashboard__cards-placeholder'>
                            <div className='dashboard__cards-placeholder-image'>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  '/images/placeholder/cardPlaceholder.png'
                                }
                                alt='cardPlaceholder'
                              />
                            </div>
                            <h6>More opportunities coming soon!</h6>
                          </div>
                          <div className='dashboard__cards-placeholder'>
                            <div className='dashboard__cards-placeholder-image'>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  '/images/placeholder/cardPlaceholder.png'
                                }
                                alt='cardPlaceholder'
                              />
                            </div>
                            <h6>More opportunities coming soon!</h6>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className='dashboard__table-outer'>
                  <div className='dashboard__table-title'>
                    <h4>Earnings</h4>
                    <YearToDate
                      list={ytdList}
                      selected={ytdList[0]}
                      onChange={changeMedium}
                    />
                  </div>
                  <div className='dashboard__table'>
                    <table>
                      <thead>
                        <tr>
                          <th>Brand</th>
                          <th>Date</th>
                          <th>
                            {accountCount > 1 ? "Account" : "Description"}
                          </th>
                          <th>Compensation</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(isEarningLoaded && dashboardEarning.length > 0) ? (
                          <>
                            {dashboardEarning.map((item, index) => {
                              return <DashboardItem
                                key={index}
                                {...item}
                                accountCount={accountCount}
                              />;
                            })}
                          </>
                        ) : (
                          <tr>
                            <td colSpan='4' style={{ textAlign: 'center', fontWeight: 'normal' }}>
                              No records
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {dashboardEarning.length > 0 && (
                    <div className='dashboard__table-footer'>
                      <button className='button tertiary download' onClick={downloadCsv}>
                        Download CSV {download}
                      </button>
                      <Pagination
                        perPage={paginationData['per_page']}
                        total={paginationData['total']}
                        currentPage={paginationData['current_page']}
                        totalPages={paginationData['total_pages']}
                        onPaginate={onPaginate}
                      />
                    </div>
                  )}
                </div>
                <div className='notification__outer'>
                  <div className='notification__title'>
                    <h4>Notifications</h4>
                    {NotifModul.length > 0 &&
                      <button
                        className='button tertiary'
                        onClick={() => setModalNotification('notifications')}
                      >
                        View all {arrowRight}
                      </button>}
                  </div>
                  <div className='notification'>
                    {[...NotifModul].splice(0, NotifMax).map((item, index) => {
                      return (
                        <NotificationItem
                          NotifModul={NotifModul}
                          setNotifModul={setModalNotification}
                          accountCount={accountCountNotification}
                          deleteNotif={deleteNotif}
                          updateNotificationStatus={updateNotificationStatus}
                          setModalNotification={setModalNotification}
                          setModalNotificationData={setModalNotificationData}
                          setModalData={() => {
                            setModalNotificationData(item);
                            setModalNotification('notifDetailDash');
                          }}
                          key={index}
                          {...item}
                        />
                      );
                    })}
                    {showNotifPlacholder > 0 && (
                      <div
                        className={
                          'notification__placeholder ' +
                          (showNotifPlacholder === (width <= 1180 ? '' : 2)
                            ? 'half '
                            : '') +
                          (showNotifPlacholder === (width <= 1180 ? 2 : 3)
                            ? 'full '
                            : '')
                        }
                      >
                        <div className='notification__placeholder-image'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/images/placeholder/notificationPlaceholder.png'
                            }
                            alt='notificationPlaceholder.png'
                          />
                        </div>
                        <h6>
                          {showNotifPlacholder === (width <= 1180 ? 2 : 3)
                            ? 'You don’t have any notifications yet.'
                            : 'That’s all the notifications for now!!'}
                        </h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div >
      <AnimatePresence>
        {modal === 'opportunity' && (
          <ModalOpportunity
            opportunity={modalData}
            setModal={setModal}
            accountCount={accountCount}
            setModalData={setModalData}
            close={() => setModal(null)}
            setToastList={setToastList}
            toastList={toastList}
            updateOnCollaborate={updateOnCollaborate}
          />
        )}
      </AnimatePresence>

      {toastList && <Toast list={toastList} setList={setToastList} />}
    </>
  );
}
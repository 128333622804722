import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Modal, SelectInput } from '../../Components';

const selectList = [
  { id: '3', value: "I'm a brand" },

];

export default function ModalPricingPlatformCreate(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState(false);
  // let roleType = searchParams.get('type') ? searchParams.get('type') : null;
  // roleType =
  //   roleType !== null && roleType == 1 ? "I'm a influencer" : "I'm a brand";
  //let refId = (props.referId === null || props.referId === undefined) ? 0 : props.referId;





  const handleLoginWithGoogle = () => {
    if (!isChecked) {
      setError(true);
    }
    else {
      let roleID = 3;
      // window.location.href = `${process.env.REACT_APP_API_URL}/login/google/action/signup/role/${roleID}/${props.subsProductId}/subscription`;
      window.location.href = `${process.env.REACT_APP_API_URL}/login/google/action/signup/role/3/0/referalPath/pricing/1`;
    }
  };

  return (
    <Modal addClass='platform' close={props.close}>
      <div className='modal__platform'>
        <h3 className="accountType"></h3>
        <div className='checkbox'>
          <input type='checkbox' checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
          <label htmlFor=''></label>
          <p>
            Accept{' '}
            <span onClick={() => props.setModal('pricingTermsCreate')}>
              Terms & Conditions
            </span>
          </p>
          {error && !isChecked && (
            <div className="checkbox__error">You have to accept terms</div>
          )}
        </div>


        <button
          className='button'
          onClick={handleLoginWithGoogle}
        >
          <img src={process.env.PUBLIC_URL + '/images/googleSign.png'} alt='' />
          Sign up using Google
        </button>

      </div>
    </Modal>
  );
}
import React, { useState, useEffect } from "react";
import {
  plusIcon,
  Pagination,
  SelectInput,
  SearchInput,
  searchIcon,

} from "../../Components";
import {

  useNavigate
} from 'react-router-dom';
const selectDescList = [
  { id: "", value: "All List" },
  { id: "name", value: "Name" },


];


export default function ListMain({
  setTab,
  setModalDataList,
  paginationData,
  onPaginate,
  listAll,
  isLoaded,
  changeMediumDesc,
  setSearchTextOnChange,
  search,
  searchText,
  selectDesc,
  setTabModal,
  setShowCart
}) {

  const [view, setView] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        if (searchText != "") {
          search();
        }
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [searchText]);
  const exploreList = () => {
    navigate("/brand/influencerdiscovery");
  }
  return (
    <div className="assets">
      <div className="assets__table-title">
        <h4 className="mob">Details</h4>
        <div className="assets__table-title-info">
          <button class="explore-button" onClick={() => exploreList()}>Explore Influencers</button>
          <button className="button tertiary big" onClick={() => { setShowCart(false); setTabModal("add") }}>
            Add List {plusIcon}
          </button>

        </div>
        <div className="assets__table-title-inner">
          <SelectInput
            list={selectDescList}
            selected={selectDescList.filter(item => item.id === selectDesc)[0]}
            onChange={changeMediumDesc}
            value={selectDesc}
          />
          <SearchInput
            type="text"
            value={searchText}
            icon={searchIcon}
            onClick={search}
            onChange={setSearchTextOnChange}
            placeholder="Search"
          />
        </div>
      </div>
      <>
        <div className="inflist__table">
          {/* <div className="header_explore">
            <button class="explore-button" onClick={() => exploreList()}>Explore Influencers</button>
          </div>*/}
          <div className="grid">
            {(isLoaded && listAll.length > 0) ? (
              <>
                {listAll.map((item, index) => {
                  return (
                    <div
                      className="card"
                      style={{ backgroundImage: item.image == "" ? `url( ${process.env.PUBLIC_URL}/images/placeholder/list_image.jpg)` : `url(${item.image}` }}
                      onClick={() => { setModalDataList(item); setShowCart(false); setTab("listinfluencer") }}
                    >
                      <div className="card-text">
                        <h3>{item.name}</h3>
                        <p>{item.infcount} influencer</p>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : ((isLoaded &&
              <div>No List Found</div>))}
          </div>
        </div>
      </>

      <div className="assets__table-footer">
        {(isLoaded && listAll.length > 0) && (
          <>
            <Pagination
              perPage={paginationData['per_page']}
              total={paginationData['total']}
              currentPage={paginationData['current_page']}
              totalPages={paginationData['total_pages']}
              onPaginate={onPaginate}
            />
          </>
        )}
      </div>
    </div >
  );
}

import React, { useState, useEffect } from 'react';
import { Modal, TextInput, TextArea } from '../../../../Components';
import {
  influencerService
} from '../../../../Services';
export default function ModalConfirmDeleteInfluencer(props) {
  //console.log(props)
  return (
    <Modal addClass="modalAsset" close={props.close}>
      <div className="modal__asset">
        <div className="modal__asset-inner">
          <div className="modal__assetDetail-delete">
            <h4>Are you sure you want to remove Influencer from the list?</h4>
            <div className="modal__assetDetail-delete-btns">
              <button
                className="button secondary"
                onClick={props.close}
              >
                Cancel
              </button>
              <button className="button primary" onClick={() => props.removeFromList(props.selectedInfluencer)}>
                Remove
              </button>
            </div>
          </div>

        </div>
      </div>
    </ Modal>)
}
import React, { useEffect, useRef, useState } from "react";
import Modal from "../../Components/Base/Modal";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary, {
  accordionSummaryClasses,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  tickSymbol,
  cancelCircleicon,
  SelectInput,
  TextInput,
  closeIcon,
  chevronBottom2,
} from "../../Components";

const selectbystate = [
  { id: "United States", value: "United States" },
  { id: "Canada", value: "Canada" },
  { id: "Australia", value: "Australia" },
  { id: "Mexico", value: "Mexico" },
];
const selectbyregion = [
  { id: "Alaska", value: "Alaska" },
  { id: "Alaska", value: "Alaska" },
  { id: "India", value: "India" },
];

const categoriesList = [
  { id: "Alaska", value: "Alaska" },
  { id: "Alaska", value: "Alaska" },
  { id: "India", value: "India" },
];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
  {
    transform: "rotate(90deg)",
  },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ModalLocation(props) {
  //console.log(props)
  const [expanded, setExpanded] = React.useState("panel1");
  const inputWrapper = useRef(null);
  const [list, setList] = useState([]);
  const [focus, setFocus] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const inpRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [selCountry, setSelCounty] = useState([]);
  const [selTempCountry, setSelTempCounty] = useState([]);
  const setInterests = (obj) => {
    const foundItem = () => {
      // return categories.filter(
      //   (item) => item.toUpperCase() === obj.value.toUpperCase()
      // );
    };
    if (foundItem().length !== 0) {
      // let newCategory = [...form.category];
      // let selCategory = categoriesList.find(cat => cat.category.toUpperCase() === obj.value.toUpperCase());
      // newCategory.push(selCategory.id);
      // updateForm({ category: newCategory });
      // closeSearch();
    }
  };
  const optionClickHandler = (item) => {

    let newitem = props.selectedLocationData.country;
    console.log(newitem.push(item))
    console.log(newitem)
    props.setSelectedLocationData({ ...props.setSelectedLocationData, ['country']: newitem });
    //setSelTempCounty(newitem)
    closeSearch();
  };
  const saveLocation = () => {
    //props.setSelectedLocationData({ ...props.setSelectedLocationData, ['country']: selTempCountry });
  }
  const handleChange = (panel) => (event, newExpanded) => {
    if (panel == 'panel2') {
      props.setSelectedLocationData({ ...props.setSelectedLocationData, ['country']: [] });
    }
    setExpanded(newExpanded ? panel : false);
  };
  const initForm = {
    selCounty: [],
  };
  //console.log(list)
  const [form, setForm] = useState(initForm);
  const delCountry = (label) => {
    let newCountry = [...props.selectedLocationData.country];
    let index = newCountry.indexOf(label);
    newCountry.splice(index, 1);
    //setTempCountry
    props.setSelectedLocationData({ ...props.setSelectedLocationData, ['country']: newCountry });
    //updateForm({ category: newIndustries });
  };
  const closeSearch = () => {
    setSearchValue("");
    setFocus(false);
    //setList(categories);
    inpRef.current.value = "";
    inpRef.current.blur();
  };
  useEffect(() => {
    let newList = [];
    props.countries.map(item => newList.push(item.label))
    setList(newList);
    const windowClick = ({ target }) => {
      if (!inputWrapper.current.contains(target)) closeSearch();
    };

    if (focus) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);
    return () => window.removeEventListener("click", windowClick);
  }, [focus]);

  const handlePress = (event) => {
    const inputItem = event.target;
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      setSelCounty(inputItem);
      return;
    }
  };
  const searchListFunc = (text) => {
    return list.filter((item) =>
      item.toUpperCase().startsWith(text.toUpperCase())
    );
  };
  const handleChangeTextInput = (event) => {
    const list = searchListFunc(event.target.value);
    setSearchValue(event.target.value);
    setList(list);
  };
  const handleClear = () => {
    // props.setSelectedLocationData
    //setSelTempCounty([]);
    props.setSelectedLocationData({ ...props.setSelectedLocationData, ['country']: [] });

  }
  //console.log(props.selectedLocationData.country)
  return (
    <Modal addClass="modalCamp" close={props.close}>
      <div className="filterModal modal__camp">
        <div className="modal__assetDetail-delete perm long">
          <h4>Location</h4>
          <div className="accordion-wrapper">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography component="span">Filter by Country</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div
                    className="recomForm__industry filter-country"
                    ref={inputWrapper}
                  >
                    {/*<TagInput
                      btnClickHandler={setInterests}
                      optionClickHandler={optionClickHandler}
                      inpRef={inpRef}
                      onKeydown={handlePress}
                      type="text"
                      label="Industry(s)"
                      icon={chevronBottom2}
                      list={form.industries}
                      autoCompleteList={list}
                      searchValue={searchValue}
                      searchList={list}
                      onFocus={() => setFocus(true)}
                      showList={focus}
                      onChange={handleChange}
                    />*/}
                    <TextInput
                      btnClickHandler={() => setFocus(!focus)}
                      optionClickHandler={optionClickHandler}
                      inpRef={inpRef}
                      onKeydown={handlePress}
                      type="text"
                      icon={chevronBottom2}
                      list={form.selCounty}
                      autoCompleteList={list}
                      searchValue={searchValue}
                      searchList={props.countries}
                      onFocus={() => setFocus(true)}
                      showList={focus}
                      onChange={handleChangeTextInput}
                      readOnly={readOnly}
                    />
                    <>
                      {props.selectedLocationData.country.length > 0 && (
                        <div className="recomForm__industry-items">
                          {props.countries.map((item, index) => {

                            let obj = props.selectedLocationData.country.find(cat => (cat) === item.label);
                            console.log(obj)
                            if (obj) {
                              return (
                                <div className="recomForm__industry-item" key={item.value}>
                                  {item.label}
                                  {!readOnly && <div
                                    className="recomForm__industry-item-icon"
                                    onClick={() => delCountry(item.label)}
                                  >
                                    {closeIcon}
                                  </div>
                                  }
                                </div>
                              );
                            }
                          })

                          }
                        </div>)}
                    </>
                  </div>
                  <button className="location-clear" onClick={handleClear}>Clear</button>
                </Typography>
              </AccordionDetails>
            </Accordion>
            {/* <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography component="span">Filter by Region</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="by-region">
                    <SelectInput list={selectbystate} />
                    <SelectInput list={selectbyregion} />
                    <button className="location-clear">Clear</button>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography component="span">Filter by City</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="by-region">
                    <SelectInput list={selectbyregion} />
                    <button className="location-clear">Clear</button>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>*/}
          </div>

          <div className="modal__assetDetail-delete-btns">
            <button className="button primary" onClick={() => { saveLocation(); props.handleSearch() }}>
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

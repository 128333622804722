import React, { useState, useEffect } from "react";
import { Toast, Pagination, SelectInput, SelectInputFilter, ModalConfirmDeleteInfluencer, ModalAddInfToList } from "../../Components";

import 'rc-slider/assets/index.css';
import ModalFollowers from "./ModalFollowers";
import ModalLocation from "./ModalLocation";
import ModalPrice from "./ModalPrice";
import ModalAge from "./ModalAge";
import ModalEthnicity from "./ModalEthnicity";
import ModalLanguage from "./ModalLanguage";
import { AnimatePresence } from "framer-motion";
import Select from 'react-dropdown-select';
import Modal from "../../Components/Base/Modal";
import CardInfluencerSearch from "./CardInfluencerSearch";


import AddListandInfluencerToList from "./AddListandInfluencerToList";
import {
  influencerlistService,

} from '../../Services';

//export Slider;
export default function SearchInfMain({ setIsUserHasAccessListing, onPaginate, paginationData, selectedPrice, selectedFollowersCount, influncerList, profiles, setStartSearch, startSearch, setSelectedCategory, selectedLocationData, setSelectedLocationData, countries, selectedEthenicity, selectedLanguage, isUserHasSubscription, selectedGender, setSelectedGender, clearAll, setSelectedPrice, categoriesData, setSelectedFollowersCount, setSelectedAge, selectedAge, setSelectedProduct, selectedPlatform, platform, productList, setSelectedEthenicity, setSelectedLanguage, changeSelectedPlatform, selectedProduct }) {
  //console.log(selectedFollowersCount)
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modal, setModal] = useState(null);
  const [genderIsOpen, setGenderIsOpen] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [selectedInfluencer, setSelectedInfluencer] = useState({});
  const [productSel, setProductSel] = useState([]);
  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ];
  const gender = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: "Other", label: "Other" },
  ];

  const toggleDropdown = () => {
    setGenderIsOpen(false);
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    //console.log(selectedPlatform)
    if (selectedPlatform == '' || selectedPlatform === 'Any' || productList.length == 0) {
      setIsOpen(false);
    }
  }, [selectedPlatform, productList]);
  const handleCheckboxChange = (option) => {
    //console.log(option)
    if (selectedProduct.includes(option.id)) {
      setSelectedProduct(selectedProduct.filter((item) => item !== option.id));
    } else {
      setSelectedProduct([...selectedProduct, option.id]);
    }
  };
  const removeFromList = async (influencer) => {
    //console.log(influencer)
    try {
      let option = { 'listId': influencer.list_id, 'influencer': influencer.id, 'action': 'unassign', 'infType': influencer.inf_user_type }
      //console.log(option)
      const res = await influencerlistService.removeFromList(option);
      addToast({
        error: false,
        msg: res.message,
        id: `${Date.now()}-${toastList.length}`
      });
      handleSearch();
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const handleButtonClick = () => {
    // Do something with the selected options
    //console.log('Selected options:', selectedOptions);
    setSelectedProduct([])
  };
  const handleSearch = () => {

    //console.log("here ==")
    setStartSearch(!startSearch)
    setModal(null)
    setIsOpen(false)
    setGenderIsOpen(false);
  }
  const handleSearchNew = () => {

    // console.log("here ==")
    //setStartSearch(!startSearch)
    setModal(null)
    setIsOpen(false)
    setGenderIsOpen(false);
  }
  const handleClearButtonClick = () => {
    // Do something with the selected options
    //console.log('Selected options:', selectedOptions);
    setSelectedGender('')
  };

  const changeSelectedGender = (item) => {
    setSelectedGender(item.value)
  }

  const toggleDropdownGender = () => {
    setIsOpen(false)
    setGenderIsOpen(false);
    setGenderIsOpen(!genderIsOpen);
  };

  const showFollowerModal = () => {
    setIsOpen(false)
    setGenderIsOpen(false);
    setModal("followers");
  };
  const showLocationModal = () => {
    setIsOpen(false)
    setGenderIsOpen(false);
    setModal("location");
  };
  const showPriceModal = () => {
    setIsOpen(false)
    setGenderIsOpen(false);
    setModal("price");
  };
  const showAgeModal = () => {
    setIsOpen(false)
    setGenderIsOpen(false);
    setModal("age");
  };
  const showEthnicityModal = () => {
    setIsOpen(false)
    setGenderIsOpen(false);
    setModal("ethnicity");
  };
  const showLanguageModal = () => {
    setIsOpen(false)
    setGenderIsOpen(false);
    setModal("language");
  };
  const addToast = data => {
    const newToastList = [...toastList];
    if (data.msg) {
      if (data.msg[data.msg.length - 1] === ".")
        data.msg = data.msg.slice(0, -1);
    }
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };


  //console.log(categoriesData)
  //console.log(selectedLocationData)
  return (
    <>
      <div className="searchcontainer">
        {profiles === undefined &&
          <div class="headerMarketing">
            <h1 className="marketingHeading">Find the Perfect Influencer – Effortlessly</h1>
            <p>Easily discover and connect with the ideal influencers to create unique content for your brand
            </p>
          </div>
        }
        <div className="searchbar-wrap">
          <div className="search-bar">
            <SelectInputFilter
              selected={platform.filter(item => item.id === selectedPlatform).length > 0 ? platform.filter(item => item.id === selectedPlatform)[0] : ''}
              onChange={changeSelectedPlatform}
              list={platform}
              value={platform.filter(item => item.id === selectedPlatform).length > 0 ? platform.filter(item => item.id === selectedPlatform)[0].name : 'Any'}
              setIsOpen={setIsOpen}
              setGenderIsOpen={setGenderIsOpen}
            />
            <div className="devider"></div>
            <>
              <Select
                multi
                labelField="category"
                valueField="id"
                options={categoriesData}
                onChange={(value) => setSelectedCategory(value)}
                values={[]}
                searchBy="category"
                searchable={true}
              />
            </>

            <button className="search-btn" onClick={handleSearch}>
              <svg
                data-slot="icon"
                fill="none"
                stroke-width="1.5"
                stroke="#FFFFFF"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                width={22}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        <div className="filters">
          <div className="sort-button-wrap" >
            <button className={"sort-button" + (((selectedProduct.length > 0 && isOpen == false) || (selectedProduct.length > 0 && isOpen == true)) ? " sort-button-selected" : '')}
              onClick={toggleDropdown} disabled={selectedPlatform === null || selectedPlatform === 'Any' || selectedPlatform === ""}>Content Type
              <svg
                data-slot="icon"
                className="down-arrow"
                fill="none"
                stroke-width="1.5"
                stroke="currentColor"
                width="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                ></path>
              </svg>
            </button>
            {isOpen && (
              <div className="dropdown-menu">
                {productList.map((option) => (
                  <div key={option.name} className="dropdown-item" >
                    <input
                      type="checkbox"
                      checked={selectedProduct.includes(option.id)}
                      onChange={() => handleCheckboxChange(option)}
                    /><span onClick={() => handleCheckboxChange(option)}>{option.name}</span>

                  </div>
                ))}
                <div className="dropdown-btns">
                  <button onClick={handleButtonClick}>Clear</button>
                  <button onClick={handleSearchNew}>Save</button>
                </div>
              </div>
            )}
          </div>
          <div className="sort-button-wrap">
            <button
              disabled={selectedPlatform === null || selectedPlatform === 'Any' || selectedPlatform === ""}
              id="followersButton"

              className={"sort-button" + (selectedFollowersCount.length > 0 ? " sort-button-selected" : '')}
              onClick={showFollowerModal}
            >
              Followers
              <svg
                data-slot="icon"
                className="down-arrow"
                fill="none"
                stroke-width="1.5"
                stroke="currentColor"
                width="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                ></path>
              </svg>
            </button>
          </div>
          <div className="sort-button-wrap">
            <button
              id="locationButton"
              className={"sort-button" + (selectedLocationData.country.length > 0 && modal == null ? " sort-button-selected" : '')}
              onClick={showLocationModal}
            >
              Location
              <svg
                data-slot="icon"
                className="down-arrow"
                fill="none"
                stroke-width="1.5"
                stroke="currentColor"
                width="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                ></path>
              </svg>
            </button>
          </div>
          <div className="sort-button-wrap">
            <button
              id="priceButton"
              className={"sort-button" + (selectedPrice.length > 0 ? " sort-button-selected" : '')}
              onClick={showPriceModal}
            >
              Price
              <svg
                data-slot="icon"
                className="down-arrow"
                fill="none"
                stroke-width="1.5"
                stroke="currentColor"
                width="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                ></path>
              </svg>
            </button>
          </div>


          <div className="sort-button-wrap">
            <button className={"sort-button" + (selectedGender !== "" && genderIsOpen == false ? " sort-button-selected" : '')} onClick={toggleDropdownGender}>
              Gender
              <svg
                data-slot="icon"
                className="down-arrow"
                fill="none"
                stroke-width="1.5"
                stroke="currentColor"
                width="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                ></path>
              </svg>
            </button>
            {genderIsOpen && (
              <div className="dropdown-menu">
                <div className="dropdown-item gender-select">

                  <SelectInput
                    selected={gender.filter(item => item.value === selectedGender)[0]}
                    onChange={changeSelectedGender}
                    list={gender}
                    value={selectedGender}
                  />
                </div>
                <div className="dropdown-btns">
                  <button onClick={handleClearButtonClick}>Clear</button>
                  <button onClick={() => { handleSearchNew(); }}>Save</button>
                </div>
              </div>
            )}
          </div>
          <div className="sort-button-wrap">
            <div className="premium-tag">Premium</div>
            <button id="ageButton" className={"sort-button" + (selectedAge !== "" ? " sort-button-selected" : '')} onClick={showAgeModal}>
              Age
              <svg
                data-slot="icon"
                className="down-arrow"
                fill="none"
                stroke-width="1.5"
                stroke="currentColor"
                width="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                ></path>
              </svg>
            </button>
          </div>
          <div className="sort-button-wrap">
            <div className="premium-tag">Premium</div>
            <button
              id="ethnicityButton"
              className={"sort-button" + (selectedEthenicity !== "" ? " sort-button-selected" : '')}
              onClick={showEthnicityModal}
            >
              Ethnicity
              <svg
                data-slot="icon"
                className="down-arrow"
                fill="none"
                stroke-width="1.5"
                stroke="currentColor"
                width="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                ></path>
              </svg>
            </button>
          </div>
          <div className="sort-button-wrap">
            <div className="premium-tag">Premium</div>
            <button
              id="languageButton"
              className={"sort-button" + ((selectedLanguage !== "") ? " sort-button-selected" : '')}
              onClick={showLanguageModal}
            >
              Language
              <svg
                data-slot="icon"
                className="down-arrow"
                fill="none"
                stroke-width="1.5"
                stroke="currentColor"
                width="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                ></path>
              </svg>
            </button>
          </div>
          <button onClick={clearAll} className="clearall-btn">Clear All</button>

        </div>
        {profiles === undefined &&
          <div className="featured">
            <h2>Featured</h2>
            <p>Collaborate with top influencers across all platforms</p>
          </div>}
        <div className="grid">
          {influncerList.length > 0 &&
            influncerList.map(influencer => {
              return <CardInfluencerSearch handleSearch={handleSearch} setToastList={setToastList} toastList={toastList} addToast={addToast} setModal={setModal} profiles={profiles} setSelectedInfluencer={setSelectedInfluencer} influencer={influencer} isUserHasSubscription={isUserHasSubscription} setIsUserHasAccessListing={setIsUserHasAccessListing}></CardInfluencerSearch>

            })}
        </div>
        {influncerList.length == 0 &&
          <div>No Influencer Found</div>
        }
        {paginationData && influncerList.length > 0 &&
          <div className='dashboard__table-footer'>
            <Pagination
              perPage={paginationData['per_page']}
              total={paginationData['total']}
              currentPage={paginationData['current_page']}
              totalPages={paginationData['total_pages']}
              onPaginate={onPaginate}
            />
          </div>}
        <AnimatePresence>
          {modal === "followers" && (
            <ModalFollowers close={() => setModal(null)}
              setSelectedFollowersCount={setSelectedFollowersCount} handleSearch={handleSearch} selectedFollowersCount={selectedFollowersCount} />
          )}
          {modal === "addtoList" && (
            <ModalAddInfToList setToastList={setToastList} toastList={toastList} close={() => setModal(null)} setModal={setModal} selectedInfluencer={selectedInfluencer} handleSearch={handleSearch} />
          )}
          {modal === "addList" && (
            <Modal addClass="modalCamp" close={() => setModal(null)}>
              <div className="filterModal modal__camp">
                <AddListandInfluencerToList setToastList={setToastList} toastList={toastList} setTabModal={setModal} selectedInfluencer={selectedInfluencer} handleSearch={handleSearch} />
              </div>
            </Modal>

          )}

          {modal === "location" && <ModalLocation close={() => setModal(null)} countries={countries} setSelectedLocationData={setSelectedLocationData} selectedLocationData={selectedLocationData} handleSearch={handleSearch} />}
          {modal === "price" && <ModalPrice handleSearch={handleSearch} close={() => setModal(null)} setSelectedPrice={setSelectedPrice} selectedPrice={selectedPrice} />};
          {modal === "age" && <ModalAge handleSearch={handleSearch} close={() => setModal(null)} isUserHasSubscription={isUserHasSubscription} setSelectedAge={setSelectedAge} selectedAge={selectedAge} />};
          {modal === "ethnicity" && (
            <ModalEthnicity handleSearch={handleSearch} close={() => setModal(null)} isUserHasSubscription={isUserHasSubscription} setSelectedEthenicity={setSelectedEthenicity} selectedEthenicity={selectedEthenicity} />
          )}
          ;
          {modal === "language" && <ModalLanguage close={() => setModal(null)} isUserHasSubscription={isUserHasSubscription} setSelectedLanguage={setSelectedLanguage} selectedLanguage={selectedLanguage} handleSearch={handleSearch} />}
          ;
          {modal === "confirmDelete" && (
            <ModalConfirmDeleteInfluencer removeFromList={removeFromList} setToastList={setToastList} toastList={toastList} close={() => setModal(null)} setModal={setModal} selectedInfluencer={selectedInfluencer} />
          )}
        </AnimatePresence>
      </div >
      {toastList && <Toast list={toastList} setList={setToastList} />
      }
    </>
  );
}
import { fetchWrapper } from '../Helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}/reports`;

export const reportService = {
  getAll,
  updateStatus,
  saveReport,
  getReport,
  updateReport,
  getTokenData,
  getReportStats

};
function getAllPaging(options, filterString) {
  filterString += filterString.length > 0 ? `&` : '';
  filterString += `page[number]=${options.page}`;
  if (options.pageSize) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[size]=${options.pageSize}`;
  }
  return filterString;
}
function getAll(options) {

  let filterString = '';
  if (options.filter) {
    filterString = options.filter;
  }
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  const url =
    filterString.length > 0 ? `${baseUrl}?${filterString}` : `${baseUrl}`;
  return fetchWrapper.get(url);
}

function updateStatus(reportId, status) {
  return fetchWrapper.put(`${baseUrl}/updatestatus/${reportId}`, { 'status': status });

}
function saveReport(param) {
  return fetchWrapper.post(`${baseUrl}`, param);

}
function updateReport(param, id) {
  return fetchWrapper.put(`${baseUrl}/${id}`, param);

}
function getReport(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);

}
function getTokenData() {
  //debugger
  return fetchWrapper.get(`${baseUrl}` + "/getTokendata");

}
function getReportStats(id) {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}` + "/getReportStats/" + id);

}



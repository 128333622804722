import { fetchWrapper } from '../Helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}` + "/influencerlist";
export const influencerlistService = {

  getAll,
  getById,
  create,
  update,
  delete: _delete,
  getAllInfluencer,
  removeFromList,
  assignToList,
  createListandAddInfluencer,
  getInfluencerForList,
  getSocialMedia
};




function getAll(options) {
  let filterString = '';
  if (options.filter) {
    filterString = options.filter;
  }
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  const url =
    filterString.length > 0 ? `${baseUrl}?${filterString}` : `${baseUrl}`;
  return fetchWrapper.get(url);
}

function getAllPaging(options, filterString) {
  console.log(options)
  filterString += filterString.length > 0 ? `&` : '';
  filterString += `page[number]=${options.page}`;
  if (options.pageSize) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[size]=${options.pageSize}`;
  }
  return filterString;
}
function getById(id) {
  return fetchWrapper.get(`${baseUrl}/getById/${id}`);
}
function getSocialMedia() {
  return fetchWrapper.get(`${baseUrl}/getSocialMedia/`);
}

function getInfluencerForList(id) {
  console.log("=====to get " + id)
  return fetchWrapper.get(`${baseUrl}/getAllInf/${id}`);
}

function getAllInfluencer(id, options) {
  let filterString = '';
  if (options.filter) {
    filterString = options.filter;
  }
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  const url =
    filterString.length > 0 ? `${baseUrl}/getAllInf/${id}?${filterString}` : `${baseUrl}/getAllInf/${id}`;
  console.log(url);

  return fetchWrapper.get(url);
  //return fetchWrapper.get(`${baseUrl}/getAllInf/${id}`);
}

function createListandAddInfluencer(params) {
  return fetchWrapper.post(baseUrl + "/addlistandinf", params);
}

function create(params) {
  console.log(params)
  return fetchWrapper.post(baseUrl + "/add", params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  console.log(id)
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}
function removeFromList(params) {
  console.log(params)
  return fetchWrapper.post(baseUrl + "/status", params);
}
function assignToList(params) {
  console.log(params)
  return fetchWrapper.post(baseUrl + "/status", params);
}



import React, { useState } from "react";
import { SearchInput, SelectInput } from "../../Components";

import {
  arrowTop,
  download,
  plusIcon,
  searchIcon,
  arrowBottom
} from "../../Components";
import { Pagination } from "../../Components";


import ReportItem from './ReportItem';
const selectDescList = [
  { id: "title", value: "Title" },

  { id: "status", value: "Status" },

];
export default function ReportMain({
  setTab,
  reportData,
  disableSearchBox,
  paginationData,
  onPaginate,
  searchString,
  search,
  setSearchStringText,
  changeMediumDesc,
  editReport,
  openViewReport
}) {



  return (
    <div className='billing dashboard'>


      <div className='billing__table-title'>
        <button className="button tertiary big"
          onClick={() => { setTab("create") }}>
          Add Report {plusIcon}
        </button>
        <div className='billing__table-title-inner'>
          <SelectInput
            list={selectDescList}
            selected={selectDescList[0]}
            onChange={changeMediumDesc}
          />
          <SearchInput
            type='text'
            icon={searchIcon}
            placeholder='Search'
            onClick={search}
            onChange={setSearchStringText}
            value={searchString}
          />

        </div>
      </div>

      <div className='billing__table report'>
        <table>
          <thead>
            <tr>
              <th className={"titleWidth"}>Title</th>
              <th># of Influencers</th>
              <th>Timeframe</th>
              <th>Token Used</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {reportData.length > 0 ? (
              <>
                {reportData.map((item, index) => {
                  return (
                    <ReportItem
                      openViewReport={openViewReport}
                      editReport={editReport}
                      key={index}
                      item={item}


                    />
                  )
                })}</>) : (
              <tr>
                <td colSpan='6' style={{ textAlign: 'center' }}>
                  No records
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='campaigns__table-footer paginationEnd'>

        {reportData && reportData.length > 0 &&
          <Pagination
            perPage={paginationData['per_page']}
            total={paginationData['total']}
            currentPage={paginationData['current_page']}
            totalPages={paginationData['total_pages']}
            onPaginate={onPaginate}
          />
        }
      </div>
    </div>
  );
}

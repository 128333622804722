import React, { useEffect, useRef, useState } from "react";
import { chevronBottom, closeIcon } from "./SVG";
import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import CryptoJS from "crypto-js";
import { storageHandler } from "../../Helpers"
import { searchProfileService, offerService } from '../../Services';
export default function Cart({ user, updateProfilePage, setUpdateProfilePage, subTotalAmt, setSubTotalAmt, checkout, setTotalToPay, totalToPay, availableBalance, setCartItem, showCart, cartItem, setShowCart, removeCartItem }) {


  const [showConfirm, setShowConfirm] = useState(false)
  const [removeItemId, setRemoveItemId] = useState(null)
  const navigate = useNavigate();
  //const [subTotal, setSubTotal] = useState(0)
  const [projected, setProjected] = useState(0)
  const [cartItemSaved, setCartItemSaved] = useState([])
  const [fromAvailableBalance, setFromAvailableBalance] = useState()

  const [reloadCart, setReloadCart] = useState(false)
  const secretKey = process.env.REACT_APP_SECRET_KEY;

  useEffect(() => {
    let decryptedData = storageHandler.getCartItem();

    //let storedCartItem = storageHandler.getCartItem()
    // console.log(storedCartItem)
    if (decryptedData !== null) {
      const storedCartItem = decryptData(decryptedData, secretKey);
      let getcartItem = [];
      let getcartItem_base = storedCartItem.length > 0 && JSON.parse(storedCartItem);
      getcartItem = getcartItem_base.filter(item => item.cart_item_addedBy === user.data.id)

      setCartItemSaved(getcartItem)
      let subtotalVal = 0;
      getcartItem.length > 0 &&
        getcartItem.map(item => {
          subtotalVal = Number(subtotalVal) + (Number(item.price))
        })
      //console.log(subtotal)

      if (availableBalance !== "" && availableBalance !== null) {
        let subTotalShow = subtotalVal > availableBalance ? availableBalance : subtotalVal;
        setFromAvailableBalance(subTotalShow)
        setTotalToPay(Number(subtotalVal) - Number(subTotalShow))
      }


      setSubTotalAmt(subtotalVal)
      setProjected(subtotalVal / 2);
    }



  }, [cartItem, showCart, availableBalance]);

  const explodeInfluencer = () => {
    setShowCart(false)
    navigate("/brand/influencerdiscovery")

  }

  const showProfileForUSer = (userType, id) => {


    setUpdateProfilePage(!updateProfilePage)
    storageHandler.setUserTypeForProfileItem(JSON.stringify(userType))
    navigate("/brand/influencerProfile/" + id)
  }

  const encryptData = (data, secretKey) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
  };

  const decryptData = (cipherText, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };
  return (
    <div className={showCart ? "openCart" : "closeCart"}>
      <div class="modal__inner-close" onClick={() => setShowCart(false)}><svg width="20" height="20" viewBox="0 0 20 20" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z" fill="currentColor"></path></svg></div>
      <div className="sidebar-cart" >
        <div className="sidebar-cart-header">
          <div className="sidebar-cart-title">Cart</div>

        </div>
        {cartItemSaved.length > 0 ?
          <>
            <div className="search-checkout-added" >
              <div className="search-checkout-cart-items js-cart-items">
                {cartItemSaved.length > 0 &&
                  cartItemSaved.map((cartItemLoop, index) => {
                    return <div className="search-checkout-item-holder">
                      <div className="search-checkout-item">


                        <img className="showProfileImgCursor" onClick={() => showProfileForUSer(cartItemLoop.to_user_type, cartItemLoop.to_user_id)} src={
                          cartItemLoop.profileImage && cartItemLoop.profileImage !== ''
                            ? cartItemLoop.profileImage
                            : `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`
                        }

                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`;
                          }} />
                        <div className="search-checkout-item-info-holder" >
                          <div className="search-checkout-item-title-holder">
                            <div className="search-checkout-item-title">
                              {cartItemLoop.offer_type == "custom" ?
                                cartItemLoop.pieceOfContent ? cartItemLoop.pieceOfContent + ' ' : '' : ''}
                              {cartItemLoop.product}

                              {cartItemLoop.offer_type == "custom" ?
                                cartItemLoop.time ?
                                  cartItemLoop.time !== "" ? " (" + cartItemLoop.time + ' ' + cartItemLoop.timespan + ")" : '' : '' :
                                ''}</div>
                            <div className="search-checkout-item-price">${cartItemLoop.price}</div>
                          </div>
                          <div className="search-checkout-item-name">{cartItemLoop.profileName} {cartItemLoop.influencerAccountCount > 1 && "(" + cartItemLoop.followers_count + " Followers)"}</div>
                        </div>
                      </div>
                      {((index != removeItemId) || (removeItemId == null)) ?
                        <div className="remove-cart" onClick={() => { setRemoveItemId(index); setShowConfirm(true); }}>Remove</div> :
                        <div className="remove-cart" onClick={() => { setShowConfirm(false); removeCartItem(index); setRemoveItemId(null) }}>Confirm</div>
                      }
                    </div>
                  })
                }
              </div>
              <div className="search-checkout-cart-number-holder">
                <div className="cart-price-row">
                  <div>Subtotal</div>
                  <div className="subtotal">${subTotalAmt}</div>
                </div>
                {availableBalance > 0 &&
                  <div className="cart-price-row">
                    <div>From Available Balance</div>
                    <div className="subtotal">${fromAvailableBalance}</div>
                  </div>}
                <div className="cart-price-row">
                  <div>Grand Total</div>
                  <div className="subtotal">${totalToPay}</div>
                </div>
              </div>
              <div className="search-checkout-estimate-holder search-checkout-width-holder">
                {cartItemSaved.length >= 2 &&
                  <div className="cart-price-row total-cart-price-row search-checkout-estimate-projected">
                    <div>Projected Spend</div>
                    <div className="projected-total">${projected}</div>
                  </div>}
                <div className="search-checkout-estimate-txt search-checkout-estimate-projected">On average, <b class="acceptance-rate">
                  50% of offers are accepted</b>. Therefore, your projected spend is <span class="projected-total">${projected}</span>. Your total spend can still be reached if all offers are accepted.</div>

              </div>
            </div>

            <div className="sidebar-cart-footer"><button className='button primary' onClick={checkout}>
              Checkout
            </button></div></> : <>
            <div className="search-checkout-added" >
              <div className="search-checkout-cart-items js-cart-items"><div className="search-checkout-item-holder">
                <div >
                  <div className="sidebar-cart-title emptyCart">Cart is empty</div>
                  <div className="emptyCart noItemText"> Start adding influencers by clicking the button below</div>

                  <div className="sidebar-cart-footer"><button className='button primary' onClick={explodeInfluencer}>
                    Explore Influencer
                  </button></div>
                </div></div></div>
            </div></>
        }

      </div>

    </div >
  );
}

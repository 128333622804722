import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import CryptoJS from "crypto-js";
import { TextInput, TextArea, SelectInput, MoneyInput } from "../../Components";
import { storageHandler } from '../../Helpers';

import {
    influencerService,
    productService,
    influencerlistService
} from '../../Services';

const initForm = {
    product: "",
    pieceOfContent: '',
    time: '',
    timespan: "",
    rate: "",
    platform_user_id: "",
};
const timeFrame = [{ 'id': 'seconds', 'value': 'Seconds' }, { 'id': 'minutes', 'value': 'Minutes' }, { 'id': 'hours', 'value': 'Hours' }]
export default function AddOffer({ user, setShowCart, listInfluencer, listDetails, setModal, setTab, setToastList, toastList, setTabModal, setCartItem, cartItem, influencerProfile }) {

    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const tagsWrapper = useRef(null);
    const inpRef = useRef(null);
    const createList = useRef(null);
    const fileRef = useRef(null);
    const [form, setForm] = useState(initForm);
    const [error, setError] = useState(false);
    const [productList, setProductList] = useState([]);
    const [selproduct, setSelProduct] = useState("");
    const [selPlatform, setSelPlatform] = useState("");
    const [seltimespam, setSelTimeSpan] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [totalAmountOfOffer, setTotalAmountOfOffer] = useState(0);
    const [countOfInfluencer, setCountOfInfluencer] = useState(0);
    const [allInfluencerInList, setAllInfluencerInList] = useState(listInfluencer);
    const [socialMediaData, setSocialMediaData] = useState();
    const [infValidForCustomOffer, setInfValidForCustomOffer] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const updateForm = (data) => {
        setForm({ ...form, ...data });
    };
    const setInput = (key) => (event) => {
        updateForm({ [key]: event.target.value });
        if (key === 'rate') {
            setTotalAmountOfOffer(countOfInfluencer * event.target.value)
        }
    }
    useEffect(() => {
        updateForm({ ['rate']: 100 });
    }, []);
    useEffect(() => {
        updateForm({ ['rate']: 100 });
    }, []);
    const encryptData = (data, secretKey) => {
        return CryptoJS.AES.encrypt(data, secretKey).toString();
    };
    useEffect(() => {
        async function fetchData() {
            try {
                const res = await influencerlistService.getSocialMedia();
                if (res) {
                    setSocialMediaData(res)
                }
            } catch (err) {
                addToast({
                    error: true,
                    msg: err,
                    id: `${Date.now()}-${toastList.length}`
                });
            }
        }
        fetchData();
    }, []);
    const findInfluencerForProductSelected = (selproduct) => {
        let allInfEligibleForProduct = [];
        let selProductSocialMediaData = socialMediaData.filter(item => item.id === selproduct.socialId)
        console.log(selProductSocialMediaData[0].name)

        allInfluencerInList.map(item => {
            let inf = (item.account.filter(user => user.account_type === selProductSocialMediaData[0].name));
            allInfEligibleForProduct.push({ 'account': inf, 'name': item.name, profileImage: item.image });
        })
        let total = 0
        allInfEligibleForProduct.map(influencerDetailsForAccount => {
            total = total + influencerDetailsForAccount.account.length;
        })
        setInfValidForCustomOffer(allInfEligibleForProduct)
        setCountOfInfluencer(total);
        if (total === 0) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
        setTotalAmountOfOffer(total * form.rate)
    }
    const submitBtnClick = () => {
        if (form.product == "" || form.time == "" || form.timespan == "" || form.rate == "" || form.pieceOfContent == '') {
            setError(true);
            //addToast({ error: true, date: new Date(), msg: 'Enter details to add List.' });
        } else {
            let productId = productList.filter((item) =>
                item.id === form.product
            )
            let allCartNeedToAdd = [];
            infValidForCustomOffer.map(influencerDetailsForAccount => {
                influencerDetailsForAccount.account.map(influencerDetails => {
                    const filteredCount = influencerDetailsForAccount.account.length;

                    let newObject = ({
                        'offer_type': 'custom',
                        'time': form.time,
                        'timespan': form.timespan,
                        'pieceOfContent': form.pieceOfContent,
                        'quantity': form.pieceOfContent,
                        'price': form.rate,
                        'to_user_type': influencerDetails.user_type,
                        'followers_count': influencerDetails.followers,
                        'influencerAccountCount': filteredCount,
                        'description': form.pieceOfContent + " " + productId[0].value + " (" + form.time + " " + form.timespan + ")" + (filteredCount > 1 ? "- Followerscount -" + influencerDetails.followers : ''),
                        'product_id': form.product,
                        'product': productId[0].value,
                        'platform_user_id': influencerDetails.id,
                        'profileName': influencerDetailsForAccount.name,
                        'profileImage': influencerDetailsForAccount.profileImage,
                        'cart_item_addedBy': user.data.id,
                        'to_user_id': influencerDetails.user_type == 'registered' ? influencerDetails.user_id : influencerDetails.ds_user_id,
                    })
                    allCartNeedToAdd.push(newObject)
                })
            })
            //console.log(allCartNeedToAdd);
            setCartItem( // Replace the state
                [ // with a new array
                    ...cartItem, // that contains all the old items
                    ...allCartNeedToAdd // and one new item at the end
                ]
            );

            let encrypteddata = (encryptData(JSON.stringify([ // with a new array
                ...cartItem, // that contains all the old items
                ...allCartNeedToAdd // and one new item at the end
            ]), secretKey));
            //setCartItem(prevArray => [...prevArray, newObject]);
            storageHandler.setCartItem(encrypteddata);
            setTabModal(null);
            setShowCart(true);
        }
    };

    async function fetchProductData(platform) {
        try {
            const res = await productService.getAll({});
            let prd = [];
            res.data.map(item => {
                prd.push({ 'id': item.id, 'value': item.name, 'label': item.name, 'socialId': item.social_media_id });
            })
            setProductList(prd)
        } catch (err) {
            addToast({
                error: true,
                msg: err,
                id: `${Date.now()}-${toastList.length}`
            });
        }
    }
    useEffect(() => {
        fetchProductData();
    }, []);
    const addToast = data => {
        const newToastList = [...toastList];
        if (data.msg) {
            if (data.msg[data.msg.length - 1] === ".")
                data.msg = data.msg.slice(0, -1);
        }
        newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
        setToastList(newToastList);
    };
    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                createList.current.click();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [form]);
    const changeSpanTime = (item) => {
        setSelTimeSpan(item);
        updateForm({ 'timespan': item.id });
    };
    const changeSelProduct = (item) => {
        setSelProduct(item);
        updateForm({ 'product': item.id, });
        findInfluencerForProductSelected(item)
    };
    return (
        <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            exit={{ opacity: 0, y: 30 }}
            className="assetsAdd"
        >
            <div className="assetsAdd__form">
                <div className="assetsAdd__form-title">
                    <h3 className="big">Create a Custom Offer</h3>
                </div>
                <div className="assetsAdd__form-inner">
                    <div className="assetsAdd__form-head">
                        <SelectInput
                            errorText="Please enter what kind of content do you need?"
                            list={productList}
                            selected={productList.filter(item => item.id === form.product)[0]}
                            onChange={changeSelProduct}
                            label="What kind of content do you need?"
                            error={error}
                            value={form.product}
                        />
                    </div>
                </div>
                <div className="assetsAdd__form-inner">
                    <div className="assetsAdd__form-head">
                        <TextInput
                            onChange={setInput("pieceOfContent")}
                            type="number"
                            label="How many pieces of content do you need?"
                            errorText="Please enter how many pieces of content do you need"
                            readOnly={false}
                            error={error}
                            value={form.pieceOfContent}
                        />
                    </div>
                </div>
                <div className="assetsAdd__form-inner">
                    <div className="addOffer">
                        <TextInput
                            onChange={setInput("time")}
                            type="number"
                            label="How long should the content be?"
                            errorText="Please enter how long should the content be"
                            readOnly={false}
                            error={error}
                            value={form.time}
                        />
                        <SelectInput
                            onChange={changeSpanTime}
                            label="TimeFrame"
                            list={timeFrame}
                            error={error}
                            value={form.timespan}

                        />
                    </div>
                </div>
                <div className="assetsAdd__form-inner">
                    <div className="addCalculation">

                        <MoneyInput
                            label="Set a price for each Influencer"
                            type="number"
                            currency="$"
                            error={error}
                            value={form.rate}
                            disabled={false}
                            readOnly={false}
                            onChange={setInput('rate')}
                        />
                    </div>
                    <div className="input__outer countofOfferCalculation">
                        <div className="calulationOperation2"> X</div>
                        <div><TextInput
                            label="# of Influencers"
                            readOnly={true}
                            value={countOfInfluencer}
                        /></div>
                        <div className="calulationOperation2">=</div>
                        <div className="calulationOperation"><TextInput
                            readOnly={true}
                            label="Total"
                            value={"$" + totalAmountOfOffer}
                        /></div>
                    </div>
                </div>
            </div>
            <div class="campaignsCreate__btns">
                <div class="campaignsCreate__btns-row"></div>
                <button ref={createList} disabled={disableButton} className="button primary" onClick={submitBtnClick}  >
                    Add To Cart
                </button></div>
        </motion.div>
    );
}

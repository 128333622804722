import { fetchWrapper } from '../Helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}/subsproduct`;

export const subProductService = {
    getAll,
    cancelUrl

};

function getAll(options) {


    const url = `${baseUrl}`;
    return fetchWrapper.get(url);
}
function cancelUrl(cancelUrl) {


    const url = cancelUrl
    return fetchWrapper.get(url);
}






import React, { useEffect, useState } from 'react';
import { TextInput, SelectInputFilter, DateInput, calendarIcon, closeIconRed, addnewRow } from "../../Components";
export default function AddInfluencer({ infError, error, data, setData, setInput, startDate, endDate, status }) {
    console.log(data)

    const [totalToken, setTotalToken] = useState(0);
    const [isDisabled, setIsDisabled] = useState((startDate !== "" && endDate !== "") ? false : true);
    const diffInDaysCount = (dt2, dt1) => {
        if (dt2 !== "" && dt1 !== "") {
            let findDiffInDays = Math.floor(
                (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
                    Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
                (1000 * 60 * 60 * 24)

            );
            return findDiffInDays;
        }
    }
    const handleAddRow = () => {
        if (startDate !== "" && endDate !== "") {
            const dt1 = new Date(startDate);
            const dt2 = new Date(endDate);
            let findDiffInDays = 0;
            findDiffInDays = diffInDaysCount(dt2, dt1);
            setData([...data, { key: Date.now(), hash_tag: '', influencer_handle: '', infHandle: '', token: (Number(findDiffInDays) + 1) }]);
        } else {
            setData([...data, { key: Date.now(), hash_tag: '', influencer_handle: '', infHandle: '', token: "-" }]);
        }
        //debugger
        if (startDate !== "" && endDate !== "") {
            const dt1 = new Date(startDate);
            const dt2 = new Date(endDate);
            const newArray = [...data];
            let findDiffInDays = 0;
            findDiffInDays = diffInDaysCount(dt2, dt1);
            let totalToken = findDiffInDays + 1;
            data.map(item => {
                totalToken = totalToken + item.token;
            })
            setTotalToken(totalToken)
        }

    };
    const removeRow = (index) => {
        const newArray = [...data];
        newArray.splice(index, 1);
        setData(newArray);
        if (startDate !== "" && endDate !== "") {
            let totalToken = 0
            newArray.map(item => {
                totalToken = totalToken + item.token;
            })
            setTotalToken(totalToken)
        }

    };
    const handleChange = (event, index, name) => {
        let findDiffInDays = 0;
        if (startDate !== "" && endDate !== "") {
            const dt1 = new Date(startDate);
            const dt2 = new Date(endDate);
            findDiffInDays = diffInDaysCount(dt2, dt1);
        }
        const updatedData = [...data];
        console.log(event.target)
        if (name == "hashtag") {
            updatedData[index].hash_tag = event.target.value;

        } else {
            updatedData[index].influencer_handle = event.target.value;
            updatedData[index].infHandle = event.target.value;
        }
        if (startDate !== "" && endDate !== "") {
            updatedData[index].token = Number(findDiffInDays) + 1;
        } else {
            updatedData[index].token = "-";
        }
        setData(updatedData);
    };
    useEffect(() => {
        //console.log(status)
        setIsDisabled((status !== 'archived') ? false : true);
        const dt1 = new Date(startDate);
        const dt2 = new Date(endDate);
        //debugger
        let findDiffInDays = diffInDaysCount(dt2, dt1);
        let newData = [];
        let totalToken = 0;
        if (startDate !== "" && endDate !== "" && data !== undefined) {
            data.map(item => {
                item.token = Number(findDiffInDays) + Number(1);
                newData.push(item)
                totalToken = totalToken + item.token;
            })
            setData(newData)
            setTotalToken(totalToken)
        } else {
            setTotalToken("-")
        }

    }, [startDate, endDate])
    return (
        <div>
            <div>
                {data !== undefined &&
                    data.map((item, index) => (
                        <div className="reportMain1">
                            <TextInput
                                type="text"
                                label={index == 0 ? "Handle" : ''}
                                value={item.infHandle}
                                errorText="Please enter Handle"
                                placeholder="myhandle"
                                onChange={(event) => handleChange(event, index, 'handle')}
                                readOnly={isDisabled}
                            />
                            <TextInput
                                type="text"
                                label={index == 0 ? "Hashtag" : ''}
                                value={item.hash_tag}
                                errorText="Please enter Hashtag"
                                placeholder="#mytag, #youtag"
                                onChange={(event) => handleChange(event, index, 'hashtag')}
                                readOnly={isDisabled}
                            />
                            <TextInput
                                style={{ border: '0px' }}
                                type="text"
                                label={index == 0 ? "Token Needed" : ''}

                                readOnly={true}
                                value={item.token}
                            />
                            <button title="Add New row" className="addInfButton" onClick={() => handleAddRow()}
                                disabled={isDisabled}
                            >
                                {addnewRow}
                            </button>
                            <button disabled={(data.length) == 1 ? true : isDisabled}
                                title="Delete row"
                                className="addInfButton"
                                onClick={() => removeRow(index)}>{closeIconRed}</button></div>
                    ))
                }
            </div>
            <div className="totalToken">
                <div className="reportDisclaimer"><span className="asterisk">*</span>Influencer must be registered with Tama Platform</div>
                <div className="totalTokenCount">Total Estimated Token Needed: {totalToken}</div>
            </div>
            <div className='errorText'>{infError ? 'Please enter handle and hashtag for all added influencer' : ''}</div>

        </div >
    );
}


import React, { useState, useEffect } from "react";
import Modal from "../../Components/Base/Modal";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
export default function ModalPrice(props) {
  const [minValue, setMinValue] = useState(50);
  const [maxValue, setMaxValue] = useState(3000);
  const [selMinValue, setSelMinValue] = useState(props.selectedPrice.length > 0 ? props.selectedPrice[0] : minValue);
  const [selMaxValue, setSelMaxValue] = useState(props.selectedPrice.length > 0 ? props.selectedPrice[1] : maxValue);


  const OnChangeEventTriggerd = (newValue) => {
    console.log("new Value", newValue);
    //props.setSelectedPrice(newValue);
    setSelMinValue(newValue[0])
    setSelMaxValue(newValue[1])
  };
  const savePrice = () => {
    props.setSelectedPrice([selMinValue, selMaxValue]);
  }
  return (
    <Modal addClass="modalCamp" close={props.close}>
      <div className="filterModal modal__camp">
        <div className="modal__assetDetail-delete perm long">
          <h4>Price</h4>
          <div className="sm min-max-text">
            <div>Min Price</div>
            <div>Max Price</div>
          </div>
          <div className="followers-count">
            <div>${selMinValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            <div>${selMaxValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{selMaxValue === 3000 ? '+' : ''}</div>
          </div>
          <div className="range-slider-select">
            <Slider
              range
              min={minValue}
              max={maxValue}
              defaultValue={[selMinValue, selMaxValue]}
              onChange={OnChangeEventTriggerd}
            />
          </div>
          <div className="modal__assetDetail-delete-btns">
            <button className="button primary" onClick={() => { savePrice(); props.handleSearch(); }}>
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
import React from "react";
import Modal from "../../Components/Base/Modal";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
export default function ProfileAllPhotosModal(props) {
  // const OnChangeEventTriggerd = (newValue) => {
  //   console.log("new Value", newValue);
  //   // setValue(newValue);
  // };

  return (
    <Modal addClass="showallPhotos modalCamp" close={props.close}>
      <div className="filterModal modal__camp">
        <div className="grid">
          {props.allInfluencerImage.length > 0 &&
            props.allInfluencerImage.map(item => {
              return <div
                className="img-cards"
                style={{ backgroundImage: item.image == "" ? `url( ${process.env.PUBLIC_URL}/images/placeholder/list_image.jpg)` : `url(${item.image})` }}
              ><div className="card-text-top">
                  <h4>Adventure</h4>
                </div>
                <div className="card-text">
                  <h4>{item.name}</h4>
                  <p>{item.followers} influencer</p>
                </div>
              </div>


            })
          }
        </div>
      </div>

    </Modal>
  );
}





import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {

  Profile,

} from '../../../Components';

import ModalNotSubscribe from './Modal/ModalNotSubscribe';
export default function Offer({
  setModalNotification,
  checkout,
  goToPricing,
  setAuth,
  setModal,
  NotifModul,
  setNotifModul,
  closeCartPanel,
  user,
  cartItem,
  profiles,
  title,
  background

}) {
  // eslint-disable-next-line

  const [isUserHasSubscription, setUserHasSubscription] = useState(false);

  return (
    <>

      {
        isUserHasSubscription === false && (
          <>
            <div className="main">
              <div className="main__inner" >
                <div className="main__inner-title">
                  <div className="main__inner-title-col"> <h2>{title}</h2></div>
                  <Profile
                    type='brand'
                    setAuth={setAuth}
                    setModal={setModal}
                    NotifModul={NotifModul}
                    setNotifModul={setModalNotification}
                    profiles={profiles}
                    closeCartPanel={closeCartPanel}
                    user={user}
                    cartItem={cartItem}
                  />
                </div>
                <div className="searchcontainer notsubscribeDiv backgroundImage" style={{ backgroundImage: `url( ${process.env.PUBLIC_URL}/images/placeholder/${background})` }}></div>
              </div>
            </div>

            <ModalNotSubscribe goToPricing={goToPricing}></ModalNotSubscribe>
          </>
        )
      }
    </>
  );
}

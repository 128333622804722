import { useEffect, useState } from 'react';
import Modal from "../../Components/Base/Modal";

import {
    assignToListIcon,
    assignToListIconRed
} from "../../Components";

import {
    useNavigate
} from 'react-router-dom';
import HalfRating from "./HalfRating";
import { storageHandler } from '../../Helpers';
export default function CardInfluencerSearch(props) {
    const navigate = useNavigate();
    const [isInsta, setIsInsta] = useState(false);
    const [isFB, setIsFB] = useState(false);
    const [isYouTube, setIsYouTube] = useState(false);
    const [ratingStar, setRatingStar] = useState([])


    const chkLoginandActionAccordingly = (event, influencer) => {
        event.stopPropagation();
        if (props.profiles !== undefined) {
            if (props.isUserHasSubscription == 1) {
                props.setSelectedInfluencer(influencer);
                props.setModal('addtoList');
            } else {
                props.setIsUserHasAccessListing(false)
            }
        } else {
            navigate('/account/pricing');
        }
    }
    const confirmRemoveFromList = async (event, influencer) => {
        event.stopPropagation();
        props.setSelectedInfluencer(influencer);
        props.setModal('confirmDelete');
    }

    useEffect(() => {

        let array = props.influencer.handle.icon;
        let newarray = [];

        array.map(itemIcon => {
            newarray.push(itemIcon.name)
        })
        //console.log(newarray)
        newarray.includes('Instagram') ? setIsInsta(true) : setIsInsta(false);
        newarray.includes('Facebook') ? setIsFB(true) : setIsFB(false);
        newarray.includes('Youtube') ? setIsYouTube(true) : setIsYouTube(false);


        //console.log('** ' + isInsta + "---" + isFB);
        //props.influencer.handle.icon[0].split(',')
        //console.log(array)
    }, []);


    const showInfProfile = async (influencer) => {

        console.log(influencer)

        storageHandler.setUserTypeForProfileItem(JSON.stringify(influencer.inf_user_type))
        navigate("/brand/influencerProfile/" + influencer.id);
    }

    return (

        <div className="cardMain" onClick={() => showInfProfile(props.influencer)}>
            <div className="top-icons">
                <div className="social-icons">

                    {isInsta &&
                        < img src="images/instagram.svg" width="28" title="instagram" className="smallImg" />
                    }
                    {isFB &&
                        < img src="images/facebook.svg" width="28" title="facebook" className="smallImg" />
                    }
                    {isYouTube &&
                        <img src="images/youtube.svg" width="28" title="youtube" className="smallImg" />
                    }

                </div>
                {props.profiles === undefined ?
                    <div className="like-icon" onClick={(e) => chkLoginandActionAccordingly(e, props.influencer)}
                    >
                        {assignToListIcon}
                    </div> :
                    props.influencer.liststatus === 1 ?
                        <div class="like-icon" onClick={(e) => confirmRemoveFromList(e, props.influencer)}>
                            {assignToListIconRed}
                        </div>
                        : <div class="like-icon" onClick={(e) => chkLoginandActionAccordingly(e, props.influencer)}>
                            {assignToListIcon}
                        </div>
                }
            </div>

            <div className="image-wrapper"
                style={{ backgroundImage: props.influencer.image == "" ? `url( ${process.env.PUBLIC_URL}/images/placeholder/avatar.png)` : `url(${props.influencer.image})` }}
            >
            </div>
            <div className="rating">
                <div className="star-container " data-rating="0">
                    <HalfRating infrating={props.influencer.rating}></HalfRating>
                </div>
            </div>
            <div className="username">{props.influencer.name}</div>
            {props.influencer.bio && props.influencer.bio != "" &&
                <div className="tags">
                    <p className="tagstext">{props.influencer.bio ? props.influencer.bio != "" ? props.influencer.bio.substring(0, 25) : "" : ""}</p>

                </div>}
        </div >
    );
}

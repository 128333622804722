import React from "react";
import Modal from "../../Components/Base/Modal";
import { tickSymbol, cancelCircleicon, SelectInput } from "../../Components";

const selectLanguageList = [{ id: "English", value: "English" }];
export default function ModalLanguage(props) {
  const changeSelectedLanguage = (item) => {
    props.setSelectedLanguage(item.value)
  }
  return (
    <Modal addClass="modalCamp" close={props.close}>
      <div className="filterModal modal__camp">
        {props.isUserHasSubscription ?
          <div className="modal__assetDetail-delete perm long">
            <h4>Language</h4>
            <div className="dropdown-item age-select">

              <SelectInput
                selected={selectLanguageList.filter(item => item.value === props.selectedLanguage)[0]}
                onChange={changeSelectedLanguage}
                list={selectLanguageList}
                value={props.selectedLanguage}
              />
            </div>
            <div className="modal__assetDetail-delete-btns">
              <button className="button primary" onClick={props.handleSearch}>
                Save
              </button>
            </div>
          </div> :

          <div className="modal__assetDetail-delete perm long lock-feture-screen">
            <h4>Upgrade to Unlock Advanced Filters</h4>
            <div className="dropdown-item">
              <div className="unlock-box">
                <svg
                  data-slot="icon"
                  fill="#ffffff"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  width={70}
                >
                  <path
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z"
                  ></path>
                </svg>
              </div>
              <p>
                Upgrade to unlock filters including age, ethnicity, languages, and
                more.
              </p>
            </div>
            <div className="modal__assetDetail-delete-btns">
              <button className="button primary" onClick={props.onSaveCamp}>
                Unlock
              </button>
            </div>
          </div>}
      </div>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import {
  commentsIcon,
  heartIcon,
  shareIcon,
  LineChart,
  BarChart,
  MultipleLineChart,
  AgeChart,

} from "../../Components";
import {

  reportService
} from '../../Services';

import { utils } from "../../Helpers/utils";
export default function ReportInsight(props) {
  const [reportId, setReportId] = useState(props.modalData.id);
  const [toastList, setToastList] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [isGrpahDataLoaded, setIsGraphDataLoaded] = useState(false);

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  console.log(props.modalData)
  useEffect(() => {
    async function getReportStatsData() {
      try {
        const res = await reportService.getReportStats(reportId);
        setGraphData(res.data);
        setIsGraphDataLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    getReportStatsData()
  }, []);
  return (
    <div className="campaigns">
      <div className="campaignsInsight">
        <div className="campaignsInsight__info">
          <div className="campaignsInsight__info-overview reportInsigntDiv">
            <div class="campaignsInsight__info-details-row reportInsigntDiv"><h3>Description </h3>
              <div class="campaignsInsight__info-details-row-inner"><h6><span>  <p className="hideToolbar">
                {props.modalData.description
                  ? utils.draftJsFormatingForDetails({ 'data': props.modalData.description, 'view': 'fullview' })
                  : ''}
              </p></span></h6></div></div>



            <div class="campaignsInsight__info-details-row reportInsigntDiv"><h3>Influencers </h3>
              <div class="campaignsInsight__info-details-row-inner">


                {props.modalData.influencers.map((item) => {
                  return <div className="reportInsightDivInf">
                    <div>{item.handle} </div><div>{item.hash_tag} </div></div>

                })}
              </div></div>



          </div>

          <div className="campaignsInsight__info-details">
            <div className="campaignsInsight__info-details-row">
              <h3>Tokens Used </h3>
              <div className="campaignsInsight__info-details-row-inner">
                <h6>
                  <span> {props.modalData.token_used
                    ? props.modalData.token_used
                    : '0'}
                  </span>
                </h6>

              </div>
            </div>
            <div className="campaignsInsight__info-details-row">
              <h3>Timeframe</h3>
              <div className="campaignsInsight__info-details-row-inner">
                <h6>
                  <span>{props.modalData.start_date} - {props.modalData.end_date}</span>
                </h6>

              </div>
            </div>

            <div className="campaignsInsight__info-details-row">
              <h3>Platform</h3>
              <div className="campaignsInsight__info-details-row-inner">
                {props.platformToShow}
              </div>
            </div>

          </div>
        </div>
        <div className="campaignsInsight__insight">
          <h3>Insights</h3>
          <div className="campaignsInsight__insight-row">
            <h5>At a glance</h5>
            <div className="campaignsInsight__insight-row-inner">
              <div className="campaignsInsight__insight-row-item">
                {heartIcon}
                <h5>{graphData.summary ? graphData.summary.like : 0} Total likes</h5>
              </div>
              <div className="campaignsInsight__insight-row-item">
                {commentsIcon}
                <h5>{graphData.summary ? graphData.summary.comment : 0} Total Comments</h5>
              </div>
              <div className="campaignsInsight__insight-row-item">
                {shareIcon}
                <h5>{graphData.summary ? graphData.summary.share : 0} Total Shares</h5>
              </div>
            </div>
          </div>
        </div>
        {isGrpahDataLoaded && (
          <>
            <div className="campaignsInsight__insight">
              <h3>Insights</h3>
              <div className="campaignsInsight__insight-row">
                <h5>At a glance</h5>
                <div className="campaignsInsight__insight-row-inner">
                  <div className="campaignsInsight__insight-row-item">
                    {heartIcon}
                    <h5>{graphData.summary ? graphData.summary.like : 0} Total likes</h5>
                  </div>
                  <div className="campaignsInsight__insight-row-item">
                    {commentsIcon}
                    <h5>{graphData.summary ? graphData.summary.comment : 0} Total Comments</h5>
                  </div>
                  <div className="campaignsInsight__insight-row-item">
                    {shareIcon}
                    <h5>{graphData.summary ? graphData.summary.share : 0} Total Shares</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="campaignsChart">
              <div className="campaignsChartTop">
                <div className="campaignsChartTop__col">
                  <div className="campaignsChartTop__col-row">
                    <div className="campaignsChartTop__reach">
                      <div className="campaignsChartTop__reach-title">
                        <h5>Reach</h5>
                        <h2>{graphData.reach ? graphData.reach.header : 0}</h2>
                      </div>
                      <div className="campaignsChartTop__reach-chart">
                        <LineChart
                          data={graphData.reach ? graphData.reach.data ? graphData.reach.data : [] : []}
                          labels={graphData.label ? graphData.label : []}
                        />
                      </div>
                    </div>
                    <div className="campaignsChartTop__reach">
                      <div className="campaignsChartTop__reach-title">
                        <h5>Impressions</h5>
                        <h2>{graphData.impression ? graphData.impression.header : 0}</h2>
                      </div>
                      <div className="campaignsChartTop__reach-chart">
                        <BarChart
                          labels={graphData.label ? graphData.label : []}
                          data={graphData.impression ? graphData.impression.data ? graphData.impression.data : [] : []}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="campaignsChartTop__col-total">
                    <div className="campaignsChartTop__col-total-title">
                      <h5>Impressions vs reach</h5>
                      <div className="campaignsChartTop__col-total-title-inner">
                        <div className="campaignsChartTop__col-total-title-item">
                          <span style={{ background: "#08A708" }}></span>
                          <h6 className="sm">Total Impressions - {graphData.impression ? graphData.impression.header : 0}</h6>
                        </div>
                        <div className="campaignsChartTop__col-total-title-item">
                          <span style={{ background: "#1F4BE7" }}></span>
                          <h6 className="sm">Total Reach - {graphData.reach ? graphData.reach.header : 0}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="campaignsChartTop__col-total-chart">
                      <MultipleLineChart
                        data1={graphData.impression ? graphData.impression.data ? graphData.impression.data : [] : []}
                        data2={graphData.reach ? graphData.reach.data ? graphData.reach.data : [] : []}
                        labels={graphData.label ? graphData.label : []}
                      />
                    </div>
                  </div>
                </div>
                <div className="campaignsChartTop__col">
                  <div className="campaignsChartTop__age">
                    <div className="campaignsChartTop__age-title">
                      <h5>Average by Age</h5>
                    </div>
                    <AgeChart ageData={graphData.ageData ? graphData.ageData.data ? graphData.ageData.data : [] : []} />
                  </div>
                  <div className="campaignsChartTop__gender">
                    <div className="campaignsChartTop__gender-title">
                      <h5>Audience by Gender</h5>
                      <div className="campaignsChartTop__gender-subtitle">
                        Entire campaign
                      </div>
                    </div>
                    <div className="campaignsChartTop__gender-row">
                      <div className="campaignsChartTop__gender-item">
                        <h6 className="sm">Male</h6>
                        <h2 className="uniq">{graphData.ageData.header ? graphData.ageData.header.gender_m : 0}%</h2>
                      </div>
                      <div className="campaignsChartTop__gender-item">
                        <h6 className="sm">Female</h6>
                        <h2 className="uniq">{graphData.ageData.header ? graphData.ageData.header.gender_f : 0}%</h2>
                      </div>
                      <div className="campaignsChartTop__gender-item">
                        <h6 className="sm">Undisclosed</h6>
                        <h2 className="uniq">{graphData.ageData.header ? graphData.ageData.header.gender_u : 0}%</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="campaignsChartBot">
                <h3>Engagement</h3>
                <div className="campaignsChartBot__row">
                  <div className="campaignsChartBot__col">
                    <div className="campaignsChartBot__col-title">
                      <h5>Amplification rate</h5>
                      <h2>{graphData.ampRate ? graphData.ampRate.header : '0%'}</h2>
                    </div>
                    <div className="campaignsChartBot__col-chart">
                      <LineChart
                        percentage={true}
                        data={graphData.ampRate ? graphData.ampRate.data ? graphData.ampRate.data : [] : []}
                        labels={graphData.label ? graphData.label : []}
                      />
                    </div>
                  </div>
                  <div className="campaignsChartBot__col">
                    <div className="campaignsChartBot__col-title">
                      <h5>Virality rate</h5>
                      <h2>{graphData.viralityRate ? graphData.viralityRate.header : '0%'}</h2>
                    </div>
                    <div className="campaignsChartBot__col-chart">
                      <LineChart
                        percentage={true}
                        data={graphData.viralityRate ? graphData.viralityRate.data ? graphData.viralityRate.data : [] : []}
                        labels={graphData.label ? graphData.label : []}
                      />
                    </div>
                  </div>
                  <div className="campaignsChartBot__col">
                    <div className="campaignsChartBot__col-title">
                      <h5>Engagement rate</h5>
                      <h2>{graphData.engagementRate ? graphData.engagementRate.header : '0%'}</h2>
                    </div>
                    <div className="campaignsChartBot__col-chart">
                      <LineChart
                        percentage={true}
                        data={graphData.engagementRate ? graphData.engagementRate.data ? graphData.engagementRate.data : [] : []}
                        labels={graphData.label ? graphData.label : []}
                      />
                    </div>
                  </div>
                </div>
                <div className="campaignsChartBot__total">
                  <div className="campaignsChartBot__total-title">
                    <h5>Total Engagement</h5>
                    <div className="campaignsChartBot__total-title-inner">
                      <div className="campaignsChartBot__total-title-item">
                        <span style={{ background: "#08A708" }}></span>
                        <h6 className="sm">Likes</h6>
                      </div>
                      <div className="campaignsChartBot__total-title-item">
                        <span style={{ background: "#1F4BE7" }}></span>
                        <h6 className="sm">Comments</h6>
                      </div>
                      <div className="campaignsChartBot__total-title-item">
                        <span style={{ background: "#FF319A" }}></span>
                        <h6 className="sm">Shares</h6>
                      </div>
                      <div className="campaignsChartBot__total-title-item">
                        <span style={{ background: "#8D0048" }}></span>
                        <h6 className="sm">Interactions</h6>
                      </div>
                    </div>
                  </div>
                  <div className="campaignsChartBot__total-chart">
                    <MultipleLineChart
                      data1={graphData.like ? graphData.like.data ? graphData.like.data : [] : []}
                      data2={graphData.comment ? graphData.comment.data ? graphData.comment.data : [] : []}
                      data3={graphData.share ? graphData.share.data ? graphData.share.data : [] : []}
                      data4={graphData.interaction ? graphData.interaction.data ? graphData.interaction.data : [] : []}
                      labels={graphData.label ? graphData.label : []}
                    />
                  </div>
                </div>
              </div>


            </div>
          </>)}
      </div>
    </div >
  );
}

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function HeaderAcc() {
  const [menu, setMenu] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (menu) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [menu]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
    document.body.classList.remove('active');
  }, [location]);
  const close = e => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  return (
    <header className='headerAcc'>
      <div className='headerAcc__inner'>
        <Link to='https://tamayou.com/' className='headerAcc__inner-logo'>
          <img
            src={process.env.PUBLIC_URL + '/images/logo-white.png'}
            alt='logo-white'
          />
        </Link>
        <nav className={'navAcc ' + (menu ? 'active' : '')} onClick={close}>
          <div className='navAcc__inner'>
            <a href='https://tamayou.com/brand/' className='navAcc__inner-link'>
              Brands
            </a>
            <a href='https://tamayou.com/influencer/' className='navAcc__inner-link'>
              Influencers
            </a>
            <a href='https://tamayou.com/what-we-do/' className='navAcc__inner-link'>
              Services
            </a>
            <a href='http://blog.tamayou.com/' className='navAcc__inner-link'>
              Blog
            </a>
            <a href='#/account/pricing' className='navAcc__inner-link'>
              Pricing
            </a>
          </div>
        </nav>
        <div
          className={'burger ' + (menu ? 'active' : '')}
          id='menuBtn'
          onClick={() => {
            setMenu(!menu);
          }}
        >
          <span></span>
        </div>
      </div>
    </header>
  );
}

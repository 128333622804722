import React, { useState, useEffect } from "react";
import Modal from "../../Components/Base/Modal";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
export default function ModalFollowers({ setSelectedFollowersCount, close, handleSearch, selectedFollowersCount }) {
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(1000000);
  ;
  const [selMinValue, setSelMinValue] = useState(selectedFollowersCount.length > 0 ? selectedFollowersCount[0] : minValue);
  const [selMaxValue, setSelMaxValue] = useState(selectedFollowersCount.length > 0 ? selectedFollowersCount[1] : maxValue);

  const OnChangeEventTriggerd = (newValue) => {
    console.log("new Value", newValue);
    //
    setSelMinValue(newValue[0])
    setSelMaxValue(newValue[1])
  };
  const saveFollowersCount = () => {
    setSelectedFollowersCount([selMinValue, selMaxValue]);
  }
  return (
    <Modal addClass="modalCamp" close={close}>
      <div className="filterModal modal__camp">
        <div className="modal__assetDetail-delete perm long">
          <h4>Followers</h4>
          <div className="sm min-max-text">
            <div>Min Followers</div>
            <div>Max Followers</div>
          </div>
          <div className="followers-count">
            <div>{selMinValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            <div>{selMaxValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{selMaxValue === 1000000 ? '+' : ''}</div>
          </div>
          <div className="range-slider-select">
            <Slider
              range
              min={minValue}
              max={maxValue}
              defaultValue={[selMinValue, selMaxValue]}
              onChange={OnChangeEventTriggerd}
            />
          </div>
          <div className="modal__assetDetail-delete-btns">
            <button className="button primary" onClick={() => { saveFollowersCount(); handleSearch(); }}>
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

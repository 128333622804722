import React, { useEffect, useRef, useState } from "react";
import Modal from "../../Components/Base/Modal";


export default function ModalHowItWorks(props) {
  //console.log(props)
  const [expanded, setExpanded] = React.useState("panel1");
  const inputWrapper = useRef(null);
  const [list, setList] = useState([]);
  const [focus, setFocus] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const inpRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");




  //console.log(props.selectedLocationData.country)
  return (
    <Modal addClass="modalCamp" close={props.close}>
      <div className="filterModal modal__camp">
        <div className="perm long smalldiv">
          <h4>What Happens When I Purchase a Package?</h4>
          <div className="howitwork">
            <div>
              <ul>
                <li>
                  Your payment will be held for 72 hours while the influencer accepts your request.
                </li>
                <li>
                  Chat with the influencer and arrange the collaboration.
                </li>
                <li>
                  Approve the deliverables before payment is released to the influencer.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

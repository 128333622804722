import React from "react";

export default function OrderItem(props) {

  return (
    <tr onClick={() => props.showOrderDetails(props.id)}>
      <td>
        <p>{props.created_at}</p>
      </td>

      <td>
        <p>${props.amount}</p>
      </td>
      <td><p>{props.offer_count}</p></td>

      <td>{String(props.status).charAt(0).toUpperCase() + String(props.status).slice(1)}</td>
      <td>{props.ref_id}</td>
    </tr>
  );
}

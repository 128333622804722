import React, { useState, useEffect } from "react";

import { HeaderAccOuterPage, Profile } from "../../Components";

import { arrowLeft } from "../../Components";
import {
  HeaderAcc,
  Toast,
  Modal,
  EmailInput,
} from '../../Components';
import PricingMain from './PricingMain';
import {
  subProductService, billingService
} from '../../Services';
import ModalPricingPlatformCreate from './ModalPricingPlatformCreate';
import ModalAccActivation from '../Account/Activation/ModalActivation';
import ModalPricingformCreate from './ModalPricingformCreate';
import ModalPricingSignIn from './ModalPricingSignIn';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { utils } from "../../Helpers/utils";

export default function Pricing({ user, referPageUrl, cartItem, closeCartPanel, setAuth, NotifModul, account, profiles }) {
  const [tab, setTab] = useState("main");
  const [planType, setPlanType] = useState("monthly");
  const [planTypeShow, setPlanTypeShow] = useState("Month");
  const [planAmtEssential, setPlanAmtEssential] = useState("$75");
  const [planAmtGrowth, setPlanAmtGrowth] = useState("$395");
  const [isloaded, setIsLoaded] = React.useState(false);
  const [page, setPage] = useState(1);
  const [toastList, setToastList] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const [modal, setModal] = useState(null);
  const [subProduct, setSubProduct] = useState([]);
  const [subProductMonthly, setSubProductMonthly] = useState([]);
  const [subProductYearly, setSubProductYearly] = useState([]);
  const [subProductToShow, setSubProductToShow] = useState([]);
  const [subModal, setSubModal] = useState(null);
  const [referId, setReferId] = useState(null);
  const [searchList, setSearchList] = useState(false);
  const [subsProductId, setSubsProductId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(referPageUrl)
  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    let paypalError = searchParams.get('error') !== null ? searchParams.get('error') == 1 ? "We're having trouble connecting to your payment account. Please try again." : '' : '';
    let successMessage = searchParams.get('success') !== null ? searchParams.get('success') == 1 ? "Plan  is successfully subscribe." : '' : '';
    let allReadyError = searchParams.get('error') !== null ? searchParams.get('error') == 2 ? "This email address is already registered." : '' : '';
    let subscriptionError = searchParams.get('error') !== null ? searchParams.get('error') == 3 ? "Subscription already present for this user" : '' : '';
    let signInError = searchParams.get('error') !== null ? searchParams.get('error') == 4 ? "Please SignUp " : '' : '';
    if (successMessage !== '') {
      addToast({
        error: false,
        msg: successMessage,
        id: `${Date.now()}-${toastList.length}`
      });
    }
    if (paypalError !== '') {
      addToast({
        error: true,
        msg: paypalError,
        id: `${Date.now()}-${toastList.length}`
      });
    }
    if (signInError !== '') {
      addToast({
        error: true,
        msg: signInError,
        id: `${Date.now()}-${toastList.length}`
      });
    }
    if (allReadyError !== '') {
      addToast({
        error: true,
        msg: allReadyError,
        id: `${Date.now()}-${toastList.length}`
      });
    }
    if (subscriptionError !== '') {
      addToast({
        error: true,
        msg: subscriptionError,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {

        const res = await subProductService.getAll();

        setSubProduct(res.data ? res.data : []);
        if (res.data.length > 0) {
          let monthlyProduct = res.data.filter(item => item.billing_cycle == 'monthly');
          let yearlyProduct = res.data.filter(item => item.billing_cycle == 'yearly');
          console.log(monthlyProduct)
          setSubProductMonthly(monthlyProduct);
          setSubProductYearly(yearlyProduct);
          setPlanAmtEssential(monthlyProduct[0].price);
          setPlanAmtGrowth(monthlyProduct[1].price);
          setPlanTypeShow("Month")
          setSubProductToShow(monthlyProduct)
        }
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }

    if (tab !== "add" && tab !== "edit")
      fetchData();

  }, [tab, page, searchList]);
  const changePlan = (type) => {
    setPlanType(type);
    if (type === 'monthly') {

      setPlanAmtEssential(subProductMonthly[0].price);
      setPlanAmtGrowth(subProductMonthly[1].price);
      setPlanTypeShow("Month")
      setSubProductToShow(subProductMonthly)
    }
    if (type === 'yearly') {
      setPlanAmtEssential(subProductYearly[0].price);
      setPlanAmtGrowth(subProductYearly[1].price);
      setPlanTypeShow("Year")
      setSubProductToShow(subProductYearly)
    }
  };


  const chkLogin = (product) => {

    if (profiles === undefined) {

      setSubsProductId(product.product_id)
      setModal('pricingformCreate');

    } else { doSubscription(product) }
  }


  const doSubscription = async (product) => {

    const payload = {
      amount: product.price,
      description: product.billing_cycle,
      product_id: product.product_id,
      plan_id: product.paypal_plan_id,
      amount: product.price,
      description: product.product_description,
      account: "paypal",
      plan_id: product.paypal_plan_id,
      billing_cycle: product.billing_cycle
    };
    try {

      const res = await billingService.addSubscribePlan(payload);
      window.location.replace(res.url);
    } catch (error) {
      console.log(error)
    }
  };

  console.log(subsProductId)
  return (
    <>
      {profiles !== undefined ?
        <div className="main">
          <div className={"main__inner" + (profiles === undefined ? ' nopadding' : '')}>
            <div className="main__inner-title">
              <h2>Pricing</h2>
              <Profile
                account={account}
                setAuth={setAuth}
                setModal={setModal}
                NotifModul={NotifModul}
                profiles={profiles}
                closeCartPanel={closeCartPanel}
                type={"brand"}
                user={user}
                cartItem={cartItem}
              />
            </div>
            {subProductToShow.length > 0 &&
              <PricingMain setToastList={setToastList}
                toastList={toastList}
                subProductToShow={subProductToShow} chkLogin={chkLogin} changePlan={changePlan} planAmtGrowth={planAmtGrowth} planAmtEssential={planAmtEssential} planType={planType} planTypeShow={planTypeShow} />
            }
          </div>
        </div >

        :
        <div className='searchouterpage'>
          <div className='account log' >
            <div className='account__inner'>
              <HeaderAccOuterPage />
              <div >
                <div className='account__inner-col-image'>
                  <div > {subProductToShow.length > 0 &&
                    <PricingMain
                      subProductToShow={subProductToShow}
                      chkLogin={chkLogin}
                      changePlan={changePlan}
                      planAmtGrowth={planAmtGrowth}
                      planAmtEssential={planAmtEssential}
                      planType={planType}
                      planTypeShow={planTypeShow}
                      setToastList={setToastList}
                      toastList={toastList} />
                  }</div>
                </div>

              </div>
            </div>
          </div></div>}
      {
        modal === 'pricingPlatformCreate' && (
          <ModalPricingPlatformCreate
            setReferId={setReferId}
            setRoleId={setRoleId}
            close={() => setModal(null)}
            setModal={setModal}
            subsProductId={subsProductId}

          />
        )
      }
      {(modal === 'terms' || modal === 'termsCreate' || modal === "pricingTermsCreate") && (
        <ModalAccActivation

          close={() => {

            setModal('pricingPlatformCreate');

          }}
        />
      )}
      {
        (subModal === 'terms') && (
          <ModalAccActivation

            close={() => {
              if (subModal === 'terms') {
                setSubModal(null);
              }
            }}
          />
        )
      }
      {
        modal === 'pricingformCreate' && (
          <ModalPricingformCreate
            setReferId={setReferId}
            referId={referId}
            setRoleId={setRoleId}
            close={() => setModal(null)}
            setModal={setModal}
            setSubModal={setSubModal}
            subsProductId={subsProductId}
            setToastList={setToastList}
            toastList={toastList}
          />
        )
      }
      {
        modal === 'pricingsignin' && (
          <ModalPricingSignIn

            close={() => setModal(null)}
            setModal={setModal}

            setSubModal={setSubModal}
            subsProductId={subsProductId}
            referPageUrl={referPageUrl}
            setToastList={setToastList}
            toastList={toastList}
          />
        )
      }
      {toastList && <Toast list={toastList} setList={setToastList} />}
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

import {

    Pagination
} from "../../Components";
import AddOffer from "./AddOffer";
import { Modal } from "../../Components";
import {
    influencerlistService,

} from '../../Services';

import ListEdit from "./ListEdit";
import ShowInfluencerGrid from "./ShowInfluencerGrid";
export default function ShowInfluencer({ user, setShowCart, setUpdateProfilePage, updateProfilePage, setCartItem, cartItem, selectedInfluencer, removeInfFromList, setSelectedInfluencer, modalDataList, setTab, setToastList, toastList, addToast, setTabModal, tabModal }) {
    // console.log(modalDataList)
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [isLoaded, setIsLoaded] = useState(false);
    const [paginationData, setPaginationData] = useState({});
    const [countOfInf, setCountOfInf] = useState();
    const [listInfluencer, setListInfluencer] = useState([]);
    const [listDetails, setListDetails] = useState({});
    const [listname, setListName] = useState(modalDataList.name);

    //  console.log(modalDataList)

    useEffect(() => {
        async function fetchList() {

            try {
                const res = await influencerlistService.getById(modalDataList.id);

                setListDetails(res.data);

            } catch (err) {
                addToast({
                    error: true,
                    msg: err,
                    id: `${Date.now()}-${toastList.length}`
                });
            }
        }

        fetchList();
        // fetchData();
    }, [listname]);

    useEffect(() => {

        async function fetchData() {

            let filterString = '';
            console.log("in fetchlist");
            console.log(pageSize)
            try {
                const res = await influencerlistService.getAllInfluencer(modalDataList.id, {
                    page: page,
                    pageSize,
                    filter: filterString
                });
                if (
                    res.meta &&
                    res.meta.pagination
                ) {
                    setPaginationData(res.meta.pagination);
                    setCountOfInf(res.meta.total);
                }
                //setRowCount((res.data));

                //setList([{ 'id': 1, 'title': "ABC", 'thumbnail': "http://localhost:3000/images/placeholder/avatar.png", 'created_at': '2024-09-09' }, { 'id': 2, 'title': "1ABC", 'thumbnail': "http://localhost:3000/images/placeholder/avatar.png", 'created_at': '2025-09-09' }]);
                setListInfluencer(res.data);
                setIsLoaded(true);
            } catch (err) {
                addToast({
                    error: true,
                    msg: err,
                    id: `${Date.now()}-${toastList.length}`
                });
            }
        }

        fetchData();
    }, [page, countOfInf, selectedInfluencer]);

    const onPaginate = page => {
        setPage(page);
    };
    const addOffer = () => {
        setTabModal('addOffer')
    }
    //  console.log("in show inf");
    //console.log(modalDataList)

    return (
        <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            exit={{ opacity: 0, y: 30 }}
            className="assetsAdd"
        >
            <div className="assetsAdd__form">
                <div className="assetsAdd__form-title">
                    <div class="campaignsCreate__btns"><div class="campaignsCreate__btns-row"><h3 className="big">Influencer List- {listDetails.name}</h3>
                    </div>
                        {isLoaded &&
                            <button class="button primary " onClick={() => addOffer(modalDataList.id)}>Add Custom Offer</button>
                        }</div>

                    <button className="button tertiary big" onClick={() => { setShowCart(false); setTabModal("edit") }}>
                        Edit
                    </button>

                </div>
                <>
                    <div className="inflist__table searchcontainer showCompleteContainer">
                        <div className="grid">

                            {(isLoaded && listInfluencer.length > 0) ? (
                                <>
                                    {listInfluencer.map((item, index) => {
                                        return (

                                            <ShowInfluencerGrid
                                                setCountOfInf={setCountOfInf}
                                                item={item} toastList={toastList}
                                                addToast={addToast}
                                                listId={modalDataList.id}
                                                setTabModal={setTabModal}
                                                setSelectedInfluencer={setSelectedInfluencer}
                                                removeInfFromList={removeInfFromList}
                                                setUpdateProfilePage={setUpdateProfilePage}
                                                updateProfilePage={updateProfilePage}
                                            >
                                            </ShowInfluencerGrid>

                                        );
                                    })}
                                </>
                            ) : ((isLoaded &&
                                <div className='showpriceanddetails'><p className='influencerCategory inLeftNotFound' >No Influener found in this list.</p></div>))}
                        </div>
                    </div>
                </>
            </div>
            {
                tabModal === "edit" && <Modal addClass="modalAsset" close={() => setTabModal(null)}>
                    <div className="modal__asset">
                        <div className="modal__asset-inner">


                            <ListEdit setListName={setListName} listDetails={listDetails} setTab={setTab} setToastList={setToastList} toastList={toastList} setTabModal={setTabModal} />

                        </div>
                    </div>
                </Modal>
            }
            {
                tabModal === "addOffer" && <Modal addClass="modalAsset" close={() => setTabModal(null)}>
                    <div className="modal__asset">
                        <div className="modal__asset-inner">


                            <AddOffer user={user} setShowCart={setShowCart} listInfluencer={listInfluencer} cartItem={cartItem} setCartItem={setCartItem} setListName={setListName} listDetails={listDetails} setTab={setTab} setToastList={setToastList} toastList={toastList} setTabModal={setTabModal} />

                        </div>
                    </div>
                </Modal>
            }
            <div className="assets__table-footer">
                {(isLoaded && listInfluencer.length > 0) && (
                    <>
                        <Pagination
                            perPage={paginationData['per_page']}
                            total={paginationData['total']}
                            currentPage={paginationData['current_page']}
                            totalPages={paginationData['total_pages']}
                            onPaginate={onPaginate}
                        />
                    </>
                )}
            </div>
        </motion.div >
    );
}

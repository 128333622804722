import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { utils } from '../../Helpers/utils';
import {
  Profile,
  Toast,
  arrowLeft
} from '../../Components';

import ReportMain from './ReportMain';
import ModalReportConfirm from "./ModalReportConfirm";
import ReportInsight from "./ReportInsight";
import CreateReport from './CreateReport';
import NotSubscribe from "../../Components/Base/Common/NotSubscribe";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { reportService, searchInfluencerService } from '../../Services';
const selectDescList = [
  { id: "title", value: "Title" },
  { id: "status", value: "Status" },
];
export default function Report({
  setAuth,
  NotifModul,
  user,
  profiles,
  setModalNotification,
  closeCartPanel,
  cartItem,
  goToPricing
}) {
  // eslint-disable-next-line
  const [selectDesc, setselectDesc] = useState(selectDescList[0].id);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isloaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [platform, setPlatform] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [searchList, setSearchList] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [selectedReport, setSelectedReport] = useState();
  const [modal, setModal] = useState(null);
  const [disableSearchBox, setDisableSearchBox] = useState(false);
  const [tab, setTab] = useState("main");
  const [confirmFor, setConfirmFor] = useState(null);
  const [filterBy, setFilterBy] = useState("nonarchived");
  const [platformToShow, setPlatformToShow] = useState("");
  const [statusActivePaused, setStatusActivePaused] = useState("");
  const [tokenAvailable, setTokenAvailable] = useState(0);
  const [isTokenLoaded, setIsTokenLoaded] = useState(false);
  const [isUserHasSubscription, setUserHasSubscription] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(selectedReport ? selectedReport.status : '');
  const location = useLocation();
  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);


  useEffect(() => {
    //setUserHasSubscription(user ? user.data.isUserHasSubscription == 1 ? true : false : false)
    async function fetchData() {
      try {
        let filter = '';

        if (searchString !== '' && filterBy == "") {
          if (filter != "") {
            filter += '&filter[' + selectDesc + ']=' + searchString;
          } else {
            filter += '&filter[' + selectDesc + ']=' + searchString;
          }
        } else {
          filter += '&filter[status]=nonarchived';
        }
        const res = await reportService.getAll({
          page: page,
          pageSize,
          filter: filter
        });
        if (
          res['meta'] &&
          res['meta']['pagination']
        ) {
          console.log("insode")
          setPaginationData(res['meta']['pagination']);
        }
        setReportData(res.data);
        setFilterBy("")
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }

    fetchData();

  }, [page, searchList, tab]);
  useEffect(() => {
    setUserHasSubscription(user !== null ? user.data.isUserHasSubscription == 1 ? true : false : false)
  }, [user]);
  useEffect(() => {

    async function gettokenData() {
      try {

        const res = await reportService.getTokenData();
        setTokenAvailable(res.data[0]);
        setIsTokenLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }

    gettokenData();
  }, []);
  const onPaginate = page => {
    setPage(page);
  };

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        search();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [searchString]);


  const changeMediumDesc = item => {
    setselectDesc(item.id)
    setPage(1);
    if (item.id === 'lastyr' || item.id === '30days') {
      setDisableSearchBox(true);
    } else {
      setDisableSearchBox(false);
    }
    setSearchString("");
    setSearchList(!searchList);
  };

  useEffect(() => {
    async function fetchCategoriesData() {
      try {
        const res = await searchInfluencerService.getCategoriesDetails();
        console.log(res)
        let platformDetails = res.socialMedia;
        setPlatform(platformDetails);
        setIsLoaded(true)
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchCategoriesData();
  }, []);
  const setSearchStringText = event => {
    if (event.target.value === '') {
      setSearchList(!searchList);
    }
    setSearchString(event.target.value);
  };
  const search = () => {
    setSearchList(!searchList);
    setPage(1);

  };
  const restoreReport = () => {
    setConfirmFor('restore')
    setModal('confirm');
  }
  const archiveReport = () => {
    setConfirmFor('archived');
    setModal('confirm');
  }
  const onConfirm = () => {
    if (confirmFor == 'restore') {
      updateStatus('paused')
    } else {
      updateStatus(confirmFor)
    }

  }
  const openViewReport = (item) => {
    setSelectedReport(item);
    let platformUsed = platform.filter(platformData => platformData.id == item.platform_id);
    setPlatformToShow(platformUsed[0].name);
    setTab('insights');

  }

  async function editReport(item) {
    if (item.id !== "") {
      try {
        const res = await reportService.getReport(item.id);
        res['data'][0]['start_date'] = res['data'][0]['start_date'] !== "" ? new Date(res['data'][0]['start_date'].replace(/-/g, '/')) : '';
        res['data'][0]['end_date'] = res['data'][0]['end_date'] !== "" ? new Date(res['data'][0]['end_date'].replace(/-/g, '/')) : '';
        console.log(res.data[0]);
        if (res['data'][0]['influencers'].length > 0) {
          let newArrayInf = [];
          res['data'][0]['influencers'].map((item) => {
            newArrayInf.push({ key: Date.now(), 'infHandle': item.handle, 'hash_tag': item.hash_tag, 'token': 0 })
          })
          res['data'][0]['influencer_report'] = newArrayInf;
        }
        setSelectedReport(res.data[0]);
        setTab("edit");
        setIsLoaded(true)
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
  }
  async function updateStatus(status) {
    try {
      const res = await reportService.updateStatus(selectedReport.id, status);
      if (res) {
        setCurrentStatus(status);
        addToast({
          error: false,
          msg: res.message,
          id: `${Date.now()}-${toastList.length}`
        });
        setModal(null);
        if (status == 'archived') {
          setSearchString("")
          setFilterBy('nonarchived');
          setTab("main")
        }
        if (status == 'paused' && confirmFor == 'restore') {
          setFilterBy('nonarchived');
          setSearchString("")
          setTab("main")
        }
        if (status === 'active' || status == 'paused') {
          setStatusActivePaused(status)
        }
      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }

  return (
    <>
      {isUserHasSubscription && <>
        <div className='main'>
          <div className='main__inner'>
            <div className='main__inner-title'>
              {tab === "main" && (
                <div className="main__inner-title-col">
                  <h2>Reports</h2>
                </div>
              )}
              {tab === "create" && (
                <div className="main__inner-title-row">
                  <h2>Create Report</h2>
                </div>
              )}
              {tab === "insights" && (
                <>
                  <div className="main__inner-title-col">
                    <div className="main__inner-title-row">
                      <h2>{selectedReport.title}</h2>
                    </div>
                    <button
                      className="button tertiary big"
                      onClick={() => setTab("main")}
                    >
                      {arrowLeft} Back
                    </button>
                  </div>
                </>
              )}
              {tab === "edit" && (
                <div className="main__inner-title-row">
                  <h2>Edit Report</h2>
                  {selectedReport && selectedReport.status != 'archived' &&
                    <button onClick={archiveReport} className="reportStatusButton" >Archive</button>}
                  {selectedReport && selectedReport.status == 'archived' &&
                    <button onClick={restoreReport} className="reportStatusButton">Restore</button>}
                </div>
              )}
              <Profile
                type='brand'
                setAuth={setAuth}
                setModal={setModal}
                NotifModul={NotifModul}
                user={user}
                profiles={profiles}
                setNotifModul={setModalNotification}
                closeCartPanel={closeCartPanel}
                cartItem={cartItem}
              />
            </div>
            {(tab === "main" && isloaded) && (
              <ReportMain
                setTab={setTab}
                reportData={reportData}
                setSearchStringText={setSearchStringText}
                searchString={searchString}
                paginationData={paginationData}
                onPaginate={onPaginate}
                disableSearchBox={disableSearchBox}
                changeMediumDesc={changeMediumDesc}
                editReport={editReport}
                openViewReport={openViewReport}
                search={search}
              />
            )}
            {tab === "insights" && (
              <ReportInsight
                setTab={setTab}
                modalData={selectedReport}
                platformToShow={platformToShow}
              />
            )}
            {tab === "edit" && isTokenLoaded && <CreateReport tokenAvailable={tokenAvailable} platform={platform} restoreReport={restoreReport} statusActivePaused={statusActivePaused} setConfirmFor={setConfirmFor} currentStatus={currentStatus} updateStatus={updateStatus} tab={tab} setTab={setTab} setToastList={setToastList} addToast={addToast} toastList={toastList} setSelectedReport={setSelectedReport} selectedReport={selectedReport} />}
            {tab === "create" && isTokenLoaded && <CreateReport tokenAvailable={tokenAvailable} platform={platform} restoreReport={restoreReport} setConfirmFor={setConfirmFor} currentStatus={currentStatus} updateStatus={updateStatus} tab={tab} setTab={setTab} setToastList={setToastList} addToast={addToast} toastList={toastList} />}
          </div>
        </div>
        <AnimatePresence>
          {modal === "confirm" && (
            <ModalReportConfirm
              close={() => setModal(null)}
              onConfirm={onConfirm}
              confirmFor={confirmFor}

            />
          )}
        </AnimatePresence>
        {toastList && <Toast list={toastList} setList={setToastList} />}
      </>}
      {isUserHasSubscription === false && user !== null && (
        <>
          <NotSubscribe goToPricing={goToPricing} type='brand'
            setAuth={setAuth}
            setModal={setModal}
            NotifModul={NotifModul}
            setNotifModul={setModalNotification}
            profiles={profiles}
            closeCartPanel={closeCartPanel}
            user={user}
            cartItem={cartItem}
            title={"Reports"}
            background={"background_reports.png"}
          >
          </NotSubscribe>
        </>
      )
      }
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

import {
    assignToListIcon,
    assignToListIconRed
} from "../../Components";

import {
    influencerlistService,
} from '../../Services';
import { storageHandler } from '../../Helpers';
import HalfRating from "./HalfRating";
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
export default function ShowInfluencerGrid({ setUpdateProfilePage, updateProfilePage, setUserTypeRegOrNon, removeInfFromList, setSelectedInfluencer, item, toastList, addToast, listId, setCountOfInf, setTabModal }) {
    const navigate = useNavigate();
    const [isInsta, setIsInsta] = useState(false);
    const [isFB, setIsFB] = useState(false);
    const [isYouTube, setIsYouTube] = useState(false);
    const [isTikTok, setIsTikTok] = useState(false);
    const removeFromList = async (event, item) => {
        event.stopPropagation();
        setTabModal('confirmDelete');
        setSelectedInfluencer(item)
    }


    const assignToList = async (id, inftype) => {
        try {
            let option = { 'listId': listId, 'influencer': id, 'action': 'assign', 'infType': inftype }
            const res = await influencerlistService.assignToList(option);
            setCountOfInf(0);
            // setListDetails(res.data);
        } catch (err) {
            addToast({
                error: true,
                msg: err,
                id: `${Date.now()}-${toastList.length}`
            });
        }
    }
    const showInfProfile = (event, item) => {
        event.stopPropagation();


        storageHandler.setUserTypeForProfileItem(JSON.stringify(item.inf_user_type))
        setUpdateProfilePage(!updateProfilePage)
        navigate("/brand/influencerProfile/" + item.user_id);

    }

    useEffect(() => {
        let array = item.account;
        let insta1 = array.filter(item => item.account_type == "Instagram");
        let fb = array.filter(item => item.account_type == "Facebook");
        let youtube = array.filter(item => item.account_type == "Youtube");
        let tiktok = array.filter(item => item.account_type == "TikTok");
        insta1.length > 0 ? setIsInsta(true) : setIsInsta(false);
        fb.length > 0 ? setIsFB(true) : setIsFB(false);
        youtube.length > 0 ? setIsYouTube(true) : setIsYouTube(false);
        tiktok.length > 0 ? setIsTikTok(true) : setIsTikTok(false);
    }, []);
    return (
        <>

            <div className="cardMain" onClick={(e) => showInfProfile(e, item)}>
                <div className="top-icons">
                    <div className="social-icons">
                        {isInsta &&
                            <img src="images/instagram.svg" width="28" title="instagram" />
                        }
                        {isFB &&
                            <img src="images/facebook.svg" width="28" title="facebook" />
                        }
                        {isYouTube &&
                            <img src="images/youtube.svg" width="28" title="youtube" />
                        }
                    </div>
                    {
                        item.liststatus === 1 &&
                        <div class="like-icon" onClick={(e) => removeFromList(e, item)}>
                            {assignToListIconRed}
                        </div>
                    }
                </div>
                <div className="image-wrapper"
                    style={{ backgroundImage: item.image == "" ? `url( ${process.env.PUBLIC_URL}/images/placeholder/avatar.png)` : `url(${item.image})` }}
                >
                </div>
                <div className="rating">
                    <div className="star-container " data-rating="0">
                        <HalfRating infrating={item.rating}></HalfRating>
                    </div>
                </div>
                <div className="username">{item.name}</div>
                {item.bio && item.bio != "" &&
                    <div className="tags">
                        <p className="tagstext">{item.bio ? item.bio != "" ? item.bio.substring(0, 25) : "" : ""}</p>

                    </div>}
            </div >
        </>
    );
}

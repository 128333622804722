import React, { useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { storageHandler } from '../../Helpers';
import ReCAPTCHA from 'react-google-recaptcha';
import {
    HeaderAcc,
    Toast,
    EmailInput,
    PasswordInput,
    SelectInput,
    TextInput,
    Modal
} from '../../Components';
import { motion } from 'framer-motion';
import { accountService } from '../../Services';
import { windowToggle } from 'rxjs/operators';

const userSubject = new BehaviorSubject(null);


export default function ModalPricingformCreate(props) {

    const initForm = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        accountType: "I'm a brand",
        subsproductId: props.subsProductId

    };

    const navigate = useNavigate();
    const [toastList, setToastList] = useState([]);
    const [form, setForm] = useState(initForm);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [captchaKey, setCaptchaKey] = useState(process.env.REACT_APP_CAPTCHA_KEY);

    const [captch, setCaptch] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [error, setError] = useState(false);
    const [errorTerms, setErrorTerms] = useState(false);
    let referId = searchParams.get('refId') ? searchParams.get('refId') : null;
    const setInput = key => event => updateForm({ [key]: event.target.value });
    // let roleType = searchParams.get('type') ? searchParams.get('type') : null;
    // roleType =
    //   roleType !== null && roleType == 1 ? "I'm a influencer" : "I'm a brand";
    let refId = (props.referId === null || props.referId === undefined) ? 0 : props.referId;
    const [selectDesc, setselectDesc] = useState(props.roleId);

    const createValidation = () => {
        setErrorTerms(false);
        if (
            form.email === '' ||
            form.password === '' ||
            form.password.length < 8 ||
            form.confirmPassword === '' ||
            form.confirmPassword < 8 ||
            form.lastName === '' ||
            form.firstName === '' ||
            captch === "" ||
            !isChecked
        ) {
            setError(true);
        } else {
            var passwordFormat = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
            if (form.password.match(passwordFormat)) {
                if (form.password !== form.confirmPassword) {
                    setError(true);
                    setShowErrorMessage("Password and Confirm Password should be same")
                    /* addToast({
                         error: true,
                         msg: "Password and Confirm Password should be same",
                         successText: '',
                         date: new Date()
                     });*/
                } else {
                    const payload = {
                        title: '',
                        firstName: form.firstName,
                        lastName: form.lastName,
                        email: form.email,
                        password: form.password,
                        confirmPassword: form.confirmPassword,
                        refId: referId,
                        role: 'advertiser',
                        acceptTerms: isChecked,
                        captcha: captch,
                        subsProductId: props.subsProductId
                    };
                    accountService
                        .register(payload)
                        .then(ressignup => {
                            //debugger;
                            accountService
                                .login(form.email, form.password)
                                .then(res => {
                                    //  debugger;
                                    console.log(ressignup.data.paypalurl);
                                    //navigate('/brand/dashboard');
                                    if (ressignup.data.paypalurl && ressignup.data.paypalurl !== "") {
                                        window.location.replace(ressignup.data.paypalurl);
                                    }
                                });

                        })
                        .catch(err => {
                            setShowErrorMessage(err.replaceAll(/<\/?[^>]+(>|$)/gi, ''))
                            /*addToast({
                                error: true,
                                msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, ''),
                                successText: '',
                                date: new Date()
                            });*/
                        })
                }
            }
            else {
                setError(true);
                setShowErrorMessage("Password should have atleast one uppercase, one lowercase letter, one number and one special character")
                /*addToast({
                    error: true,
                    msg: "Password should have atleast one uppercase, one lowercase letter, one number and one special character",
                    successText: '',
                    date: new Date()
                });*/
            }

        }
    };
    const addToast = data => {
        const newToastList = [...props.toastList];
        newToastList.push({ ...data, id: `${Date.now()}-${props.toastList.length}` });
        props.setToastList(newToastList);
    };
    const handleSignInClick = () => {
        props.setModal('pricingsignin')
    }

    const handleLoginWithGoogle = () => {
        setError(false);
        if (!isChecked) {
            setErrorTerms(true);
        }
        else {
            let roleID = 3;
            window.location.href = `${process.env.REACT_APP_API_URL}/login/google/action/signup/role/3/0/referalPath/pricing/1`;
        }
    };

    const updateForm = data => {
        setForm({ ...form, ...data });
    };
    const onChange = value => {
        setCaptch(value);
    };

    return (
        <Modal addClass='platform pricingModal' close={props.close}>
            <div className='modal__platform'>
                <motion.div
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4 }}
                    exit={{ opacity: 0, y: 0 }}
                    className='account__create'
                >
                    <div className='account__create-inner'>
                        <h2 className='uniq'>Sign up</h2>


                        <>
                            < button
                                className='button login'

                                // onClick={() => { setModal('platformCreate'); setRoleId(form.accountType) }}


                                onClick={() => { props.setRoleId(form.accountType); props.setReferId(referId); props.setModal('pricingPlatformCreate'); }}
                            >
                                Sign up using
                                <img
                                    src={process.env.PUBLIC_URL + '/images/googleSign.png'}
                                    alt=''
                                />

                            </button>



                            <h5 className='reg'>or</h5>

                            <div className='account__create-row'>
                                <TextInput
                                    value={form.firstName}
                                    type='text'
                                    onChange={setInput('firstName')}
                                    label='First name'
                                    error={error}
                                    errorText='Please enter first name'
                                    autoFocus
                                />
                                <TextInput
                                    value={form.lastName}
                                    type='text'
                                    onChange={setInput('lastName')}
                                    label='Last name'
                                    error={error}
                                    errorText='Please enter last name'
                                />
                            </div>
                            <div className='account__create-email'>
                                <EmailInput
                                    value={form.email}
                                    type='email'
                                    onChange={setInput('email')}
                                    label='E-mail'
                                    error={error}
                                    errorText='Please enter Email'
                                />
                            </div>
                            <div className='account__create-row mb24'>
                                <PasswordInput
                                    value={form.password}
                                    type='password'
                                    onChange={setInput('password')}
                                    label='Password'
                                    error={error}
                                    errorText={form.password === "" ? 'Please enter Password' : "Please enter 8 digit password"}
                                />
                                <PasswordInput
                                    value={form.confirmPassword}
                                    type='password'
                                    onChange={setInput('confirmPassword')}
                                    label='Confirm password'
                                    error={error}
                                    errorText={form.confirmPassword === "" ? 'Please enter Confirmation' : "Please enter 8 digit confirm Password"}
                                />
                            </div>
                            <div>
                                <div className='account__create-rect recaptcha-container'
                                >
                                    <ReCAPTCHA
                                        sitekey={captchaKey}
                                        onChange={onChange}
                                    />

                                    {error && captch == "" &&
                                        <div className="input__error captchaerror">Please select captcha</div>}
                                </div>
                            </div>
                            <div className='checkbox'>
                                <input
                                    type='checkbox'
                                    checked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                />
                                <label htmlFor=''></label>
                                <p>
                                    Accept{' '}
                                    <span onClick={() => props.setSubModal('terms')}>
                                        Terms & Conditions
                                    </span>
                                </p>
                                {(errorTerms || error) && !isChecked && (
                                    <div className="checkbox__error">
                                        You have to accept terms
                                    </div>
                                )}
                            </div>
                            <div className="input__error captchaerror signuperror">{showErrorMessage}</div>
                            <button className='button primary' onClick={createValidation}>
                                Create account
                            </button>

                            <p className='sm'>
                                Already have an account?{' '}
                                <a href="javascript:void(0)" onClick={handleSignInClick}>Sign in</a>
                            </p>
                        </>

                    </div>
                </motion.div>
            </div>
        </Modal>
    );
}
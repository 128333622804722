import { fetchWrapper } from '../Helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}/offers`;

export const offerService = {
  getAll,

  getOfferByOrderId
};
function getAllPaging(options, filterString) {
  filterString += filterString.length > 0 ? `&` : '';
  filterString += `page[number]=${options.page}`;
  if (options.pageSize) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[size]=${options.pageSize}`;
  }
  return filterString;
}
function getAll(options) {
  let filterString = '';
  if (options.filter) {
    filterString = options.filter;
  }
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  const url =
    filterString.length > 0 ? `${baseUrl}?${filterString}` : `${baseUrl}`;
  return fetchWrapper.get(url);
}
function getOfferByOrderId(id) {
  return fetchWrapper.get(
    `${process.env.REACT_APP_API_URL}/offersByOrderId/${id}`
  );
}




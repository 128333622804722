import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Profile, Modal } from "../../Components";
import { arrowLeft, Toast } from "../../Components";
import ListAdd from "./ListAdd";


import ShowInfluencer from "./ShowInfluencer";
import ListMain from "./ListMain";
import { influencerlistService } from '../../Services';
import { ModalConfirmDeleteInfluencer } from "../../Components"
import NotSubscribe from "../../Components/Base/Common/NotSubscribe";
export default function InfluencerList({ goToPricing, setShowCart, updateProfilePage, setUpdateProfilePage, setUserTypeRegOrNon, setCartItem, cartItem, closeCartPanel, setAuth, setModal, user, NotifModul, profiles, setModalNotification, locationData }) {
  const [tab, setTab] = useState("main");
  // eslint-disable-next-line

  const [modalDataList, setModalDataList] = useState(null);
  const [editTabData, setEditTabData] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [toastList, setToastList] = useState([]);
  const [list, setList] = useState([]);
  // const [rowCount, setRowCount] = useState("");
  const [selectDesc, setselectDesc] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState(false);
  const [tabModal, setTabModal] = useState("main");
  const [selectedInfluencer, setSelectedInfluencer] = useState({});
  // const [isUserHasSubscription, setUserHasSubscription] = useState(false);
  const [isUserHasSubscription, setUserHasSubscription] = useState(false);
  useEffect(() => {
    setTab("main");
    setPage(1);
    setSearchText("");
    setselectDesc("")
  }, [locationData.key]);

  useEffect(() => {
    async function fetchData() {
      const filterBy = tab !== 'deleted' ? 'active' : 'inactive';
      let filterString = '';

      if (searchText != "" && selectDesc != "") {
        filterString += '&filter[' + selectDesc + ']=' + searchText;
      }

      try {
        const res = await influencerlistService.getAll({
          page: page,
          pageSize,
          filter: filterString
        });
        if (
          res.meta &&
          res.meta.pagination
        ) {
          setPaginationData(res.meta.pagination);
        }
        //  setRowCount((res.data.length));
        //setList([{ 'id': 1, 'title': "ABC", 'thumbnail': "http://localhost:3000/images/placeholder/avatar.png", 'created_at': '2024-09-09' }, { 'id': 2, 'title': "1ABC", 'thumbnail': "http://localhost:3000/images/placeholder/avatar.png", 'created_at': '2025-09-09' }]);
        setList(res.data);
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, [page, tabModal, searchList, tab]);
  //console.log(list)
  const onPaginate = page => {
    setPage(page);
  };
  useEffect(() => {
    setUserHasSubscription(user !== null ? user.data.isUserHasSubscription == 1 ? true : false : false)
  }, [user]);
  const changeMediumDesc = (item) => {
    setselectDesc(item.id)
    setPage(1);
    setSearchText("");
    setSearchList(!searchList);
  };
  const setSearchTextOnChange = event => {
    if (event.target.value === '') {
      setSearchList(!searchList);
      setselectDesc("");
    }
    setSearchText(event.target.value);
  };
  const search = () => {
    setSearchList(!searchList);
    setPage(1);
  };

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    if (newToastList.length > 0) {
      setToastList(newToastList);
    }

  };


  const removeInfFromList = async () => {
    //console.log("in remove list");
    //setSelectedInfluencer(item)
    //console.log(selectedInfluencer)

    const listId = selectedInfluencer.list_id;
    const id = selectedInfluencer.user_id;
    const inftype = selectedInfluencer.inf_user_type;
    try {
      let option = { 'listId': listId, 'influencer': id, 'action': 'unassign', 'infType': inftype }
      const res = await influencerlistService.removeFromList(option);
      addToast({
        error: false,
        msg: res.message,
        id: `${Date.now()}-${toastList.length}`
      });
      setTabModal(null);
      setSelectedInfluencer({})
      //setCountOfInf(0);
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }

  const deleteList = async () => {
    try {
      if (modalDataList.id) {
        const deleteID = modalDataList.id
        console.log(deleteID);
        const res = await influencerlistService.delete(deleteID, {});
        if (res.status === 'failed') {
          addToast({
            error: true,
            msg: res.message,
            id: `${Date.now()}-${toastList.length}`
          });
        }
        if (res.status !== 'failed') {
          addToast({
            error: false,
            msg: res.message,
            id: `${Date.now()}-${toastList.length}`
          });
        }
        // setRowCount(rowCount - 1);
        setTabModal(null);
        setTab("main");

      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  };
  //console.log("===" + tab)
  return (
    <>
      {isUserHasSubscription &&
        <>
          <div className="main">
            <div className="main__inner">
              <div className="main__inner-title">
                <div className="main__inner-title-col"> <h2>List</h2>
                  {tab !== "main" && (
                    <button
                      className="button tertiary big"
                      onClick={() => { setTab("main"); onPaginate(1); }}
                    >
                      {arrowLeft} Back to list
                    </button>)}
                </div>
                <Profile
                  type='brand'
                  setAuth={setAuth}
                  setModal={setModal}
                  NotifModul={NotifModul}
                  setNotifModul={setModalNotification}
                  profiles={profiles}
                  closeCartPanel={closeCartPanel}
                  user={user}
                  cartItem={cartItem}
                />
              </div>
              {(tab === "main" && isLoaded == true) && (
                <ListMain
                  listAll={list}
                  setTab={setTab}
                  setModalDataList={setModalDataList}
                  paginationData={paginationData}
                  onPaginate={onPaginate}
                  isLoaded={isLoaded}
                  setTabModal={setTabModal}
                  setList={setList}
                  changeMediumDesc={changeMediumDesc}
                  setSearchTextOnChange={setSearchTextOnChange}
                  search={search}
                  searchText={searchText}
                  selectDesc={selectDesc}
                  setShowCart={setShowCart}
                />
              )}
              {tab === "listinfluencer" && <ShowInfluencer setShowCart={setShowCart} setUpdateProfilePage={setUpdateProfilePage} updateProfilePage={updateProfilePage} setUserTypeRegOrNon={setUserTypeRegOrNon} setCartItem={setCartItem} cartItem={cartItem} selectedInfluencer={selectedInfluencer} removeInfFromList={removeInfFromList} setSelectedInfluencer={setSelectedInfluencer} setModalDataList={setModalDataList} setTabModal={setTabModal} modalDataList={modalDataList} setTab={setTab} setToastList={setToastList} toastList={toastList} addToast={addToast} tabModal={tabModal}
                user={user} />}
            </div>
          </div>



          {tabModal === "delete" && (
            <Modal addClass="modalAsset" close={() => setTabModal(null)}>
              <div className="modal__asset">
                <div className="modal__asset-inner">
                  <div className="modal__assetDetail-delete">
                    <h4>Are you sure you want to delete this list?</h4>
                    <div className="modal__assetDetail-delete-btns">
                      <button
                        className="button secondary"
                        onClick={() => setTabModal("edit")}
                      >
                        Cancel
                      </button>
                      <button className="button primary" onClick={deleteList}>
                        Delete List
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>)}
          {tabModal === "add" &&
            <Modal addClass="modalAsset" close={() => setTabModal(null)}>
              <div className="modal__asset">
                <div className="modal__asset-inner">
                  <ListAdd setTab={setTab} setToastList={setToastList} toastList={toastList} setTabModal={setTabModal} />
                </div>
              </div>
            </Modal>}
          {tabModal === "confirmDelete" &&
            < ModalConfirmDeleteInfluencer setToastList={setToastList} toastList={toastList} close={() => setTabModal(null)} setTabModal={setTabModal} removeFromList={removeInfFromList} />
          }
          {toastList && <Toast list={toastList} setList={setToastList} />}
        </>
      }

      {isUserHasSubscription === false && user !== null && (
        <>
          <NotSubscribe goToPricing={goToPricing} type='brand'
            setAuth={setAuth}
            setModal={setModal}
            NotifModul={NotifModul}
            setNotifModul={setModalNotification}
            profiles={profiles}
            closeCartPanel={closeCartPanel}
            user={user}
            cartItem={cartItem}
            title={"List"}
            background={"background_inflist.png"}>
          </NotSubscribe>
        </>
      )
      }
    </>
  );
}

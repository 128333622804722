import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

import { TextInput, TextArea } from "../../Components";


import {
    influencerlistService,

} from '../../Services';



export default function AddListandInfluencerToList({ handleSearch, setTab, setToastList, toastList, setTabModal, selectedInfluencer }) {

    const tagsWrapper = useRef(null);
    const inpRef = useRef(null);
    const createList = useRef(null);
    const fileRef = useRef(null);
    const [isAsset, setisAsset] = useState(false);
    const [form, setForm] = useState({
        name: "",
        influencer_id: selectedInfluencer.id,
        influencer_type: selectedInfluencer.inf_user_type
    });
    const [error, setError] = useState(false);
    const [listFile, setListFile] = useState("");
    const [listFileDetail, setListFileDetail] = useState("");
    const [disableCreateButton, setDisableCreateButton] = useState(false);
    const updateForm = (data) => {
        setForm({ ...form, ...data });
    };

    const setInput = (key) => (event) =>
        updateForm({ [key]: event.target.value });





    /*useEffect(() => {
      updateForm({ ['mediafile']: assetFile });
    }, [assetFile]);*/
    const submitBtnClick = () => {

        if (form.name === "") {
            setError(true);
            //addToast({ error: true, date: new Date(), msg: 'Enter details to add List.' });
        } else {
            saveList(form);
        }
    };
    const saveList = async () => {
        try {
            //console.log(form)
            // form.influencer = influencer;
            //updateForm({ ['influencer']: influencer });
            const res = await influencerlistService.createListandAddInfluencer(form);
            setTabModal("main")
            handleSearch();
            addToast({ error: false, date: new Date(), msg: res.message });
        } catch (err) {
            addToast({ error: true, date: new Date(), msg: err.message });
            setTabModal("main")
        }
    };

    const addToast = data => {
        const newToastList = [...toastList];
        if (data.msg) {
            if (data.msg[data.msg.length - 1] === ".")
                data.msg = data.msg.slice(0, -1);
        }
        newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
        setToastList(newToastList);
    };

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                createList.current.click();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [form]);
    return (
        <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            exit={{ opacity: 0, y: 30 }}
            className="assetsAdd"
        >
            <div className="assetsAdd__form">
                <div className="assetsAdd__form-title">
                    <h3 className="big">Name Your List</h3>
                </div>
                <div className="assetsAdd__form-inner">
                    <div className="assetsAdd__form-head">
                        <TextInput
                            value={form.name}
                            type="text"
                            onChange={setInput("name")}

                            error={error}
                            errorText="Please enter list name"
                            autoFocus
                        />
                    </div>


                </div>
            </div>
            <div className="campaignsCreate__btns-row">
                <button ref={createList} className="button primary" onClick={submitBtnClick} disabled={disableCreateButton}>
                    Add To List
                </button>
            </div>

        </motion.div>
    );
}

import React from "react";
import Modal from "../../Modal";

export default function ModalNotSubscribe(props) {
  return (
    <Modal addClass="modalCamp userNotSubscribe" >
      <div className="filterModal modal__camp">
        <div className="modal__assetDetail-delete perm long lock-feture-screen">
          <h4>Subscribe to Access Feature</h4>
          <div className="dropdown-item">
            <div className="unlock-box">
              <svg
                data-slot="icon"
                fill="#ffffff"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                width={70}

              >
                <path
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                  d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z"
                ></path>
              </svg>
            </div>
            <p>
              Subscription needed for this functionality
            </p>
          </div>
          <div className="modal__assetDetail-delete-btns">
            <button className="button primary" onClick={props.goToPricing}>
              Go to Pricing
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

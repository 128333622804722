import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import CryptoJS from "crypto-js";
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  Header,
  Sidebar,
  NotificationModul,
  ModalSwitch,
  Modal,
  closeIcon,
  Toast,
  Cart
} from '../Components';
import { accountService, billingService, campaignService, searchProfileService, orderService } from '../Services';
import { PrivateRoute } from '../Helpers';
import { storageHandler } from "../Helpers"
import {
  Billing,
  BrandProfile,
  Assets,
  BrandDashboard,
  Campaigns,
  ProductGuidelines,
  Recommend,
  SearchInf,
  InfluencerList,
  Pricing,
  InfluencerProfile,
  Offer,
  OrderDetails,
  Order,
  Report
} from './index';

export default function Brand({ user, setChangePage, changePage, path, allNotifications,
  setModalNotification,
  setModalNotificationData,
  updateNotificationStatus,
  accountCount, locationData, impersonationBar, handleAnchorClick, setSaveCampaign, setShowConfirmModal, setShowConfirmAction, saveCampaign
}) {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const [NotifModul, setNotifModul] = useState(NotificationModul);
  const [modalData, setModalData] = useState(null);
  const [modal, setModal] = useState(null);
  const [menu, setMenu] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const location = useLocation();
  const [toastList, setToastList] = useState([]);
  const [totalToPay, setTotalToPay] = useState()
  const [showCart, setShowCart] = useState(false);
  const [cartItem, setCartItem] = useState([])
  const [payAccount, setPayAccount] = useState("paypal");
  const [closeCartFirsttime, setCloseCartFirsttime] = useState(true);
  const navigate = useNavigate();
  const [newNavigation, setNewNavigation] = useState({});
  const [availableBalance, setAvailableBalance] = useState(0);
  const [isAvailableBalnceLoaded, setIsAvailableBalnceLoaded] = useState(false);
  const [subTotalAmt, setSubTotalAmt] = useState(0);
  const [orderCreated, setOrderCreated] = useState(false);
  const [userTypeRegOrNon, setUserTypeRegOrNon] = useState();
  const [updateProfilePage, setUpdateProfilePage] = useState(true);
  //console.log(locationData)
  useEffect(() => {
    if (user) {
      const roleId =
        user['data']['roles'].length > 0
          ? user['data']['roles'].filter(
            item => item['id'] == user['data']['active_role']
          )[0]['id']
          : '';
      if (roleId === 1) {
        //navigate('/influencer/dashboard');
      } else if (roleId === 2) {
        //navigate('/admin/dashboard');
      }
    }

  }, []);

  useEffect(() => {
    async function fetchBalance() {

      try {
        const res = await searchProfileService.getBalance();
        setAvailableBalance(parseFloat(res.wallet_details.available.replace(/[\$,]/g, '')));
        setIsAvailableBalnceLoaded(true)
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }

    }

    fetchBalance()
  }, [showCart, cartItem]);
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    if (modal) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [modal]);
  const deleteNotif = id => {
    setNotifModul(NotifModul.filter(item => item.id !== id));
    if (modal === 'notifDelete') {
      setModal('notifications');
    } else if (modal === 'notifDeleteDash') {
      setModal(null);
    }
  };
  const leaveCampaign = () => {
    //console.log("dsd")
  };
  useEffect(() => {
    if (menu) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [menu]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
    document.body.classList.remove('active');
    setShowCart(false)
  }, [location]);
  const close = e => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  async function getActiveOpportunityCount() {
    try {
      const res = await campaignService.getCount();
      setNewNavigation({ 'campaigns_count': res.campaign_count });
    } catch (err) {
      addToast({ error: true, date: new Date(), msg: err });
    }
  }

  useEffect(() => {
    getProfiles();
    getActiveOpportunityCount();
  }, []);


  const addMoneyForCartFromPaypal = async () => {
    let option = {}
    const payload = {
      amount: totalToPay,
      description: "Offer Order for " + totalToPay,
      account: payAccount,
      type: "offer",
      subTotalAmt: subTotalAmt,
      totalToPay: totalToPay,
      cartItem: cartItem

    };
    try {

      const res = await billingService.addBalance(payload);

      window.location.replace(res.url);
    } catch (error) {
      addToast({
        error: true,
        msg: error,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  };
  useEffect(() => {

    if (cartItem.length > 0) {

    }
  }, [cartItem]);
  useEffect(() => {

    setShowCart(false);

  }, [closeCartFirsttime]);

  const goToPricing = () => {
    navigate("/brand/pricing");
  }
  const closeCartPanel = () => {
    setShowCart(!showCart);
    //setCloseCartFirsttime(true)
  }
  const removeCartItem = (indexToRemove) => {
    const newItems = cartItem.filter((item, index) => index !== indexToRemove);

    // cartItem.splice(index)
    setCartItem(newItems)
    let decryptedData = storageHandler.getCartItem();
    if (decryptedData !== null) {
      const storedCartItem = decryptData(decryptedData, secretKey);
      let getcartItem = [];
      let getcartItem_base = storedCartItem.length > 0 && JSON.parse(storedCartItem);
      console.log(user)
      getcartItem = getcartItem_base.filter(item => item.cart_item_addedBy === user.data.id)

      let savedCart = (storedCartItem.length > 0 && JSON.parse(storedCartItem))
      if (savedCart.length > 0) {
        const newItems = savedCart.filter((item, index) => index !== indexToRemove);
        storageHandler.setCartItem(JSON.stringify(newItems));
        let encrypteddata = (encryptData(JSON.stringify(newItems), secretKey));
        //setCartItem(prevArray => [...prevArray, newObject]);
        storageHandler.setCartItem(encrypteddata);
      }


    }
    // setShowCart(true)
  }

  async function getProfiles() {
    try {
      const res = await accountService.getProfiles({});
      if (res && res['data']) {
        setProfiles(res['data']['profiles']);
      }
    } catch (err) {
      //console.log(err);
    }
  }

  const decryptData = (cipherText, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };
  const encryptData = (data, secretKey) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
  };

  //console.log(user ? user.data.isUserHasSubscription : '')
  async function checkout() {


    try {
      const res = await searchProfileService.getBalance();
      let priceval = res.wallet_details.available;
      let availableWallet = parseFloat(priceval.replace('$', ''));
      //console.log(parseFloat(priceval.replace('$', '')));
      //console.log(availableBalance)

      //  if (res.wallet_details.available.substring(1) == availableBalance) {
      try {
        //let storedCartItem = storageHandler.getCartItem();
        let decryptedData = storageHandler.getCartItem();
        if (decryptedData !== null) {
          const storedCartItem = decryptData(decryptedData, secretKey);
          let getcartItem = [];
          let getcartItem_base = storedCartItem.length > 0 && JSON.parse(storedCartItem);
          if (user) {
            getcartItem = getcartItem_base.filter(item => item.cart_item_addedBy === user.data.id)
          } else {
            getcartItem = getcartItem_base
          }

          let subtotalVal = 0;
          getcartItem.length > 0 &&
            getcartItem.map(item => {
              //console.log(item.price)
              subtotalVal = Number(subtotalVal) + (Number(item.price))
            })

          let subTotalShow = subtotalVal < availableWallet ? availableWallet : subtotalVal;
          let totalToPay = subTotalShow - availableWallet;

          let option = { 'subTotalAmt': subtotalVal, 'totalToPay': totalToPay, 'cartItem': getcartItem }
          const res = await orderService.createOrder(option);
          //console.log(res)
          if (res.message === 'paypal') {
            addMoneyForCartFromPaypal()
          } else {

            storageHandler.deleteCartItem();
            let encrypteddata = (encryptData(JSON.stringify([]), secretKey));
            //setCartItem(prevArray => [...prevArray, newObject]);
            storageHandler.setCartItem(encrypteddata);
            //storageHandler.setCartItem(JSON.stringify([]));
            setCartItem([]);
            setShowCart(false);
            setOrderCreated(true)
            navigate("/brand/orderDetails/" + res.id);
          }
        }

      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }


    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }


  }

  const hideHeaderSidebar =
    path.indexOf('product_guidelines') > -1 ? true : false;

  return (
    <>
      <div className={impersonationBar ? "impersonationDivMargin" : ''}>
        {!hideHeaderSidebar && (
          <>
            <Header
              setModal={setModal}
              menu={menu}
              setMenu={setMenu}
              user={user}
              profiles={profiles}
            />
            <Sidebar menu={menu} handleAnchorClick={handleAnchorClick} close={close} user={user} type={'brand'} newNavigation={newNavigation} impersonationBar={impersonationBar} />
          </>
        )}
        <main>
          <Routes>
            <Route
              exact
              path='/billing'
              element={
                <PrivateRoute>
                  <Billing
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    closeCartPanel={closeCartPanel}
                    setCartItem={setCartItem}
                    cartItem={cartItem} />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/profile'
              element={
                <PrivateRoute>
                  <BrandProfile
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    closeCartPanel={closeCartPanel}
                    cartItem={cartItem}

                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/'
              element={
                <PrivateRoute>
                  <Billing user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    closeCartPanel={closeCartPanel}
                    cartItem={cartItem}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/orderDetails/:id'
              element={
                <PrivateRoute>
                  <OrderDetails user={user}
                    goToPricing={goToPricing}
                    setOrderCreated={setOrderCreated}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    closeCartPanel={closeCartPanel}
                    cartItem={cartItem}
                    orderCreated={orderCreated}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/influencerlist'
              element={
                <PrivateRoute>
                  <InfluencerList
                    goToPricing={goToPricing}
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    locationData={locationData}
                    closeCartPanel={closeCartPanel}
                    cartItem={cartItem}
                    setCartItem={setCartItem}
                    setShowCart={setShowCart}
                    updateProfilePage={updateProfilePage}
                    setUpdateProfilePage={setUpdateProfilePage}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/assets'
              element={
                <PrivateRoute>
                  <Assets user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    locationData={locationData}
                    closeCartPanel={closeCartPanel}
                    cartItem={cartItem}
                    setCartItem={setCartItem} />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/dashboard'
              element={
                <PrivateRoute>
                  <BrandDashboard
                    user={user}
                    setModalSwitch={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotify={setModalNotification}
                    setModalNotification={setModalNotification}
                    setModalNotificationData={setModalNotificationData}
                    accountCountNotification={accountCount}
                    updateNotificationStatus={updateNotificationStatus}
                    closeCartPanel={closeCartPanel}
                    cartItem={cartItem}
                    setCartItem={setCartItem}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/campaigns'
              element={
                <PrivateRoute>
                  <Campaigns
                    setShowConfirmModal={setShowConfirmModal}
                    setShowConfirmAction={setShowConfirmAction}
                    user={user}
                    setModalSwitch={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    locationData={locationData}
                    saveCampaign={saveCampaign}
                    setSaveCampaign={setSaveCampaign}
                    closeCartPanel={closeCartPanel}
                    cartItem={cartItem}
                    setCartItem={setCartItem}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/recommendations'
              element={
                <PrivateRoute>
                  <Recommend
                    user={user}
                    setModalSwitch={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    closeCartPanel={closeCartPanel}
                    cartItem={cartItem}
                    setCartItem={setCartItem}
                  />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path='/influencerdiscovery'
              element={
                <PrivateRoute>
                  <SearchInf
                    goToPricing={goToPricing}
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    closeCartPanel={closeCartPanel}
                    cartItem={cartItem}
                    setCartItem={setCartItem}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/offers'
              element={
                <PrivateRoute>
                  <Offer
                    goToPricing={goToPricing}
                    checkout={checkout}
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    closeCartPanel={closeCartPanel}
                    cartItem={cartItem}
                    setCartItem={setCartItem}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/reports'
              element={
                <PrivateRoute>
                  <Report
                    checkout={checkout}
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    closeCartPanel={closeCartPanel}
                    cartItem={cartItem}
                    setCartItem={setCartItem}
                    goToPricing={goToPricing}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/orders'
              element={
                <PrivateRoute>
                  <Order
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    closeCartPanel={closeCartPanel}
                    cartItem={cartItem}
                    setCartItem={setCartItem}
                    goToPricing={goToPricing}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/influencerProfile/*'
              element={
                <PrivateRoute>
                  <InfluencerProfile
                    user={user}
                    setModal={setModal}
                    setShowCart={setShowCart}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    closeCartPanel={closeCartPanel}
                    setCartItem={setCartItem}
                    cartItem={cartItem}
                    setCloseCartFirsttime={setCloseCartFirsttime}
                    userTypeRegOrNon={userTypeRegOrNon}
                    updateProfilePage={updateProfilePage}
                    goToPricing={goToPricing}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/pricing'
              element={
                <PrivateRoute>
                  <Pricing
                    user={user}
                    setModalSwitch={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    closeCartPanel={closeCartPanel}
                    cartItem={cartItem}
                    setCartItem={setCartItem}
                  />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path='/product_guidelines'
              element={
                <PrivateRoute>
                  <ProductGuidelines user={user} />
                </PrivateRoute>
              }
            />

            <Route path="*" element={
              <PrivateRoute>
                <BrandDashboard
                  user={user}
                  setModalSwitch={setModal}
                  profiles={profiles}
                  NotifModul={allNotifications}
                  setModalNotify={setModalNotification}
                  setModalNotification={setModalNotification}
                  setModalNotificationData={setModalNotificationData}
                  updateNotificationStatus={updateNotificationStatus}
                  closeCartPanel={closeCartPanel}
                  cartItem={cartItem}
                  setCartItem={setCartItem}
                />
              </PrivateRoute>
            } />
          </Routes>
        </main>
      </div>
      {showCart && isAvailableBalnceLoaded &&
        <Cart user={user} updateProfilePage={updateProfilePage} setUpdateProfilePage={setUpdateProfilePage} setSubTotalAmt={setSubTotalAmt} subTotalAmt={subTotalAmt} checkout={checkout} setTotalToPay={setTotalToPay} totalToPay={totalToPay} availableBalance={availableBalance} showCart={showCart} setShowCart={setShowCart} cartItem={cartItem} removeCartItem={removeCartItem}>
        </Cart>

      }
      <AnimatePresence>
        {modal === 'switch' && (
          <ModalSwitch
            changePage={changePage}
            setChangePage={setChangePage}
            profiles={profiles}
            close={() => setModal(null)}
          />
        )}
      </AnimatePresence>
      {toastList && <Toast list={toastList} setList={setToastList} />}
    </>
  );
}
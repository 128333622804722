import React from "react";

import DatePicker from "react-datepicker";

export default function DateInput({
  startDate,
  disabled,
  placeholder,
  onChange,
  label,
  error,
  value,
  errorText,
  icon,
  readOnly,
  minDate
}) {
  return (
    <div className="input__outer">
      {label && <label>{label}</label>}
      <div
        className={
          "input " +
          (error && value === "" ? "error " : "") +
          (icon ? "wIcon" : "")
        }
      >
        <DatePicker
          disabled={disabled}
          onChange={onChange}
          startDate={disabled ? "" : startDate}
          placeholderText={placeholder}
          selected={startDate}
          disabled={readOnly}
          minDate={minDate}
        />
        {icon && <div className="input__icon simple">{icon}</div>}
      </div>
      {error && value === "" && (
        <div className="input__error">{errorText}</div>
      )}
    </div>
  );
}

import React from "react";
import { viewReport, editReport } from "../../Components";
export default function ReportItem(props) {
  function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }
  return (
    <tr >
      <td>
        <p>{props.item.title}</p>
      </td>

      <td>{props.item.inf_cnt}</td>


      <td>{props.item.start_date}  -  {(props.item.end_date)}</td>
      <td>0</td>

      <td>
        <p>{capitalizeFirstLetter(props.item.status)}</p>
      </td>
      <td>
        <p className="reportEdit">
          <div title="View" onClick={() => props.openViewReport(props.item)}>{viewReport} </div>{"    "}
          <div title="Edit" onClick={() => props.editReport(props.item)}> {editReport}</div></p>
      </td>
    </tr>
  );
}

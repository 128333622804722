import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { chevronBottom, notifIcon } from './SVG';
import { accountService } from '../../Services';
import { storageHandler } from "../../Helpers"
import CryptoJS from "crypto-js";
export default function Profile(props) {
  const navigate = useNavigate();
  const wrapper = useRef(null);
  const [isRead, setIsRead] = useState(0);
  const [cartItemCount, setCartItemCount] = useState(props.cartItem && props.cartItem.length);
  const [dropdown, setDropdown] = useState(false);
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const data = props.user ? (props.user.data ? props.user.data : null) : null;
  let profilePath = ``;
  if (data) {
    const roleId =
      data['roles'].length > 0
        ? data['roles'].filter(item => item['id'] == data['active_role'])[0][
        'id'
        ]
        : '';
    if (roleId === 1) {
      profilePath = `/influencer/profile`;
    } else if (roleId === 3) {
      profilePath = `/brand/profile`;
    } else if (roleId === 2) {
      profilePath = `/admin/profile`;
    }
  }
  const decryptData = (cipherText, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };
  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setDropdown(false);
    };

    if (dropdown) window.addEventListener('click', windowClick);
    else window.removeEventListener('click', windowClick);

    return () => window.removeEventListener('click', windowClick);
  }, [dropdown]);
  useEffect(() => {
    let decryptedData = storageHandler.getCartItem();
    if (decryptedData !== null && props.user) {
      const storedCartItem = decryptData(decryptedData, secretKey);
      let getcartItem_base = storedCartItem.length > 0 && JSON.parse(storedCartItem);
      if (getcartItem_base) {
        let getcartItem = getcartItem_base.filter(item => item.cart_item_addedBy === props.user.data.id)

        setCartItemCount(getcartItem.length > 0 ? getcartItem.length : 0)
      } else {
        setCartItemCount(0)
      }

    }

  }, [props.cartItem]);

  const logout = () => {
    accountService.logout(() => {
      navigate('/account/login');
    });
  };

  return (
    <div className='profile'>
      {props.type === "brand" &&
        <div className="cartBox" onClick={() => props.closeCartPanel()}>

          <p className="cartCount">{cartItemCount}</p>
          <svg data-slot="icon" fill="none" stroke-width="1.7" stroke="#6C746B" viewBox="0 0 24 24"
            width="22" height="22" className='carticon'
            xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"></path>
          </svg>

        </div>}

      <div
        className={'profile__icon ' + (props.NotifModul.length > 0 ? props.NotifModul[0].activeNotification === 0 ? 'read' : '' : 'read')}
        onClick={() => { props.setNotifModul("notifications"); }}
      >
        {notifIcon}
      </div>
      <div
        className={'profile__inner-wrapper ' + (dropdown ? 'active' : '')}
        ref={wrapper}
        onClick={() => setDropdown(!dropdown)}
      >
        <div className='profile__inner'>
          <div className='profile__inner-content'>
            <div className='profile__inner-image'>
              <img
                style={{ width: '48px', height: '48px' }}
                src={
                  data && data.photo && data.photo !== ''
                    ? data.photo
                    : `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`;
                }}
                alt='avatar'
              />
            </div>
            <div className='profile__inner-info'>
              <p className='sm'>
                {data &&
                  data.influencers &&
                  data.influencers.length > 0 &&
                  data.influencers[0].social_handle
                  ? data.influencers[0].social_handle
                  : ''}
              </p>
              <p className='sm'>
                {data && data.firstName ? data.firstName : ''}{' '}
                {data && data.lastName ? data.lastName : ''}
              </p>
            </div>
          </div>
          {chevronBottom}
        </div>
        <div className='profile__dropdown'>
          <Link to={profilePath} className='profile__dropdown-link'>
            My Profile
          </Link>
          {props.profiles.length > 1 && (
            <button
              type='button'
              className='profile__dropdown-link'
              onClick={() => props.setModal('switch')}
            >
              Switch account
            </button>
          )}
          <Link
            to='/'
            className='profile__dropdown-link'
            onClick={() => logout()}
          >
            Log out
          </Link>
        </div>
      </div>
    </div >
  );
}

import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { utils } from '../../Helpers/utils';
import {
  SearchInput,
  SelectInput,
  Pagination,
  download,
  plusIcon,
  searchIcon,
  Profile,
  Toast
} from '../../Components';
import OfferItem from './OfferItem';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { offerService } from '../../Services';
import NotSubscribe from '../../Components/Base/Common/NotSubscribe';
const selectDescList = [
  { id: '1', value: 'All' },
  { id: 'status', value: 'Status' },
  { id: 'type', value: 'Offer type' },
  { id: 'rate', value: 'Rate' }
];

export default function Offer({
  setAuth,
  setModal,
  NotifModul,
  user,
  profiles,
  setModalNotification,
  closeCartPanel,
  cartItem,
  checkout,
  goToPricing

}) {
  // eslint-disable-next-line
  const [selectDesc, setselectDesc] = useState(selectDescList[0].value);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isloaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [offerDetails, setOfferDetails] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [searchList, setSearchList] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [disableSearchBox, setDisableSearchBox] = useState(false);
  const [showListing, setShowListing] = useState(true);
  const location = useLocation();
  console.log(user)
  const [isUserHasSubscription, setUserHasSubscription] = useState(false);
  useEffect(() => {
    let offersucess = searchParams.get('offersuccess') !== null ? searchParams.get('offersuccess') : '';
    //let successMessage = searchParams.get('success') !== null ? searchParams.get('success') == 1 ? "Plan  is successfully subscribe." : '' : '';
    if (offersucess !== '') {
      setUserHasSubscription(true);
      setShowListing(false)
      checkout()
    } else {
      setShowListing(true)
    }
  }, []);

  useEffect(() => {
    setUserHasSubscription(user !== null ? user.data.isUserHasSubscription == 1 ? true : false : false)
  }, [user]);

  useEffect(() => {
    async function fetchData() {
      try {
        let filter = '';
        if (searchString !== '') {
          if (filter != "") {
            filter += '&filter[' + selectDesc + ']=' + searchString;
          } else {
            filter = 'filter[' + selectDesc + ']=' + searchString;
          }
        } else {
          if (selectDesc === 'lastyr' || selectDesc === '30days') {
            filter = 'filter[created_at]=' + selectDesc;
          }
        }
        const res = await offerService.getAll({
          page: page,
          pageSize,
          filter: filter
        });
        console.log(res)
        if (
          res['meta'] &&
          res['meta']['pagination']
        ) {
          console.log("insode")
          setPaginationData(res['meta']['pagination']);
        }
        console.log(paginationData)
        setOfferDetails(res.data);

        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, [page, searchList]);
  const downloadCsv = async () => {
    try {
      let filter = "";
      if (searchString !== '') {
        if (filter != "") {
          filter += '&filter[' + selectDesc + ']=' + searchString;
        } else {
          filter = 'filter[' + selectDesc + ']=' + searchString;
        }
      } else {
        if (selectDesc === 'lastyr' || selectDesc === '30days') {
          filter = 'filter[created_at]=' + selectDesc;
        }
      }
      const response = await offerService.getAll({
        page: page,
        pageSize,
        filter: filter,
        fileType: 'csv'
      });
      utils.downloadFile(response, "offer_page_" + page + ".csv");
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const onPaginate = page => {
    setPage(page);
  };

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };


  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        search();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [searchString]);


  const changeMediumDesc = item => {
    setselectDesc(item.id)
    setPage(1);
    if (item.id === 'lastyr' || item.id === '30days') {
      setDisableSearchBox(true);
    } else {
      setDisableSearchBox(false);
    }
    setSearchString("");
    setSearchList(!searchList);
  };

  const setSearchStringText = event => {
    if (event.target.value === '') {
      setSearchList(!searchList);
    }
    setSearchString(event.target.value);
  };

  const search = () => {
    setSearchList(!searchList);
    setPage(1);

  };
  console.log(offerDetails)
  return (
    <>
      {isUserHasSubscription === true &&
        <>
          < div className='main'>
            <div className='main__inner'>
              <div className='main__inner-title'>
                <h2>Offers</h2>
                <Profile
                  type='brand'
                  setAuth={setAuth}
                  setModal={setModal}
                  NotifModul={NotifModul}
                  user={user}
                  profiles={profiles}
                  setNotifModul={setModalNotification}
                  closeCartPanel={closeCartPanel}
                  cartItem={cartItem}
                />
              </div>
              {isloaded && showListing && (
                <div className='billing dashboard'>
                  <div className='billing__table-title pag paginationEnd'>
                    <div className='billing__table-title-inner'>
                      <SelectInput
                        list={selectDescList}
                        selected={selectDescList[0]}
                        onChange={changeMediumDesc}
                      />
                      <SearchInput
                        type='text'
                        icon={searchIcon}
                        placeholder='Search'
                        onClick={search}
                        onChange={setSearchStringText}
                        value={searchString}
                        disable={disableSearchBox}
                      />

                    </div>
                  </div>
                  <div className='billing__table'>
                    <table>
                      <thead>
                        <tr>
                          <th>Influencer</th>
                          <th>Product</th>
                          <th>Rate</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(offerDetails.length > 0) ? (
                          <>
                            {offerDetails.map((item, index) => {
                              return (
                                <OfferItem

                                  key={index}
                                  {...item}
                                />
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td colSpan='5' style={{ textAlign: 'center' }}>
                              No records
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className='billing__table-footer paginationEnd'>

                    {offerDetails && offerDetails.length > 0 &&
                      <Pagination
                        perPage={paginationData['per_page']}
                        total={paginationData['total']}
                        currentPage={paginationData['current_page']}
                        totalPages={paginationData['total_pages']}
                        onPaginate={onPaginate}
                      />
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
          {toastList && <Toast list={toastList} setList={setToastList} />}
        </>
      }
      {isUserHasSubscription === false && user !== null && (
        <>
          <NotSubscribe goToPricing={goToPricing} type='brand'
            setAuth={setAuth}
            setModal={setModal}
            NotifModul={NotifModul}
            setNotifModul={setModalNotification}
            profiles={profiles}
            closeCartPanel={closeCartPanel}
            user={user}
            cartItem={cartItem}
            title={"Offers"}
            background={"background_offers.png"}>
          </NotSubscribe>
        </>
      )
      }
    </>
  );
}

import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ProfileAllPhotosModal from "./ProfileAllPhotosModal";
import { AnimatePresence } from "framer-motion";
import ImageSlider from "./image-slider";
import { useSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom'
import CryptoJS from "crypto-js";
import { utils } from '../../Helpers/utils';
import { FacebookCardModul } from "../Rates/Card/FacebookCardModul";
import InnerRatePanelBox from "./InnerRatePanelBox";
import HalfRating from "../SearchInf/HalfRating";
import ModalHowItWorks from "./ModalHowItWorks";

import AddListandInfluencerToList from "../SearchInf/AddListandInfluencerToList";
import {
  searchProfileService,
  influencerlistService
} from '../../Services';
import NotSubscribe from "../../Components/Base/Common/NotSubscribe";
import { Toast, ModalAddInfToList, ModalConfirmDeleteInfluencer, assignToListIconRed, removeProfile } from "../../Components";
import { storageHandler } from '../../Helpers';
import { Modal, AddOffer, Profile, showAllPhoto, tiktokFollowers, youtubeFollowers, fbFollowers, howDoesItWork, instaFollowers, shareProfileButton, saveProfile } from "../../Components"
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 4 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InfluencerProfile({ goToPricing, updateProfilePage, userTypeRegOrNon, setCloseCartFirsttime, setShowCart, setCartItem, cartItem,
  closeCartPanel, setAuth, user, NotifModul, profiles, setModalNotification }) {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const [value, setValue] = React.useState(0);
  const [influencerProfile, setInfluencerProfile] = useState();
  const [isLoadedInfluencer, setIsLoadedInfluencer] = useState(false);
  const [fcbkModul, setFcbkModul] = useState([]);
  const [instModul, setInstModul] = useState([]);
  const [tiktokModul, setTiktokModul] = useState([]);
  const [youtubeModul, setyoutubeModul] = useState([]);
  const [selectedInfluencer, setSelectedInfluencer] = useState({});
  const [allInfluencerImage, setAllInfluencerImage] = useState([]);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  let pathSegments = (location.pathname.split('/'));
  const lastElement = pathSegments[pathSegments.length - 1];
  let userType = JSON.parse(storageHandler.getUserTypeForProfileItem())
  const [influencer, setInfluencer] = useState(lastElement);
  const [influencerUserType, setInfluencerUserType] = useState(userType);
  const [influencerFound, setInfluencerFound] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [modal, setModal] = useState(null);
  const [saveToggle, setSaveToggle] = useState(false);
  const [tabIndex, setTabIndex] = useState();
  const [istabIndex, setIsTabIndex] = useState(false);
  const [isUserHasSubscription, setUserHasSubscription] = useState(false);
  const showallphotosModal = () => {
    setShowCart(false)
    setModal("showallphotos");
  };
  const handleChange = (event, newValue) => {
    //console.log(newValue)
    setValue(newValue);
  };

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  const handleSearch = () => {
    setModal(null);
    setSaveToggle(!saveToggle);
  }
  const addCustomOffer = () => {
    setModal('addOffer')
  }
  useEffect(() => {
    setInfluencer(lastElement)
    setInfluencerUserType(userType)
  }, [updateProfilePage])
  useEffect(() => {
    setUserHasSubscription(user !== null ? user.data.isUserHasSubscription == 1 ? true : false : false)
  }, [user]);


  useEffect(() => {

    async function getInfluencerProfileDetail() {
      //debugger;
      try {
        const res = await searchProfileService.searchInfluencerProfile(influencer, influencerUserType);
        if (res) {
          if (res.data.length > 0) {
            //debugger
            console.log(res.data[0])
            setInfluencerProfile(res.data[0]);
            let userImg = res.data[0].user_image;
            let lengthofImg = 0
            if (res.data[0].user_image.length < 3) {
              lengthofImg = (3 - res.data[0].user_image.length);
            }
            if (res.data[0].user_image.length < 3) {
              for (let i = 0; i < lengthofImg; i++) {
                userImg.push({ 'image': '', 'meta_1': '', 'meta_2': '' })
              }
              setAllInfluencerImage(userImg);
            } else {
              setAllInfluencerImage(userImg);
            }
            setInfluencerFound(true)

          } else {
            setInfluencerFound(false)
          }
          setIsLoadedInfluencer(true)
        }
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    getInfluencerProfileDetail();
  }, [saveToggle, influencer, updateProfilePage]);
  const fetchAccounts = async () => {
    let userType = JSON.parse(storageHandler.getUserTypeForProfileItem())
    try {
      const res = await searchProfileService.getSocialAccounts(influencer, userType);
      if (res['platform'] && res['platform'].length > 0) {
        const fbAccounts = res['platform'].filter(
          item => item['socialType'] === 'Facebook'
        );
        const instaAccounts = res['platform'].filter(
          item => item['socialType'] === 'Instagram'
        );
        const tiktokAccounts = res['platform'].filter(
          item => item['socialType'] === 'TikTok'
        );
        const youtubeAccounts = res['platform'].filter(
          item => item['socialType'] === 'Youtube'
        );
        setInstModul(prepareData(instaAccounts[0]));
        setFcbkModul(prepareData(fbAccounts[0]));
        setTiktokModul(prepareData(tiktokAccounts[0]));
        setyoutubeModul(prepareData(youtubeAccounts[0]));


        let index = 0;
        let tabIndex = [];
        if (prepareData(instaAccounts[0]).length > 0) {
          index = index + 1;
          tabIndex['instModul'] = index;
        }
        if (prepareData(tiktokAccounts[0]).length > 0) {
          index = index + 1;
          tabIndex['tiktokModul'] = index;
          tabIndex.push(index)
        }
        if (prepareData(youtubeAccounts[0]).length > 0) {
          index = index + 1;
          tabIndex['youtubeModul'] = index;
          //tabIndex.push(index)
        }
        if (prepareData(fbAccounts[0]).length > 0) {
          index = index + 1;
          tabIndex['fcbkModul'] = index;
          //tabIndex.push(index)
        }
        setTabIndex(tabIndex)
        setIsTabIndex(true)
        console.log(tabIndex)



      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        date: new Date()
      });
    }
  };
  const prepareData = data => {
    if (data['handles'] && data['handles'].length === 0) {
      return [];
    }
    const res = data['handles'].map(item => {
      return {
        id: item['id'] ? item['id'] : 0,
        profileName: item['handle'] ? item['handle'] : '',
        posts: item['posts'] ? item['posts'] : 0,
        followers: item['followers'] ? item['followers'] : 0,
        following: item['following'] ? item['following'] : 0,
        profileDesc: item['bio'] ? item['bio'] : '',
        link: item['link'] ? item['link'] : '',
        platform_id: item['platform_id'] ? item['platform_id'] : '',
        followers_count: item['followersCount'] >= 0 ? item['followersCount'] : '',
        rates: getProducts(item['products'])
      };
    });
    return res;
  };

  const getProducts = data => {
    const res = data.map(item => {
      return {
        id: item['id'] ? item['id'] : 0,
        rateName: item['product'] ? item['product'] : '',
        priceText: item['product_description']
          ? item['product_description']
          : '',
        price: item['rate'] ? item['rate'] : 0,
        desc: item['description'] ? item['description'] : '',
        link: item['link'] ? item['link'] : '',
      };
    });

    return res;
  };
  const encryptData = (data, secretKey) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
  };

  const decryptData = (cipherText, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };
  async function addToCart(module, rate, influencerAccountCount) {
    console.log(user)
    //let cartItemNew = [{ ...cartItem }];
    //console.log(cartItemNew);
    let newObject = ({
      'platform_user_id': module.platform_id,
      'influencerAccountCount': influencerAccountCount,
      'followers_count': module.followers_count,
      'description': rate.rateName + " " + rate.desc + (influencerAccountCount > 1 ? "- Followerscount -" + module.followers_count : ''),
      'quantity': 1,
      'profileName': (influencerProfile.firstName) + (influencerProfile.lastName !== "" ? " " + influencerProfile.lastName : ''),
      'profileImage': influencerProfile.profile_image,
      'price': rate.price,
      'product': rate.rateName,
      'product_id': rate.id,
      'to_user_id': influencerProfile.id,
      'to_user_type': influencerProfile.inf_user_type,
      'cart_item_addedBy': user.data.id,
    })
    setCartItem( // Replace the state
      [ // with a new array
        ...cartItem, // that contains all the old items
        newObject // and one new item at the end
      ]
    );
    let encrypteddata = (encryptData(JSON.stringify([ // with a new array
      ...cartItem, // that contains all the old items
      newObject // and one new item at the end
    ]), secretKey));
    //const encryptedText = encryptData("Hello, World!", secretKey);
    //setCartItem(prevArray => [...prevArray, newObject]);
    storageHandler.setCartItem(encrypteddata);
    setShowCart(true)
    //cartItemNew.push()
    // setCartItem(cartItem);
    //setCartItem(prevArray => [...prevArray, newObject]);

    //storageHandler.setCartItem(JSON.stringify(cartItem));
    /*try {
      const payload = { 'productid': id, 'rate': price, ''}
      const res = await offerService.createOffer();
      console.log(res);

    } catch (err) {
      addToast({
        error: true,
        msg: err,
        date: new Date()
      });
    }*/

  }

  const chkLoginandActionAccordingly = (event, influencer) => {
    //event.stopPropagation();
    if (profiles !== undefined) {
      console.log(influencer)
      setSelectedInfluencer(influencer)
      setModal('addtoList')
    } else {
      navigate('/account/pricing');
    }
  }
  const confirmRemoveFromList = async (event, influencer) => {
    event.stopPropagation();
    setSelectedInfluencer(influencer)
    // props.setSelectedInfluencer(influencer);
    setModal('confirmDelete');
  }

  useEffect(() => {
    fetchAccounts();

    let decryptedData = storageHandler.getCartItem();


    if (decryptedData !== null) {
      const storedCartItem = decryptData(decryptedData, secretKey);
      setCartItem(storedCartItem.length > 0 && JSON.parse(storedCartItem))
    }

    setCloseCartFirsttime(false);

  }, [influencer, updateProfilePage]);





  const removeFromList = async (influencer) => {
    console.log(influencer)
    try {
      let option = { 'listId': influencer.list_id, 'influencer': influencer.id, 'action': 'unassign', 'infType': influencer.inf_user_type }

      const res = await influencerlistService.removeFromList(option);
      addToast({
        error: false,
        msg: res.message,
        id: `${Date.now()}-${toastList.length}`
      });
      handleSearch();
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }

  useEffect(() => {

    //if (location.pathname.indexOf("brand/influencerprofile") == -1) {

    setShowCart(false)


    // }
  }, [])

  console.log(value)

  return (
    <>
      {isUserHasSubscription &&
        <div className='main'>
          <div className="main__inner">
            <div className='main__inner-title'>
              <h2>Profile</h2>
              <Profile
                type='brand'
                setAuth={setAuth}
                setModal={setModal}
                NotifModul={NotifModul}
                user={user}
                profiles={profiles}
                setNotifModul={setModalNotification}
                closeCartPanel={closeCartPanel}
                cartItem={cartItem}
              />
            </div>
            {(isLoadedInfluencer && influencerFound == true && istabIndex) &&
              <div className="profile-container">
                <div className="share-save">
                  {/*<div className="share-btn">
                  <button className="search-btn">
                    {shareProfileButton}
                    Share
                  </button>
                </div>*/}
                  <div className="top-icons">
                    <div className="social-icons">
                      {influencerProfile.liststatus != 1 ?
                        <button className="search-btn" onClick={(e) => chkLoginandActionAccordingly(e, influencerProfile)}>
                          {saveProfile}
                          Save
                        </button> :
                        <button className="search-btn" onClick={(e) => confirmRemoveFromList(e, influencerProfile)}>
                          {removeProfile}

                        </button>
                      }
                    </div></div>
                </div>
                <div className="grid profile-images-grid">
                  {

                    allInfluencerImage.map((item, index) => {
                      if (index <= 2)
                        return <div
                          className="cards"
                          style={{ backgroundImage: item.image == "" ? `url( ${process.env.PUBLIC_URL}/images/placeholder/list_image.jpg)` : `url(${item.image})` }}
                        >
                          <div className="card-text-top">
                            <h4>{item.meta_2}</h4>
                          </div>
                          <div className="card-text">
                            <h4>{item.meta_1}</h4>
                            <p>{influencerProfile && influencerProfile.followers} influencer</p>
                          </div>
                        </div>
                    })}


                  <button
                    id="showallphotosbutton"
                    className="show-all-btn"
                    onClick={showallphotosModal}
                  >
                    {showAllPhoto}
                    Show All Photos
                  </button>
                </div>

                <div className="slider">
                  <ImageSlider />
                  <button
                    id="showallphotosbutton"
                    className="show-all-btn"
                    onClick={showallphotosModal}
                  >
                    {showAllPhoto}
                    Show All Photos
                  </button>
                </div>

                <div className="profile-header">
                  <img
                    alt="Profile picture"
                    src={
                      influencerProfile && influencerProfile.profile_image && influencerProfile.profile_image !== ''
                        ? influencerProfile.profile_image
                        : `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`;
                    }}

                  />
                  <div>
                    <h1>
                      {influencerProfile && influencerProfile.firstName} {influencerProfile && influencerProfile.lastName} | {influencerProfile && influencerProfile.categoryName}
                    </h1>
                    <p>{influencerProfile && influencerProfile.country}</p>
                    <div className="social">
                      {influencerProfile &&
                        influencerProfile.account.length > 0 &&
                        influencerProfile.account.map(followers => {
                          if (followers.account_type == 'Instagram') {
                            return <span>
                              {instaFollowers}
                              {utils.convertNumber(followers.followers)} Followers
                            </span>
                          }
                          if (followers.account_type == 'TikTok') {
                            return <span>
                              {tiktokFollowers}
                              {utils.convertNumber(followers.followers)} Followers
                            </span>
                          }
                          if (followers.account_type == 'Youtube') {
                            return <span>
                              {youtubeFollowers}
                              {utils.convertNumber(followers.followers)} Followers
                            </span>
                          }
                          if (followers.account_type == 'Facebook') {
                            return <span>
                              {fbFollowers}
                              {utils.convertNumber(followers.followers)} Followers
                            </span>
                          }

                        })

                      }


                    </div>
                  </div>
                </div>

                <div className="badge">
                  {influencerProfile &&
                    <div className="rating">
                      <div className="star-container " data-rating="0">
                        <HalfRating infrating={influencerProfile.rating}></HalfRating>
                      </div>
                    </div>}
                </div>
                <div className="description">
                  <p>
                    {influencerProfile && influencerProfile.bio}
                  </p>
                </div>
                <div className="packages">
                  <div className="packages-heading" onClick={() => { setShowCart(false); console.log("sdf"); setModal('howitworks') }}>
                    <span>Packages</span>
                    <span className="curserForHowitWork" onClick={() => { setShowCart(false); setModal('howitworks'); }}></span>{howDoesItWork}
                    How does it work?
                  </div>


                  <Box sx={{ width: "100%" }} className="tab-box">
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        aria-label="basic tabs example"
                      >
                        <Tab label="All" {...a11yProps(0)} onClick={() => { setValue(0); setShowCart(false); }} />



                        {instModul.length > 0 && tabIndex &&


                          <Tab onClick={() => { setValue(tabIndex['instModul']); setShowCart(false); }}
                            label="Instagram" {...a11yProps(tabIndex['instModul'])} />}

                        {tiktokModul.length > 0 && tabIndex && <Tab
                          onClick={() => { setValue(tabIndex['tiktokModul']); setShowCart(false); }}
                          label="Tiktok" {...a11yProps(tabIndex['tiktokModul'])} />}
                        {youtubeModul.length > 0 && tabIndex && <Tab label="YouTube"
                          onClick={() => { setValue(tabIndex['youtubeModul']); setShowCart(false); }} {...a11yProps(tabIndex['youtubeModul'])} />}
                        {fcbkModul.length > 0 && tabIndex && <Tab
                          onClick={() => {
                            setValue(tabIndex['fcbkModul']); setShowCart(false);
                          }} label="FaceBook" {...a11yProps(tabIndex['fcbkModul'])} />}
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                      <div className="inner-packages">
                        <InnerRatePanelBox influencerUserType={influencerUserType} addToCart={addToCart} package={"all"} instModul={instModul} tiktokModul={tiktokModul} youtubeModul={youtubeModul} fcbkModul={fcbkModul}></InnerRatePanelBox>
                      </div>
                    </CustomTabPanel>
                    {instModul.length > 0 &&
                      <CustomTabPanel value={value} index={tabIndex['instModul']}>
                        <div className="inner-packages">
                          <InnerRatePanelBox influencerUserType={influencerUserType} addToCart={addToCart} package={"insta"} instModul={instModul} ></InnerRatePanelBox>
                        </div>
                      </CustomTabPanel>}

                    {tiktokModul.length > 0 &&
                      <CustomTabPanel value={value} index={tabIndex['tiktokModul']}>
                        <div className="inner-packages"> <InnerRatePanelBox influencerUserType={influencerUserType} addToCart={addToCart} package={"tiktok"} tiktokModul={tiktokModul} ></InnerRatePanelBox></div>
                      </CustomTabPanel>}
                    {youtubeModul.length > 0 &&
                      <CustomTabPanel value={value} index={tabIndex['youtubeModul']}>
                        <div className="inner-packages"><InnerRatePanelBox influencerUserType={influencerUserType} addToCart={addToCart} package={"youtube"} youtubeModul={youtubeModul}></InnerRatePanelBox></div>
                      </CustomTabPanel>}
                    {fcbkModul.length > 0 &&
                      <CustomTabPanel value={value} index={tabIndex['fcbkModul']}>
                        <div className="inner-packages"><InnerRatePanelBox influencerUserType={influencerUserType} addToCart={addToCart} package={"facebook"} fcbkModul={fcbkModul}></InnerRatePanelBox></div>
                      </CustomTabPanel>}

                    {(((instModul.length > 0 || fcbkModul.length > 0 || youtubeModul.length > 0 || tiktokModul.length > 0) && (influencerUserType != 'registered'))) &&

                      <div className="bottom-row">
                        <div className="smallTextForAsterisk"><span className="asterisk">*</span> Price for all Products determined by AI

                        </div>

                      </div>
                    }


                    <CustomTabPanel className="addCustomOffer"><div className="inner-packages">< div className="package">
                      <div className="bottom-row">
                        <div className="">
                          Have a request not offered above?

                        </div>
                        <button className="add-to-cart" onClick={() => addCustomOffer()}>Add a Custom Offer</button>
                      </div></div>
                    </div ></CustomTabPanel>


                  </Box>
                </div>
              </div>}
            {(isLoadedInfluencer && influencerFound == false) &&
              <div className="profile-container">
                <div className="description">
                  <h4> No Influencer found.</h4>

                </div>
              </div>
            }
            <AnimatePresence>
              {modal === "showallphotos" && (
                <ProfileAllPhotosModal close={() => setModal(null)} allInfluencerImage={allInfluencerImage} />
              )}
              {modal === "addOffer" && <Modal addClass="modalAsset" close={() => setModal(null)}>
                <div className="modal__asset">
                  <div className="modal__asset-inner">


                    <AddOffer user={user} instModul={instModul} fcbkModul={fcbkModul} tiktokModul={tiktokModul} youtubeModul={youtubeModul} setModal={setModal} setToastList={setToastList} influencerProfile={influencerProfile} toastList={toastList} cartItem={cartItem} setCartItem={setCartItem} setShowCart={setShowCart} />

                  </div>
                </div>
              </Modal>
              }
              {modal === "addtoList" && (
                <ModalAddInfToList setToastList={setToastList} toastList={toastList} close={() => setModal(null)} setModal={setModal} selectedInfluencer={selectedInfluencer} handleSearch={handleSearch} />
              )}
              {modal === "addList" && (
                <Modal addClass="modalCamp" close={() => setModal(null)}>
                  <div className="filterModal modal__camp">
                    <AddListandInfluencerToList setToastList={setToastList} toastList={toastList} setTabModal={setModal} selectedInfluencer={selectedInfluencer} />
                  </div>
                </Modal>

              )}
              {modal === "howitworks" && (
                <ModalHowItWorks close={() => setModal(null)} />
              )}
              {modal === "confirmDelete" && (
                <ModalConfirmDeleteInfluencer removeFromList={removeFromList} setToastList={setToastList} toastList={toastList} close={() => setModal(null)} setModal={setModal} selectedInfluencer={selectedInfluencer} />
              )}
              ;
            </AnimatePresence>
          </div></div >
      }
      {isUserHasSubscription === false && user !== null && (
        <>
          <NotSubscribe
            goToPricing={goToPricing}
            type='brand'
            setAuth={setAuth}
            setModal={setModal}
            NotifModul={NotifModul}
            setNotifModul={setModalNotification}
            profiles={profiles}
            closeCartPanel={closeCartPanel}
            user={user}
            cartItem={cartItem}
            title={"Influencer Profile"}
            background={"background_profile.png"}
          >
          </NotSubscribe>
        </>
      )
      }
    </>

  );
}

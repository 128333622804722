import { fetchWrapper } from '../Helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}/orders`;

export const orderService = {
    getAll,
    getOrderById,
    createOrder
};
function getAllPaging(options, filterString) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[number]=${options.page}`;
    if (options.pageSize) {
        filterString += filterString.length > 0 ? `&` : '';
        filterString += `page[size]=${options.pageSize}`;
    }
    return filterString;
}
function createOrder(params) {
    return fetchWrapper.put(`${baseUrl}/createOrder`, params);
}

function getOrderById(options) {
    const id = options.id;
    let filterString = '';
    //filterString = " options.id;
    if (options.filter) {
        filterString = options.filter;
    }
    if (options.page !== undefined) {
        filterString = getAllPaging(options, filterString);
    }
    const url =
        filterString.length > 0 ? `${baseUrl}/${id}?${filterString}` : `${baseUrl}/${id}`;
    return fetchWrapper.get(url);
}
function getAll(options) {
    const id = options.id;
    let filterString = '';
    //filterString = " options.id;
    if (options.filter) {
        filterString = options.filter;
    }
    if (options.page !== undefined) {
        filterString = getAllPaging(options, filterString);
    }
    const url =
        filterString.length > 0 ? `${baseUrl}?${filterString}` : `${baseUrl}`;
    return fetchWrapper.get(url);
}




import React, { useState } from 'react';
import { Modal } from '../../Components';

export default function ModalReportConfirm(props) {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const [textToShow, setTextToShow] = useState(props.confirmFor == 'archived' ? 'Archive' : 'Restore');
    return (
        <Modal addClass='modalRateConnect' close={props.close}>
            <div className='modal__rate'>
                <h6 className='mb'>
                    Are you sure you want to {textToShow} report?
                </h6>
                <div className='modal__rate-btns'>
                    <button className='button secondary' onClick={props.close}>
                        Cancel
                    </button>
                    <button
                        className='button primary'
                        onClick={() => props.onConfirm()}
                    >
                        {capitalizeFirstLetter(textToShow)}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

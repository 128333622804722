import { fetchWrapper } from '../Helpers';

export const searchProfileService = {


    searchInfluencerProfile,
    getSocialAccounts,
    getBalance,

};


function searchInfluencerProfile(profileid, usertype) {

    // if (usertype == 'registered') {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/influencerSearchProfile/searchprofile/` + profileid + "/" + usertype);
    // } 

    //else {
    //  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/influencerSearchProfile/searchprofilenon/` + profileid);
    //}


}
function getSocialAccounts(id, usertype) {

    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/influencerSearchProfile/influencer-product/` + id + "/" + usertype);


}
function getBalance() {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/balance`);
}



import React from "react";

export default function OfferItem(props) {
  function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }
  return (
    <tr >
      <td>
        <p>{props.username}</p>
      </td>

      <td>
        <p>{props.product}</p>
      </td>

      <td>${props.price}</td>
      <td>{capitalizeFirstLetter(props.status)}</td>
    </tr>
  );
}

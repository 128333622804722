
import React, { useEffect, useRef, useState } from "react";

export default function OrderCompleteDetails({ orderCreated, order, offer, profiles

}) {
    const [projected, setProjected] = useState(0);
    const [subTotalVal, setSubTotalVal] = useState(0);
    console.log(offer)
    useEffect(() => {
        let totalPrj = 0;
        let subtotal = 0;

        offer.map(itemOffer => {
            subtotal = Number(subtotal) + Number(itemOffer.price)
        })
        setSubTotalVal(subtotal)
        setProjected(subtotal / 2);
    }, [])
    const getSubstring = (text) => {

        if (text.includes("- Followerscount")) {
            const startIndex = text.indexOf("- Followerscount");
            const last = "- Followerscount".length + 2;
            const remainingText = text.substring(startIndex + last);

            console.log(remainingText); // Output: ": and is very popular."
            return " (" + remainingText + " Followers)";
        } else {
            const remainingText = "";
        }


    }
    const getSubstring1 = (text) => {
        const str = text;
        const result = str.split("-");

        //console.log(remainingText); // Output: ": and is very popular."
        return result[0];
    }
    function capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }
    return (

        <>
            <div className="order-details">
                <div className="order-card">

                    <div class="order-left detailsCart">
                        <p><strong>Ordered On:</strong> {order.created_at}</p>

                        <p><strong>Order #:</strong> {order.ref_id}</p>
                        {/*<p><strong>Status:</strong> {capitalizeFirstLetter(order.status)}</p>*/}
                    </div>

                    <div className="order-body detailspage">
                        {/*<div className="order-left detailsCart">


                            <p><strong>Amount:</strong> ${order.amount}</p>

                        </div>*/}
                        <div className="orderDetailsBox">
                            <div className="search-checkout-cart-number-holder">
                                <div className="cart-price-row amountDetails">
                                    <div>Subtotal</div>
                                    <div className="subtotal">${subTotalVal.toFixed(2)}</div>
                                </div>
                                { /*availableBalance > 0 &&
                                                        <div className="cart-price-row">
                                                            <div>From Available Balance</div>
                                                            <div className="subtotal">${100}</div>
                                                        </div>*/}
                                <div className="cart-price-row amountDetails">
                                    <div>Grand Total</div>
                                    <div className="subtotal">${order.amount.toFixed(2)}</div>
                                </div>
                            </div>
                            <div className="search-checkout-estimate-holder search-checkout-width-holder amountDetails">

                                <div className="search-checkout-estimate-txt search-checkout-estimate-projected">On average, <b class="acceptance-rate">
                                    50% of offers are accepted</b>. Therefore, your projected spend is <span class="projected-total">${projected}</span>.
                                    Your total spend can still be reached if all offers are accepted.
                                </div>
                                {offer.length >= 2 &&
                                    <div className="cart-price-row total-cart-price-row search-checkout-estimate-projected">
                                        <div>Projected Spend</div>
                                        <div className="projected-total">${projected.toFixed(2)}</div>
                                    </div>}
                            </div>
                        </div>
                        <div className="order-right orderDetailsBox">
                            <div >
                                <div className="sidebar-cart orderDetailsCart " >

                                    {offer.length > 0 ?
                                        <>
                                            <div className="search-checkout-added" >
                                                <div className="search-checkout-cart-items js-cart-items">
                                                    {offer.length > 0 &&
                                                        offer.map((cartItemLoop, index) => {
                                                            return <div className="search-checkout-item-holder">
                                                                <div className="search-checkout-item">

                                                                    <div className="search-checkout-item-info-holder" >
                                                                        <div className="search-checkout-item-title-holder">
                                                                            <div className="search-checkout-item-title">



                                                                                {cartItemLoop.offer_type == "custom" ? getSubstring1(cartItemLoop.description) : cartItemLoop.name}
                                                                            </div>
                                                                            <div className="bothStatusandPrice">
                                                                                <div className="search-checkout-item-price">{!orderCreated ? capitalizeFirstLetter(cartItemLoop.status) + " " : ''}</div>
                                                                                <div className="search-checkout-item-price">${cartItemLoop.price}</div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="search-checkout-item-name">{cartItemLoop.firstName} {" "}{cartItemLoop.lastName} {getSubstring(cartItemLoop.description)}</div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        })
                                                    }
                                                </div>


                                            </div>

                                        </> : <>
                                        </>
                                    }

                                </div>

                            </div >
                        </div>
                    </div>

                </div>
            </div>
        </ >
    );
};


import React, { useState, useEffect } from "react";

import { Profile } from "../../Components";

import { arrowLeft } from "../../Components";
import {
  HeaderAccOuterPage,
  Toast,
  EmailInput,
  Pagination
} from '../../Components';
import SearchInfMain from './SearchInfMain';
import { utils } from "../../Helpers/utils";
import { useSearchParams, useNavigate } from 'react-router-dom';
import {

  productService,
  searchInfluencerService,
  accountService
} from '../../Services';
import NotSubscribe from "../../Components/Base/Common/NotSubscribe";
const age = [
  { id: "13-17", value: "13-17" },
  { id: "18-24", value: "18-24" },
  { id: "25-34", value: "25-34" },
  { id: "35-44", value: "35-44" },
  { id: "45-54", value: "45-54" },
  { id: "55-64", value: "55-64" },
  { id: "65+", value: "65+" },
];

export default function SearchInf({ setModalNotification, goToPricing, user, cartItem, closeCartPanel, setAuth, setModal, NotifModul, account, profiles, setSubsProductId }) {

  const [toastList, setToastList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [platform, setPlatform] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedInfluencer, setIsLoadedInfluencer] = useState(false);
  const [paginationData, setPaginationData] = useState({});

  const [productList, setProductList] = useState([]);
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedAge, setSelectedAge] = useState('');
  const [selectedFollowersCount, setSelectedFollowersCount] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedEthenicity, setSelectedEthenicity] = useState('');
  const [categoriesData, setCategoriesData] = useState({});
  const [isUserHasAccessListing, setIsUserHasAccessListing] = useState(true);
  const [countries, setCountries] = useState([]);
  //const [isUserHasSubscription, setUserHasSubscription] = useState(false);
  const [isUserHasSubscription, setUserHasSubscription] = useState(user !== null ? user.data.isUserHasSubscription == 1 ? true : false : false);
  const [selectedLocationData, setSelectedLocationData] = useState({ 'country': [], 'region': [], 'city': [] })
  const [startSearch, setStartSearch] = useState(false);
  const [influncerList, setInfluncerList] = useState([]);
  const [page, setPage] = useState(1);
  const [limitedToSomePage, setLimitedToSomePage] = useState(false);
  // const [maxpageToShow, setMaxpageToShow] = useState(process.env.REACT_APP_NUMBEROFPAGE);
  const [maxpageToShow, setMaxpageToShow] = useState(3);
  const [pageSize, setPageSize] = useState(12);
  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  //console.log("==" + maxpageToShow)
  useEffect(() => {
    async function fetchCategoriesData() {
      try {
        const res = await searchInfluencerService.getCategoriesDetails();
        let newCountry = [];
        //console.log(res)
        for (let [val, key] of Object.entries(res.countries)) {

          (newCountry.push({ 'label': key, 'value': val }))
        }
        let platformDetails = res.socialMedia;
        platformDetails.unshift({ 'id': 'Any', 'name': 'Any' })
        setPlatform(platformDetails)
        setCategoriesData(res.categories);

        setCountries(newCountry)
        setIsLoaded(true)
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchCategoriesData();
  }, []);


  const onPaginate = page => {
    console.log(page)

    if (profiles !== undefined) {
      if (isUserHasSubscription) {
        setPage(page);
      } else {
        if (page > maxpageToShow) {
          setLimitedToSomePage(true)
        } else {
          setPage(page);
        }
      }

    } else {
      //debugger
      if (page <= maxpageToShow) {
        setPage(page);
      } else {
        navigate("/account/pricing")
      }

    }
  };
  async function refreshUSer() {
    await accountService.refreshToken(function () { });
  }
  useEffect(() => {
    setUserHasSubscription(user !== null ? user.data.isUserHasSubscription == 1 ? true : false : false)
  }, [user]);
  useEffect(() => {
    let paypalError = searchParams.get('error') !== null ? searchParams.get('error') == 1 ? "We're having trouble connecting to your payment account. Please try again ." : '' : '';
    let successMessage = searchParams.get('success') !== null ? searchParams.get('success') == 1 ? "Your selected plan has been successfully subscribed." : '' : '';

    if (successMessage !== '') {
      refreshUSer();
      addToast({
        error: false,
        msg: successMessage,
        id: `${Date.now()}-${toastList.length}`
      });
    }
    if (paypalError !== '') {
      addToast({
        error: true,
        msg: paypalError,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }, []);
  useEffect(() => {
    async function searchInfluencer() {
      try {

        let optionCat = [];
        //  console.log(selectedCategory)
        if (selectedCategory.length > 0) {
          optionCat = selectedCategory.map(item => item.id)
        }
        //console.log(optionCat)

        let options = {
          'platform': selectedPlatform,
          'category': selectedCategory.length > 0 ? optionCat.toString() : "",
          'product': selectedProduct,
          'followers': selectedFollowersCount,
          'location': selectedLocationData.country,
          'price': selectedPrice,
          'gender': selectedGender,
          'age': selectedAge,
          'ethenicity': selectedEthenicity,
          'language': selectedLanguage,
          'page': page,
          'pageSize': pageSize,
        }

        const res = await searchInfluencerService.searchInfluencer(options);
        //const res = await influencerlistService.getAllInfluencer(16, {});

        setInfluncerList(res.data)

        if (res.meta && res.meta.pagination) {
          //console.log(res.meta.pagination)
          setPaginationData(res.meta.pagination);
        }
        setIsLoadedInfluencer(true)
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    searchInfluencer();
  }, [startSearch, page]);
  //}, [selectedPlatform, selectedCategory, selectedProduct, selectedFollowersCount, selectedLocationData, selectedPrice, selectedGender, selectedAge, selectedEthenicity, selectedLanguage]);


  async function fetchProductData(platform) {
    try {
      const res = await productService.getAllPlatform();
      let prd = [];
      res.data.map((item, index) => {
        if (item.social_media_id === (platform) && platform !== "Any") {
          prd.push(item);
        }
        if (prd.length > 0 && platform !== "Any") {
          setProductList(prd)
        }
        if (platform == 'Any') {
          setProductList([])
        }
      })
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const changeSelectedPlatform = (item) => {
    //console.log(item)
    setSelectedProduct([])
    setSelectedPlatform(item.id);
    fetchProductData(item.id)
  }


  const clearAll = () => {

    setSelectedProduct([]);
    setSelectedAge('');
    setSelectedFollowersCount([])
    setSelectedPrice([]);
    setSelectedLanguage("");
    setSelectedGender("");
    setSelectedEthenicity("")
    setStartSearch(!startSearch)
    // setProductList([])
  }
  //console.log(influncerList)
  return (
    <>
      {<>
        {profiles !== undefined ?
          <div className="main">
            <div className={"main__inner" + (profiles === undefined ? ' nopadding' : '')}>
              <div className="main__inner-title">
                <h2> Influencers</h2>
                <Profile
                  account={account}
                  setAuth={setAuth}
                  setModal={setModal}
                  user={user}
                  NotifModul={NotifModul}
                  profiles={profiles}
                  closeCartPanel={closeCartPanel}
                  type={'brand'}
                  cartItem={cartItem}
                />
              </div>
              {isLoaded && isLoadedInfluencer &&
                <SearchInfMain selectedPlatform={selectedPlatform}
                  selectedProduct={selectedProduct}
                  setSelectedAge={setSelectedAge}
                  setSelectedFollowersCount={setSelectedFollowersCount}
                  setSelectedPrice={setSelectedPrice}
                  setSelectedProduct={setSelectedProduct}
                  productList={productList}
                  changeSelectedPlatform={changeSelectedPlatform}
                  platform={platform}
                  selectedAge={selectedAge}
                  setSelectedEthenicity={setSelectedEthenicity}
                  setSelectedLanguage={setSelectedLanguage}
                  categoriesData={categoriesData}
                  clearAll={clearAll}
                  selectedGender={selectedGender}
                  setSelectedGender={setSelectedGender}
                  isUserHasSubscription={isUserHasSubscription}
                  selectedLanguage={selectedLanguage}
                  selectedEthenicity={selectedEthenicity}
                  countries={countries}
                  setSelectedLocationData={setSelectedLocationData}
                  selectedLocationData={selectedLocationData}
                  setSelectedCategory={setSelectedCategory}
                  setStartSearch={setStartSearch}
                  startSearch={startSearch}
                  profiles={profiles}
                  influncerList={influncerList}
                  selectedFollowersCount={selectedFollowersCount}
                  selectedPrice={selectedPrice}
                  paginationData={paginationData}
                  onPaginate={onPaginate}
                  setIsUserHasAccessListing={setIsUserHasAccessListing}
                />
              }

            </div>
          </div >

          :
          isLoaded && isLoadedInfluencer &&
          <div className='searchouterpage'>
            <div className='account log' >
              <div className='account__inner'>
                <HeaderAccOuterPage />
                <div >
                  <div className='account__inner-col-image'>
                    <div class="showSearchFilterAndResult">
                      <SearchInfMain selectedPlatform={selectedPlatform}
                        selectedProduct={selectedProduct}
                        setSelectedAge={setSelectedAge}
                        setSelectedFollowersCount={setSelectedFollowersCount}
                        setSelectedPrice={setSelectedPrice}
                        setSelectedProduct={setSelectedProduct}
                        productList={productList}
                        changeSelectedPlatform={changeSelectedPlatform}
                        platform={platform}
                        selectedAge={selectedAge}
                        setSelectedEthenicity={setSelectedEthenicity}
                        setSelectedLanguage={setSelectedLanguage}
                        categoriesData={categoriesData}
                        clearAll={clearAll}
                        selectedGender={selectedGender}
                        setSelectedGender={setSelectedGender}
                        isUserHasSubscription={isUserHasSubscription}
                        selectedLanguage={selectedLanguage}
                        selectedEthenicity={selectedEthenicity}
                        countries={countries}
                        setSelectedLocationData={setSelectedLocationData}
                        selectedLocationData={selectedLocationData}
                        setSelectedCategory={setSelectedCategory}
                        setStartSearch={setStartSearch}
                        startSearch={startSearch}
                        profiles={profiles}
                        influncerList={influncerList}
                        selectedFollowersCount={selectedFollowersCount}
                        selectedPrice={selectedPrice}
                        paginationData={paginationData}
                        onPaginate={onPaginate}
                      />

                    </div>
                  </div>

                </div>
              </div>
            </div></div>



        }

        {toastList && <Toast list={toastList} setList={setToastList} />}
      </>
      }
      {((isUserHasSubscription === false && limitedToSomePage === true) || (isUserHasSubscription === false && isUserHasAccessListing == false)) && (
        <>
          <NotSubscribe
            goToPricing={goToPricing}
            type='brand'
            setAuth={setAuth}
            setModal={setModal}
            NotifModul={NotifModul}
            setNotifModul={setModalNotification}
            profiles={profiles}
            closeCartPanel={closeCartPanel}
            user={user}
            cartItem={cartItem}
            title={"Influencers"}
          >
          </NotSubscribe>
        </>
      )
      }
    </>

  );
}
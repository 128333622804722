import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Profile } from "../../Components";
import { arrowLeft, Toast } from "../../Components";
import AssetsAdd from "./AssetsAdd";
import AssetsDeleted from "./AssetsDeleted";
import AssetsEdit from "./AssetsEdit";
import AssetsMain from "./AssetsMain";
import ModalAssetDetail from "./ModalAssetDetail";
import ModalAssetFull from "./ModalAssetFull";
import ModalAssetPermanent from "./ModalAssetPermanent";
import { contentService } from '../../Services';
export default function Assets({ cartItem, closeCartPanel, setAuth, setModal, user, NotifModul, profiles, setModalNotification, locationData }) {
  const [tab, setTab] = useState("main");
  // eslint-disable-next-line
  const [modalAsset, setModalAsset] = useState(null);
  const [modalDataAsset, setModalDataAsset] = useState(null);
  const [editTabData, setEditTabData] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [toastList, setToastList] = useState([]);
  const [assestsList, setAssestsList] = useState({ "campaigns": [] });
  const [rowCount, setRowCount] = useState([]);
  const [selectDesc, setselectDesc] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState(false);
  useEffect(() => {
    if (modalAsset) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modalAsset]);
  useEffect(() => {
    setTab("main");
    setPage(1);
    setSearchText("");
    setselectDesc("")
  }, [locationData.key]);
  useEffect(() => {
    async function fetchData() {
      const filterBy = tab !== 'deleted' ? 'active' : 'inactive';
      let filterString = `filter[asset.status]=` + filterBy;
      if (searchText != "" && selectDesc != "") {
        filterString += '&filter[' + selectDesc + ']=' + searchText;
      }
      try {
        const res = await contentService.getAll({
          page: page,
          pageSize,
          filter: filterString
        });
        if (
          res.meta &&
          res.meta.pagination
        ) {
          setPaginationData(res.meta.pagination);
        }
        setRowCount((res.data.length));
        setAssestsList(res.data);
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, [page, tab, rowCount, searchList]);

  const onPaginate = page => {
    setPage(page);
  };
  const changeMediumDesc = (item) => {
    setselectDesc(item.id)
    setPage(1);
    setSearchText("");
    setSearchList(!searchList);
  };
  const setSearchTextOnChange = event => {
    if (event.target.value === '') {
      setSearchList(!searchList);
      setselectDesc("");
    }
    setSearchText(event.target.value);
  };
  const search = () => {
    setSearchList(!searchList);
    setPage(1);
  };

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };

  const deleteAssetPermanently = async () => {
    try {
      if (modalDataAsset.id) {
        const deleteID = modalDataAsset.id
        const res = await contentService.permanentdelete(deleteID);
        if (res.status === 'failed') {
          addToast({
            error: true,
            msg: res.message,
            id: `${Date.now()}-${toastList.length}`
          });
        }
        if (res.status !== 'failed') {
          addToast({
            error: false,
            msg: res.message,
            id: `${Date.now()}-${toastList.length}`
          });
        }
        setRowCount(rowCount - 1);
        setModalAsset(null);
      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  };
  const recoverAsset = async () => {
    try {
      if (modalDataAsset.id) {
        const deleteID = modalDataAsset.id
        const res = await contentService.delete(deleteID, {
          status: 'active'
        });

        if (res.status === 'failed') {
          addToast({
            error: true,
            msg: res.message,
            id: `${Date.now()}-${toastList.length}`
          });
        }
        if (res.status !== 'failed') {
          addToast({
            error: false,
            msg: res.message,
            id: `${Date.now()}-${toastList.length}`
          });
        }
        setRowCount(rowCount - 1);
        setModalAsset(null);
      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  };
  const deleteAsset = async () => {
    try {
      if (modalDataAsset.id) {
        const deleteID = modalDataAsset.id
        const res = await contentService.delete(deleteID, {
          status: 'inactive'
        });

        if (res.status === 'failed') {
          addToast({
            error: true,
            msg: res.message,
            id: `${Date.now()}-${toastList.length}`
          });
        }
        if (res.status !== 'failed') {
          addToast({
            error: false,
            msg: res.message,
            id: `${Date.now()}-${toastList.length}`
          });
        }
        setRowCount(rowCount - 1);
        setModalAsset(null);
      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  };
  return (
    <>
      <div className="main">
        <div className="main__inner">
          <div className="main__inner-title">
            {tab === "main" ? (
              <h2>Assets</h2>
            ) : (
              <div className="main__inner-title-col">
                {tab === "deleted" ? <h2>Deleted assets</h2> : <h2>Assets</h2>}
                <button
                  className="button tertiary big"
                  onClick={() => { setTab("main"); onPaginate(1); setAssestsList([]) }}
                >
                  {arrowLeft} Back to asset list
                </button>
              </div>
            )}
            <Profile
              setAuth={setAuth}
              setModal={setModal}
              NotifModul={NotifModul}
              setNotifModul={setModalNotification}
              profiles={profiles}
              user={user}
              closeCartPanel={closeCartPanel}
              type='brand'
              cartItem={cartItem}
            />
          </div>

          {tab === "main" && (
            <AssetsMain
              setTab={setTab}
              assestsList={assestsList}
              setModalAsset={setModalAsset}
              setModalDataAsset={setModalDataAsset}
              paginationData={paginationData}
              onPaginate={onPaginate}
              isLoaded={isLoaded}
              campaignsUsing={assestsList.campaigns}
              setAssestsList={setAssestsList}
              changeMediumDesc={changeMediumDesc}
              setSearchTextOnChange={setSearchTextOnChange}
              search={search}
              searchText={searchText}
              selectDesc={selectDesc}
            />
          )}
          {tab === "deleted" && (
            <AssetsDeleted
              setModalAsset={setModalAsset}
              setModalDataAsset={setModalDataAsset}
              assestsList={assestsList}
              paginationData={paginationData}
              onPaginate={onPaginate}
              isLoaded={isLoaded}
              campaignsUsing={assestsList.campaigns}
            />
          )}
          {tab === "add" && <AssetsAdd setTab={setTab} setToastList={setToastList} toastList={toastList} />}
          {tab === "edit" && <AssetsEdit  {...editTabData} setTab={setTab} setToastList={setToastList} toastList={toastList} />}

        </div>
      </div>
      <AnimatePresence>
        {modalAsset === "detail" && (
          <ModalAssetDetail
            close={() => setModalAsset(null)}
            deleteAsset={deleteAsset}
            {...modalDataAsset}
            mediaClick={() => setModalAsset("full")}
            editBtnClick={() => {
              setEditTabData(modalDataAsset);
              setTab("edit");
              setModalAsset(null);
            }}
            campaignsUsing={modalDataAsset.campaigns}
          />
        )}
        {modalAsset === "permanent" && (
          <ModalAssetPermanent
            close={() => setModalAsset(null)}
            {...modalDataAsset}
            mediaClick={() => setModalAsset("fullPermanent")}
            recoverAsset={recoverAsset}
            deleteAssetPermanently={deleteAssetPermanently}
          />
        )}
        {(modalAsset === "full" || modalAsset === "fullPermanent") && (
          <ModalAssetFull
            backClick={() => {
              if (modalAsset === "full") setModalAsset("detail");
              else if (modalAsset === "fullPermanent")
                setModalAsset("permanent");
            }}
            modalAsset={modalAsset}
            close={() => setModalAsset(null)}
            {...modalDataAsset}
            setModalAsset={setModalAsset}
          />
        )}
      </AnimatePresence>
      {toastList && <Toast list={toastList} setList={setToastList} />}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Modal } from "../../../../Components";
import {
  commentsIcon,
  graphIcon,
  heartIcon,
  imageIcon,
  searchIcon,
  shareSecondIcon,
  commentSecondIcon,
  engagementIcon
} from "../../../../Components";
import { YearToDate } from "../../../../Components";

export default function ModalInfluencerRate(props) {
  //console.log(props);
  const [select, setSelect] = useState();
  const [facebookProducts, setFacebookProducts] = useState([]);
  const [instaProducts, setInstaProducts] = useState([]);
  const [tikTokProducts, setTikTokProducts] = useState([]);
  const [youtubeProducts, setYoutubeProducts] = useState([]);

  const [goods, setGoods] = useState([]);
  const [influencerInsignt, setInfluencerInsignt] = useState({});
  const [influencerRes, setInfluencerRes] = useState({});
  const [toastList, setToastList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [insignhRange, setInsignhRange] = useState(0);
  const ytdList = [
    { id: 0, value: "Past 30 days" },
    { id: 1, value: "Past 3 months" },

  ];
  const changeMedium = (item) => {

    setInsignhRange(item.id);
  };
  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    try {
      let res = props.ModalData;
      setInfluencerRes(props.ModalData);
      let fb = [];
      let insta = [];
      let tikTok = [];
      let youtube = [];
      let productArray = (res.product[props.ModalData.id]);
      //console.log(res.product[props.ModalData.id]['influencer_stats'])
      setInfluencerInsignt(res.product[props.ModalData.id]['influencer_stats']);
      Object.keys(productArray).forEach(function (key) {
        let item = productArray[key];
        if (item.name) {
          if (item.name.startsWith("Facebook")) {
            fb.push(item);
          } else if (item.name.startsWith("Insta")) {
            insta.push(item);
          } else if (item.name.startsWith("Tik")) {
            tikTok.push(item);
          } else if (item.parent = 17) {
            youtube.push(item);
          }
        }

      });
      setFacebookProducts(fb);
      setInstaProducts(insta);
      setTikTokProducts(tikTok);
      setYoutubeProducts(youtube);
      //console.log(insta)
      setIsLoaded(true);
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }

  }, []);
  return (
    <Modal addClass="modalFunds" close={props.close}>
      {isLoaded &&
        <div className="modal__url">
          <div className="campaignsInfluItem customUrlInner">
            <div className="campaignsInfluItem__head">
              <div className="campaignsInfluItem__head-image">
                <img
                  src={
                    influencerRes.social_media_profile_img && influencerRes.social_media_profile_img !== ''
                      ? influencerRes.social_media_profile_img : `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = `${process.env.PUBLIC_URL}/images/avatar.png`;
                  }}
                  alt="avatar" />
              </div>
              <div className="campaignsInfluItem__head-content">
                <h4>{props.ModalData.handle + ' [' + props.ModalData.id + ']'}</h4>
                <div className="campaignsInfluItem__head-row">
                  <p className="sm">
                    <strong>{influencerRes ? influencerRes.media_count ? influencerRes.media_count : 0 : 0}</strong>
                    Posts
                  </p>
                  <p className="sm">
                    <strong>{influencerRes ? influencerRes.followers ? influencerRes.followers : 0 : 0}</strong>
                    Followers
                  </p>
                  <p className="sm">
                    <strong>{influencerRes ? influencerRes.follows_count ? influencerRes.follows_count : 0 : 0}</strong>
                    Following
                  </p>
                </div>
              </div>
            </div>


            <div className="campaignsInfluItem__insight">
              <div className="campaignsInfluItem__insight-title">
                <h3>Insights</h3>
                <YearToDate
                  list={ytdList}
                  selected={ytdList[0]}
                  onChange={changeMedium}
                />
              </div>
              <div className="campaignsInfluItem__insight-items">
                <div className="campaignsInfluItem__insight-item">
                  {imageIcon}
                  <h6>{influencerInsignt[insignhRange] ? influencerInsignt[insignhRange]['no_post'] ? influencerInsignt[insignhRange]['no_post'] : 0 : 0} Posts</h6>
                </div>
                <div className="campaignsInfluItem__insight-item showAvg">
                  {heartIcon}
                  <h6>Total{' '}{influencerInsignt[insignhRange] ? influencerInsignt[insignhRange]['no_like'] ? influencerInsignt[insignhRange]['no_like'] : 0 : 0}</h6>
                  <h6>Avg {influencerInsignt[insignhRange] ? influencerInsignt[insignhRange]['avg_like'] ? influencerInsignt[insignhRange]['avg_like'] : 0 : 0} </h6>
                </div>

                <div className="campaignsInfluItem__insight-item showAvg">

                  {commentSecondIcon}
                  <h6>Total{' '}{influencerInsignt[insignhRange] ? influencerInsignt[insignhRange]['no_comment'] ? influencerInsignt[insignhRange]['no_comment'] : 0 : 0}</h6>
                  <h6>Avg {influencerInsignt[insignhRange] ? influencerInsignt[insignhRange]['avg_comment'] ? influencerInsignt[insignhRange]['avg_comment'] : 0 : 0}
                  </h6>
                </div>
                <div className="campaignsInfluItem__insight-item showAvg">
                  {shareSecondIcon}
                  <h6>Total{' '}{influencerInsignt[insignhRange] ? influencerInsignt[insignhRange]['no_share'] ? influencerInsignt[insignhRange]['no_share'] : 0 : 0}</h6>
                  <h6>Avg {influencerInsignt[insignhRange] ? influencerInsignt[insignhRange]['avg_share'] ? influencerInsignt[insignhRange]['avg_share'] : 0 : 0}
                  </h6>
                </div>
                <div className="campaignsInfluItem__insight-item showAvg">
                  {engagementIcon}
                  <h6>Total{' '}{influencerInsignt[insignhRange] ? influencerInsignt[insignhRange]['no_eng'] ? influencerInsignt[insignhRange]['no_eng'] : 0 : 0}</h6>
                  <h6>Avg {influencerInsignt[insignhRange] ? influencerInsignt[insignhRange]['avg_engagement'] ? influencerInsignt[insignhRange]['avg_engagement'] : 0 : 0}</h6>
                </div>

                <div className="campaignsInfluItem__insight-item">
                  {engagementIcon}
                  <h6>{influencerInsignt[insignhRange] ? influencerInsignt[insignhRange]['perc_engagement'] ? influencerInsignt[insignhRange]['perc_engagement'] : 0 : 0} %
                  </h6>
                </div>
              </div>
            </div>
            {facebookProducts.length > 0 && (
              <div className="campaignsInfluItem__product">
                <h3>Facebook Rates</h3>
                <div className="campaignsInfluItem__product-items">
                  {facebookProducts.map((item, index) => {
                    if (item.name.startsWith("Facebook")) {
                      return <ProductItem {...item} key={index} setCompensationData={item} />;
                    }
                  })}
                </div>
              </div>
            )}

            {instaProducts.length > 0 && (
              <div className="campaignsInfluItem__product">
                <h3>Instagram Rates</h3>
                <div className="campaignsInfluItem__product-items">
                  {instaProducts.map((item, index) => {
                    if (item.name.startsWith("Insta")) {
                      return <ProductItem {...item} key={index} setCompensationData={item} />;
                    }
                  })}
                </div>
              </div>
            )}

            {tikTokProducts.length > 0 && (
              <div className="campaignsInfluItem__product">
                <h3>TikTok Rates</h3>
                <div className="campaignsInfluItem__product-items">
                  {tikTokProducts.map((item, index) => {
                    if (item.name.startsWith("Tik")) {
                      return <ProductItem {...item} key={index} setCompensationData={item} />;
                    }
                  })}
                </div>
              </div>
            )}

            {youtubeProducts.length > 0 && (
              <div className="campaignsInfluItem__product">
                <h3>Youtube Rates</h3>
                <div className="campaignsInfluItem__product-items">
                  {youtubeProducts.map((item, index) => {
                    if (item.parent === 17) {
                      return <ProductItem {...item} key={index} setCompensationData={item} />;
                    }
                  })}
                </div>
              </div>
            )}
          </div>


        </div>
      }
    </Modal>


  );
}

const ProductItem = (props) => {
  return (
    <div className="campaignsInfluItem__product-item modalProduct">
      <h6>{props.name}</h6>
      <p>${props.rate}</p>
    </div>
  );
};

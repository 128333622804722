import { fetchWrapper } from '../Helpers';

export const searchInfluencerService = {
    getCategoriesDetails,
    searchInfluencer,


};

function getCategoriesDetails() {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/getCategories`);
}

function searchInfluencer(option) {
    console.log(option)
    let filterString = "";
    if (option.platform && option.platform !== "" && option.platform !== "Any") {
        filterString = "platform=" + option.platform
    }

    if (option.category && option.category !== "") {

        // filterString += filterString === "" ? "category=" + option.category : "&category=" + option.category;
        //filterString += filterString.length > 0 ? `&` : '';
        //filterString += `filter[category]=${option.category}`;
    }
    if (option.product && option.product.length > 0) {

        filterString += filterString === "" ? "product=" + option.category.toString() : "&product=" + option.product.toString();
    }
    if (option.followers && option.followers.length > 0) {

        filterString += filterString === "" ? "followers=" + option.followers.toString() : "&followers=" + option.followers.toString();
    }
    if (option.location && option.location.length > 0) {

        filterString += filterString === "" ? "location=" + option.location.toString() : "&location=" + option.location.toString();
    }
    if (option.price && option.price.length > 0) {

        filterString += filterString === "" ? "price=" + option.price.toString() : "&price=" + option.price.toString();
    }
    if (option.gender && option.gender !== "") {

        filterString += filterString === "" ? "gender=" + option.gender : "&gender=" + option.gender;
    }
    if (option.age && option.age !== "") {

        filterString += filterString === "" ? "age=" + option.gender : "&age=" + option.age;
    }
    if (option.ethenicity && option.ethenicity !== "") {

        filterString += filterString === "" ? "ethenicity=" + option.ethenicity : "&ethenicity=" + option.ethenicity;
    }
    if (option.language && option.language !== "") {

        filterString += filterString === "" ? "language=" + option.language : "&language=" + option.language;
    }

    console.log(filterString)
    if (option.category) {
        let categorySearch = option.category;
        filterString += filterString.length > 0 ? `&` : '';
        filterString += `filter[category]=${categorySearch}`;
    }
    if (option.pageSize) {
        filterString += filterString.length > 0 ? `&` : '';
        filterString += `page[size]=${option.pageSize}`;
    }
    if (option.page) {
        filterString += filterString.length > 0 ? `&` : '';
        filterString += `page[number]=${option.page}`;
    }
    //console.log(filterString)
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/influencerlist/SearchAllInf/?${filterString}`);
}
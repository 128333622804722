const AUTH_TOKEN_KEY = 'AUTH_TOKEN_KEY';
const USER_ID = 'USER_ID';
const ROLE = 'ROLE';
const EMAIL = 'EMAIL';
const IMPERSONATION_DATA = 'IMPERSONATION_DATA';
const CARTITEM = 'CARTITEM';
const USERTYPEFORPROFILE = 'USERTYPEFORPROFILE';
const setToken = token => {
  localStorage.setItem(AUTH_TOKEN_KEY, token);
};
const setUserID = userId => {
  localStorage.setItem(USER_ID, userId);
};

const setImpersonation = impersonationData => {
  localStorage.setItem(IMPERSONATION_DATA, impersonationData);
};

const setUserRole = role => {
  localStorage.setItem(ROLE, role);
};

const setEmail = email => {
  localStorage.setItem(EMAIL, email);
};

const getToken = () => localStorage.getItem(AUTH_TOKEN_KEY);

const getUserId = () => localStorage.getItem(USER_ID);

const getUserRole = () => localStorage.getItem(ROLE);

const getEmail = () => localStorage.getItem(EMAIL);

const getImpersonation = () => localStorage.getItem(IMPERSONATION_DATA);

const deleteToken = () => localStorage.removeItem(AUTH_TOKEN_KEY);

const deleteImpersonationDate = () => localStorage.removeItem(IMPERSONATION_DATA);
const setCartItem = cartItem => localStorage.setItem("CARTITEM", cartItem);

const getCartItem = () => localStorage.getItem(CARTITEM);

const deleteCartItem = () => localStorage.removeItem(CARTITEM);



const setUserTypeForProfileItem = userTypeForProfile => localStorage.setItem("USERTYPEFORPROFILE", userTypeForProfile);

const getUserTypeForProfileItem = () => localStorage.getItem(USERTYPEFORPROFILE);

const deleteUserTypeForProfileItem = () => localStorage.removeItem(USERTYPEFORPROFILE);

const clearStorage = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(EMAIL);
  localStorage.removeItem(ROLE);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(IMPERSONATION_DATA);
};

export const storageHandler = {
  setToken,
  setUserID,
  setUserRole,
  setEmail,
  setImpersonation,
  getToken,
  getUserRole,
  getUserId,
  getEmail,
  deleteToken,
  clearStorage,
  getImpersonation,
  deleteImpersonationDate,
  getCartItem,
  setCartItem,
  deleteCartItem,
  setUserTypeForProfileItem,
  getUserTypeForProfileItem,
  deleteUserTypeForProfileItem
};

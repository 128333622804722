import { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate
} from 'react-router-dom';
import { Account, Influencer, Brand, Admin, SearchInf } from './Pages';
import Activation from './Pages/Account/Activation/Activation';
import { SocialCallback } from './SocialCallback';
import { influencerService, accountService } from './Services';
import { AnimatePresence } from 'framer-motion';
import {
  ModalNotifDetail,
  ModalNotification, ModalNotifDelete, ModalNotifExpired, ModalInConfirmExitCampaign
} from './Components';
import { PrivateRoute, storageHandler, PrivateRouteAuth } from './Helpers';
import { utils } from './Helpers/utils';
function App() {
  const [menu, setMenu] = useState(false);
  const [user, setUser] = useState(null);
  const [changePage, setChangePage] = useState('');
  const [allNotifications, setAllNotifications] = useState([]);
  const [countOfNotification, setCountOfNotification] = useState(0);
  const [toastList, setToastList] = useState([]);
  const [modalData, setModalData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [referPageUrl, setReferPageUrl] = useState();
  const [NotifModul, setNotifModul] = useState([]);
  const [accountCount, setAccountCount] = useState(0);
  const [modalNotification, setModalNotification] = useState();
  const [modalNotificationData, setModalNotificationData] = useState();
  const [locationData, setLocationData] = useState(location);

  const [impersonationBar, setImpersonationBar] = useState("");
  const [timer, setTimer] = useState('02:00:00');

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmAction, setShowConfirmAction] = useState("");
  const [saveCampaign, setSaveCampaign] = useState(false);
  const [navigateLink, setNavigateLink] = useState("");
  const [modalConfirm, setModalConfirm] = useState(null);


  let impersonationInterval;

  //console.log("refer " + document.referrer + "===" + location.href);
  // window.addEventListener('visibilitychange', () => {
  //   const isVisible = document.visibilityState === 'visible';
  //   const isLoginPage = location.pathname === "/account/login";
  //    if (isVisible && isLoginPage) {
  //       window.location.reload();
  //    }
  // });


  const startTimer = () => {
    let impersonationData = storageHandler.getImpersonation();
    let newImpersonationData = JSON.parse(impersonationData);
    if (impersonationData != null && impersonationData != undefined) {
      if (newImpersonationData.duration) {
        let compareDate = new Date(newImpersonationData.duration);
        var currentDate = new Date(); //"now" 
        var diff = (compareDate - currentDate);  // difference in milliseconds
        const seconds = Math.floor((diff / 1000) % 60);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const hours = Math.floor((diff / 1000 / 60 / 60) % 24);
        if (diff >= 0) {
          // update the timer
          // check if less than 10 then we need to
          // add '0' at the beginning of the variable
          setTimer(
            (hours > 1 ? hours : '0' + hours) + ':' +
            (minutes > 9 ? minutes : '0' + minutes) + ':'
            + (seconds > 9 ? seconds : '0' + seconds)
          )
          let impersonationBarData = accountService.getImpersonateData();
          setImpersonationBar(impersonationBarData);
        }
      }
    }
  }
  const handleAnchorClick = event => {
    if ((location.pathname === "/brand/campaigns" || location.pathname === "/admin/campaigns") && showConfirmModal === true && (showConfirmAction === "createcamp" || showConfirmAction === "editcamp" || showConfirmAction === "createeng" || showConfirmAction === "editeng")) {
      event.preventDefault();
      setModalConfirm("confirm");
      setSaveCampaign(false);
      //console.log('Anchor element clicked');
      // 👇️ refers to the link element
      //console.log(event.currentTarget.href.substring(event.currentTarget.href.indexOf("#") + 1));
      setNavigateLink(event.currentTarget.href.substring(event.currentTarget.href.indexOf("#") + 1));
    }
  };
  useEffect(() => {
    let impersonationData = storageHandler.getImpersonation();
    if (impersonationData != null && impersonationData != undefined) {
      setImpersonationInterval();
    } else {
      setImpersonationBar(false);
      clearInterval(impersonationInterval)
    }
  }, [user]);

  const setImpersonationInterval = () => {
    impersonationInterval = setInterval(() => {
      startTimer();
    }, 1000);
  };
  useEffect(() => {
    if (menu) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [menu]);
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
    document.body.classList.remove('active');
  }, [location]);
  const close = e => {
    if (e.target === e.currentTarget) setMenu(false);
  };

  useEffect(() => {
    const subscription = accountService.user.subscribe(x => {
      setUser(x);
    });
    // return subscription.unsubscribe();
  }, []);
  useEffect(() => {
    setLocationData(location);
    setReferPageUrl(location.state !== null ? location : null)
  }, [location.key]);

  async function updateNotificationStatus(id, status) {
    try {
      if (status === 'deleted') {
        let findIndex = allNotifications.findIndex(item => item.id === id);
        const res = await influencerService.updateNotificationstatus(id, { 'status': status });
        setNotifModul(NotifModul.filter(item => item.id !== id));
        if (modalNotification === 'notifDelete') {
          setModalNotification('notifications');
        } else if (modalNotification === 'notifDeleteDash') {
          setModalNotification(null);
        }
        setCountOfNotification(countOfNotification + 1);
      } else {
        if (id === 'all' && status === 'read') {
          const res = await influencerService.updateAllNotification();
          setCountOfNotification(countOfNotification + 1);
        } else {
          const res = await influencerService.updateNotificationstatus(id, { 'status': status });
          setCountOfNotification(countOfNotification + 1);
        }
      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  useEffect(() => {
    if (user) {
      let role = '';
      const roleId =
        user['data']['roles'].length > 0
          ? user['data']['roles'].filter(
            item => item['id'] == user['data']['active_role']
          )[0]['id']
          : '';
      if (roleId === 1) {
        role = 'influencer';
      } else if (roleId === 3) {
        role = 'brand';
      } else if (roleId === 2) {
        role = 'admin';
      } else {
        return;
      }
      setChangePage(role);

    }
  }, [user]);
  useEffect(() => {

    async function fetchNotifications() {
      try {
        const res = await influencerService.getNotifications(user.data.id);
        let allNotifications = [];
        let activeNotification = [];
        if (res.data) {
          activeNotification = res.data.filter(item => item.status === 'active');
        }
        if (res.data) {
          setAccountCount(res.account_count);
          res.data.map(item => {
            let objNotification = {
              id: item.id,
              image: item.type === null ? '/images/notification/1.svg' : 'images/notification/' + item.type + '.svg',
              date: item.created_at,
              title: item.title,
              handle: item.handle,
              text: item.message ? utils.draftJsFormatingForDetails({ 'data': item.message, 'view': 'fullview' }) : '',
              detailTitle: item.title,
              detailText: item.message ? utils.draftJsFormatingForDetails({ 'data': item.message, 'view': 'fullview' }) : '',
              read: item.status === 'read' ? true : false,
              buttonText: item.type ? item.type === 'payment' ? 'View payment' : 'View details' : '',
              handle: item.handle ? item.handle : {},
              activeNotification: activeNotification.length,
              expired: item.expiry_date,
              opportunity_id: item.ref_id,
              notiType: item.type === 'opportunity' || item.type === 'payment' ? 'system' : '',
              sytemNotiType: item.type ? item.type != '' ? item.type : '' : '',
              brandName: item.brand_name ? item.brand_name != '' ? item.brand_name : '' : '',
            }
            allNotifications.push(objNotification);
          })
          setAllNotifications(allNotifications);
        } else {
          setAllNotifications(allNotifications);
        }
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchNotifications();
  }, [user, countOfNotification, window.location.href]);
  // GTAG integration.
  useEffect(() => {
    window.dataLayer.push({
      event: 'virtualPageview',
      pageUrl: `${location.pathname}`,
      pageTitle: `${location.pathname}`
    });
  }, [window.location.href]);

  //FB pixel
  useEffect(() => {
    //console.log("fb page.." + `${location.pathname}`);
    window.fbq('track', `${location.pathname}`);
  }, [window.location.href]);

  const userImpersonateEnd = async () => {
    try {

      const res = await accountService.userImpersonateEnd();
      let impersonationBarData = accountService.getImpersonateData();
      setImpersonationBar(impersonationBarData);
      if (res['data']['active_role'] && res['data']['active_role'] == 1) {
        window.location = '/#/influencer/dashboard';
      }
      if (res['data']['active_role'] && res['data']['active_role'] == 3) {
        window.location = '/#/brand/dashboard';
      }
      if (res['data']['active_role'] && res['data']['active_role'] == 2) {
        window.location = '/#/admin/admin-users';
      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  return (
    <>
      {impersonationBar && <div className="impersonateDiv">{impersonationBar + "   " + timer} <button
        onClick={userImpersonateEnd}
        className='endImpersonation'
      > End</button></div>}
      <Routes>
        <Route path='/influencer/social_connect' element={<Activation />} />
        <Route
          path='/influencer/*'
          element={
            <PrivateRouteAuth><Influencer
              path={location.pathname}
              user={user}
              setChangePage={setChangePage}
              changePage={changePage}
              allNotifications={allNotifications}
              setModalNotification={setModalNotification}
              setModalNotificationData={setModalNotificationData}
              setNotifModul={setNotifModul}
              updateNotificationStatus={updateNotificationStatus}
              accountCount={accountCount}
              locationData={locationData}
              impersonationBar={impersonationBar}
            /></PrivateRouteAuth>
          }
        />
        <Route
          path='/brand/*'
          element={
            <PrivateRouteAuth><Brand
              path={location.pathname}
              user={user}
              setChangePage={setChangePage}
              changePage={changePage}
              allNotifications={allNotifications}
              setModalNotification={setModalNotification}
              setModalNotificationData={setModalNotificationData}
              setNotifModul={setNotifModul}
              updateNotificationStatus={updateNotificationStatus}
              accountCount={accountCount}
              locationData={locationData}
              impersonationBar={impersonationBar}
              handleAnchorClick={handleAnchorClick}
              setSaveCampaign={setSaveCampaign}
              setShowConfirmModal={setShowConfirmModal}
              setShowConfirmAction={setShowConfirmAction}
              saveCampaign={saveCampaign}
            /></PrivateRouteAuth>
          }
        />
        <Route
          path='/admin/*'
          element={
            <PrivateRouteAuth><Admin
              path={location.pathname}
              user={user}
              setChangePage={setChangePage}
              changePage={changePage}
              allNotifications={allNotifications}
              setModalNotification={setModalNotification}
              setModalNotificationData={setModalNotificationData}
              setNotifModul={setNotifModul}
              updateNotificationStatus={updateNotificationStatus}
              accountCount={accountCount}
              locationData={locationData}
              impersonationBar={impersonationBar}
              handleAnchorClick={handleAnchorClick}
              setSaveCampaign={setSaveCampaign}
              setShowConfirmModal={setShowConfirmModal}
              setShowConfirmAction={setShowConfirmAction}
              saveCampaign={saveCampaign}
            /></PrivateRouteAuth>
          }
        />
        <Route
          path='/influencerdiscovery/*'
          element={<SearchInf path={location.pathname}
            user={user}
            setChangePage={setChangePage}
            changePage={changePage}
            allNotifications={allNotifications}
            setModalNotification={setModalNotification}
            setModalNotificationData={setModalNotificationData}
            setNotifModul={setNotifModul}
            updateNotificationStatus={updateNotificationStatus}
            accountCount={accountCount}
            locationData={locationData}
            impersonationBar={impersonationBar} />}
        />
        <Route path='/socialCallback' element={<SocialCallback referPageUrl={referPageUrl} />} />
        <Route
          path='/account/*'
          element={<Account path={location.pathname} referPageUrl={referPageUrl} />}
        />

        <Route path='/' element={<Navigate to={'/account/login'} />} />
        <Route path="*" element={<Navigate to={'/account/login'} />} />
      </Routes>
      <AnimatePresence>
        {modalNotification === 'notifications' && (
          <ModalNotification
            NotifModul={allNotifications}
            setModal={setModalNotification}
            setModalData={setModalNotificationData}
            setNotifModul={setNotifModul}
            close={() => setModalNotification(null)}
            updateNotificationStatus={updateNotificationStatus}
            modalNotification={modalNotification}
            setModalNotification={setModalNotification}
            accountCount={accountCount}
          />
        )}
        {(modalNotification === 'notifDetail' || modalNotification === 'notifDetailDash') && (
          <ModalNotifDetail
            dash={modalNotification === "notifDetailDash" ? true : false}
            accountCount={accountCount}
            setModalData={setModalNotificationData}
            setModal={setModalNotification}
            {...modalNotificationData}
            close={() => setModalNotification(null)}
          />
        )}
        {(modalNotification === 'notifDelete' || modalNotification === 'notifDeleteDash') && (
          <ModalNotifDelete
            setModal={setModalNotification}
            deleteNotif={() => { updateNotificationStatus(modalNotificationData.id, 'deleted') }}
            cancelBtnHandler={() => {
              setModalNotification('notifDetail');
            }}
            close={() => setModalNotification(null)}
          />
        )}
        {modalNotification === "notifExpired" && (
          <ModalNotifExpired
            setModalData={setModalNotificationData}
            setModal={setModalNotification}
            {...modalNotificationData}
            close={() => setModalNotification(null)}
          />
        )}
        {modalConfirm === 'confirm' && (
          <ModalInConfirmExitCampaign
            changePage={changePage}
            showConfirmAction={showConfirmAction}
            leave={() => { setModalConfirm(null); navigate(navigateLink) }}
            close={() => { setSaveCampaign(false); setModalConfirm(null); }}
            onSaveCamp={() => { setModalConfirm(null); setSaveCampaign(true); }}
          />
        )}

      </AnimatePresence>
    </>
  );
}

export default App;
import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {
    HeaderAcc,
    Toast,
    EmailInput,
    PasswordInput,
    SelectInput,
    TextInput,
    Modal
} from '../../Components';
import { motion } from 'framer-motion';
import { accountService, alertService } from '../../Services';
import { utils } from '../../Helpers/utils';
export default function ModalPricingSignIn(props) {

    const initForm = {
        email: '',
        password: '',
    };
    const navigate = useNavigate();
    const [form, setForm] = useState(initForm);
    const [captch, setCaptch] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [error, setError] = useState(false);
    const [errorTerms, setErrorTerms] = useState(false);
    let referId = searchParams.get('refId') ? searchParams.get('refId') : null;
    const setInput = key => event => updateForm({ [key]: event.target.value });
    // let roleType = searchParams.get('type') ? searchParams.get('type') : null;
    // roleType =
    //   roleType !== null && roleType == 1 ? "I'm a influencer" : "I'm a brand";
    let refId = (props.referId === null || props.referId === undefined) ? 0 : props.referId;
    const [selectDesc, setselectDesc] = useState(props.roleId);


    const addToast = data => {
        const newToastList = [...props.toastList];
        newToastList.push({ ...data, id: `${Date.now()}-${props.toastList.length}` });
        props.setToastList(newToastList);
    };
    const handleSignUpClick = () => {
        props.setModal('pricingformCreate')
    }


    const updateForm = data => {
        setForm({ ...form, ...data });
    };
    const onChange = value => {
        setCaptch(value);
    };
    let pathurl = (props.referPageUrl !== null && props.referPageUrl !== undefined && props.referPageUrl != "/account/validateEmail") ? props.referPageUrl.state ? props.referPageUrl.state.pathname : '' : '';
    //console.log(props.referPageUrl)
    const handleLoginWithGoogle = () => {
        //setIsLoginWithTamaEmail(false);
        //console.log(pathurl);

        window.location.href = `${process.env.REACT_APP_API_URL}/login/google/action/signin/role/3/0/referalPath/pricing/1`;
    };
    const loginValidation = () => {
        if (form.email === '' || form.password === '') {
            setError(true);
        } else {
            alertService.clear();
            accountService
                .login(form['email'], form['password'], props.subsProductId)
                .then(res => {

                    if (res['paypalurl'] && res['paypalurl'] !== "") {
                        //window.open(res['paypalurl']);
                        window.location.replace(res['paypalurl'])
                    }
                    if (res['data'] && res['data']['roles']) {
                        let arrPath = [];

                        if (
                            res['data']['role'] &&
                            res['data']['role'] === 'advertiser' &&
                            res['data']['active_role'] === 3
                        ) {
                            navigate('/brand/influencerdiscovery');
                        } else {
                            navigate('/account/login');
                        }
                    }
                    else {
                        navigate('/account/login');
                    }

                })
                .catch(err => {
                    setShowErrorMessage(err)
                    /* addToast({
                         error: true,
                         msg: err,
                         id: `${Date.now()}-${props.toastList.length}`
                     });*/
                });
        }
    };
    return (
        <Modal addClass='platform pricingModal' close={props.close}>
            <div className='modal__platform pricingSignin'>
                <motion.div
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4 }}
                    exit={{ opacity: 0, y: 0 }}
                    className='account__create'
                >
                    <div className='account__create-inner'>
                        <h2 className='uniq'>Login</h2>


                        <>
                            <button className='button' onClick={handleLoginWithGoogle}>
                                <img src={process.env.PUBLIC_URL + '/images/googleSign.png'} alt='' />
                                Sign in with Google
                            </button>



                            <h5 className='reg'>or</h5>

                            <div className='account__login-inner'>
                                <p className="messageText"><strong>{ }</strong></p>

                                <h2 className='uniq'>Login</h2>

                                <EmailInput
                                    value={form.email}
                                    type='email'
                                    onChange={setInput('email')}
                                    label='E-mail'
                                    error={error}
                                    autoFocus
                                    errorText='Please enter Email'
                                />
                                <PasswordInput
                                    value={form.password}
                                    type='password'
                                    onChange={setInput('password')}
                                    label='Password'
                                    error={error}
                                    page="loginpage"
                                    errorText={form.password === "" ? 'Please enter Password' : ""}
                                />
                                <div className='account__login-row loginPricingScreen'>
                                    <div className='checkbox'>
                                        <input type='checkbox' />
                                        <label htmlFor=''></label>
                                        <p>Remember me</p>
                                    </div>
                                    <a class="account__login-row-link" href="#/account/forgot-pass">Forgot Password</a>
                                </div>
                                <div className="input__error captchaerror signuperror">{showErrorMessage}</div>
                                <button className='button primary' onClick={loginValidation}
                                >
                                    Login
                                </button>
                                <p className='sm'>
                                    Don’t have an account?{' '}
                                    <a onClick={handleSignUpClick}>Sign Up</a>
                                </p>
                            </div>

                        </>

                    </div>
                </motion.div>
            </div>
        </Modal>
    );
}
import * as React from 'react';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
export default function HalfRating({ infrating }) {
  console.log(infrating)
  return (
    <Stack spacing={1}>
      <Rating name="half-rating" defaultValue={infrating} precision={0.5} readOnly />
    </Stack>
  );
}
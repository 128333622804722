import React, { useState, useEffect } from "react";
import { Toast, Profile } from "../../Components";
import RecommendForm from "./RecommendForm";
import RecommendContent from "./RecommendContent";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  influencerService,
  productService
} from '../../Services';
const initForm = {
  category: [],
  countries: [],
  budget: "$---",
  facebookDel: [],
  instagramDel: [],
  tikTokDel: [],
  youtubeDel: [],
  reach: "",
  interaction: "",
  gender: [],
  age18_24: false,
  age25_34: false,
  age35_44: false,
  age45_54: false,
  age55_64: false,
  age65_plus: false,
  legalDringkingAge: false,
  length: 0,
  influencer: [],
  // age13_17: false,
  // age18_24: false,
  // age25_34: false,
  // age35_44: false,
  // age45_54: false,
  // age55_64: false,
  // age65_plus: false,
  // budget: "$---",
  // countries: ["United States of America", "Great Britain", "Canada"],
  // facebookDel: [{ id: "1", value: "Post + story" }],
  // gender: "Male",
  // industries: ["Fashion", "Spirits", "Lifestyle"],
  // instagramDel: [{ id: "1", value: "Reel + story" }],
  // interactions: "213,123",
  // legalDringkingAge: false,
  // reach: "123,213",
};
export default function Recommend({ cartItem, closeCartPanel, setAuth, setModal, NotifModul, account, setModalSwitch, setModalNotification, user,
  profiles, }) {
  const [form, setForm] = useState(initForm);


  const navigate = useNavigate();
  const [toastList, setToastList] = useState([]);
  const [categoriesData, setCategoriesData] = useState({});
  const [countryData, setConuntryData] = useState({});
  const [facebookProducts, setFacebookProducts] = useState([]);
  const [instaProducts, setInstaProducts] = useState([]);
  const [tikTokProducts, setTikTokProducts] = useState([]);
  const [youtubeProducts, setYoutubeProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [budgetVal, setBudgetVal] = useState('$---');
  const [budgetLength, setBudgetLength] = useState(0);
  const [campInfluencer, setCampInfluencer] = useState([]);

  const [estReach, setEstReach] = useState();
  const [estInteraction, setEstInteraction] = useState();

  const [render, setRender] = useState(false);
  const [error, setError] = useState(false);
  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  const updateForm = (data) => {
    setForm({ ...form, ...data });
    setTimeout(function () {
      setRender(!render)
    }, 1000)
  };

  const calcAge = () => {
    let str = "";
    let str2 = "";
    let str3 = "";
    let str4 = "";
    let str5 = "";
    let str6 = "";
    let str7 = "";
    let str8 = "";
    let arr = [];
    if (
      !form.age18_24 &&
      !form.age25_34 &&
      !form.age35_44 &&
      !form.age45_54 &&
      !form.age55_64 &&
      !form.age65_plus &&
      !form.legalDringkingAge
    ) {
      return ["---"];
    }
    if (form.age18_24 && !form.age25_34) {
      str2 = str2 + "18-24";
      arr.push(str2);
    } else if (
      form.age18_24 &&
      form.age25_34 &&
      !form.age35_44
    ) {
      str2 = str2 + "18-34";
      arr.push(str2);
    } else if (
      form.age18_24 &&
      form.age25_34 &&
      form.age35_44 &&
      !form.age45_54
    ) {
      str2 = str2 + "18-44";
      arr.push(str2);
    } else if (
      form.age18_24 &&
      form.age25_34 &&
      form.age35_44 &&
      form.age45_54 &&
      !form.age55_64
    ) {
      str2 = str2 + "18-54";
      arr.push(str2);
    } else if (
      form.age18_24 &&
      form.age25_34 &&
      form.age35_44 &&
      form.age45_54 &&
      form.age55_64 &&
      !form.age65_plus
    ) {
      str2 = str2 + "18-64";
      arr.push(str2);
    } else if (
      form.age18_24 &&
      form.age25_34 &&
      form.age35_44 &&
      form.age45_54 &&
      form.age55_64 &&
      form.age65_plus
    ) {
      str2 = str2 + "18-65+";
      arr.push(str2);
    }
    if (!form.age18_24 && form.age25_34 && !form.age35_44) {
      str3 = str3 + "25-34";
      arr.push(str3);
    } else if (
      !form.age18_24 &&
      form.age25_34 &&
      form.age35_44 &&
      !form.age45_54
    ) {
      str3 = str3 + "25-44";
      arr.push(str3);
    } else if (
      !form.age18_24 &&
      form.age25_34 &&
      form.age35_44 &&
      form.age45_54 &&
      !form.age55_64
    ) {
      str3 = str3 + "25-54";
      arr.push(str3);
    } else if (
      !form.age18_24 &&
      form.age25_34 &&
      form.age35_44 &&
      form.age45_54 &&
      form.age55_64 &&
      !form.age65_plus
    ) {
      str3 = str3 + "25-64";
      arr.push(str3);
    } else if (
      !form.age18_24 &&
      form.age25_34 &&
      form.age35_44 &&
      form.age45_54 &&
      form.age55_64 &&
      form.age65_plus
    ) {
      str3 = str3 + "25-65+";
      arr.push(str3);
    }
    if (!form.age25_34 && form.age35_44 && !form.age45_54) {
      str4 = str4 + "35-44";
      arr.push(str4);
    } else if (
      !form.age25_34 &&
      form.age35_44 &&
      form.age45_54 &&
      !form.age55_64
    ) {
      str4 = str4 + "35-54";
      arr.push(str4);
    } else if (
      !form.age25_34 &&
      form.age35_44 &&
      form.age45_54 &&
      form.age55_64 &&
      !form.age65_plus
    ) {
      str4 = str4 + "35-64";
      arr.push(str4);
    } else if (
      !form.age25_34 &&
      form.age35_44 &&
      form.age45_54 &&
      form.age55_64 &&
      form.age65_plus
    ) {
      str4 = str4 + "35-65+";
      arr.push(str4);
    }
    if (!form.age35_44 && form.age45_54 && !form.age55_64) {
      str5 = str5 + "45-54";
      arr.push(str5);
    } else if (
      !form.age35_44 &&
      form.age45_54 &&
      form.age55_64 &&
      !form.age65_plus
    ) {
      str5 = str5 + "45-64";
      arr.push(str5);
    } else if (
      !form.age35_44 &&
      form.age45_54 &&
      form.age55_64 &&
      form.age65_plus
    ) {
      str5 = str5 + "45-65+";
      arr.push(str5);
    }
    if (!form.age45_54 && form.age55_64 && !form.age65_plus) {
      str6 = str6 + "55-64";
      arr.push(str6);
    } else if (!form.age45_54 && form.age55_64 && form.age65_plus) {
      str6 = str6 + "55-65+";
      arr.push(str6);
    }
    if (!form.age55_64 && form.age65_plus) {
      str7 = str7 + "65+";
      arr.push(str7);
    }
    if (form.legalDringkingAge) {
      str8 = str8 + "Legal drinking age";
      arr.push(str8);
    }
    return arr;
  };
  useEffect(() => {
    async function getInfluencer() {
      try {
        let product = [];
        form.facebookDel.map(item => {
          product.push(item.id);
        })
        form.instagramDel.map(item => {
          product.push(item.id);
        })
        form.tikTokDel.map(item => {
          product.push(item.id);
        })
        form.youtubeDel.map(item => {
          product.push(item.id);
        })
        let gender_f = false;
        let gender_m = false;
        form.gender.map(item => {
          if (item.value === 'Men focused') {
            gender_m = true;
          }
          if (item.value === 'Women focused') {
            gender_f = true;
          }

        })
        let filterString = '';// `filter[reach]=` + parseInt(form.reach);
        // if (searchText != "" && selectDesc != "") {
        //filterString += '&filter[interaction]=' + parseInt(form.interaction);
        if (typeof product != undefined && product != NaN && product != '') {
          filterString += '&filter[product_id]=' + product.join(',');
        }
        if (typeof form.category != undefined && form.category != NaN && form.category != '') {
          filterString += '&filter[category_id]=' + (form.category).join(',');
        }
        if (typeof form.reach != undefined && form.reach != NaN && form.reach != '') {
          filterString += '&reach=' + parseInt(form.reach);
        }
        if (typeof form.interaction != undefined && form.interaction != NaN && form.interaction != '') {
          filterString += '&interaction=' + parseInt(form.interaction);
        }
        if (typeof gender_f != undefined && gender_f != NaN && gender_f != '') {
          filterString += '&filter[genderf]=' + gender_f;
        }
        if (typeof gender_m != undefined && gender_m != NaN && gender_m != '') {
          filterString += '&filter[genderm]=' + gender_m;
        }
        if (typeof form.age18_24 != undefined && form.age18_24 != NaN && form.age18_24 != '') {
          filterString += '&filter[age1824]=' + form.age18_24;
        }
        if (typeof form.age25_34 != undefined && form.age25_34 != NaN && form.age25_34 != '') {
          filterString += '&filter[age2534]=' + form.age25_34;
        }
        if (typeof form.age35_44 != undefined && form.age35_44 != NaN && form.age35_44 != '') {
          filterString += '&filter[age3544]=' + form.age35_44;
        }
        if (typeof form.age45_54 != undefined && form.age45_54 != NaN && form.age45_54 != '') {
          filterString += '&filter[age4554]=' + form.age45_54;
        }
        if (typeof form.age55_64 != undefined && form.age55_64 != NaN && form.age55_64 != '') {
          filterString += '&filter[age5564]=' + form.age55_64;
        }
        if (typeof form.age65_plus != undefined && form.age65_plus != NaN && form.age65_plus != '') {
          filterString += '&filter[age65plus]=' + form.age65_plus;
        }
        if (typeof form.legalDringkingAge != undefined && form.legalDringkingAge != NaN && form.legalDringkingAge != '') {
          filterString += '&filter[drinkingage]=' + form.legalDringkingAge;
        }
        //filterString += '&filter[gender_f]=' + gender_f;
        //filterString += '&filter[gender_m]=' + gender_m;
        // }
        /*let payload = {
          'reach': parseInt(form.reach),
          'interactions': parseInt(form.interaction),
          'category': form.category,
          'product': product,
          'gender_f': gender_f,
          'gender_m': gender_m,
          'age13_17': form.age13_17,
          'age18_24': form.age18_24,
          'age25_34': form.age25_34,
          'age35_44': form.age35_44,
          'age45_54': form.age45_54,
          'age55_64': form.age55_64,
          'age65_plus': form.age65_plus,
          'legalDringkingAge': form.age65_plus,
        }*/
        const res = await influencerService.getRecommendInfluencer(filterString);
        setCampInfluencer(res.estInfluencer);
        setBudgetLength(res.estLength);
        setBudgetVal(res.estBudget === 0 ? '$---' : res.estBudget);
        setEstReach(res.estReach);
        setEstInteraction(res.estInteraction);
      }
      catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    getInfluencer();
  }, [render]);
  useEffect(() => {
    async function fetchCategoriesData() {
      try {
        const res = await influencerService.getCategoriesDetails();
        let newCountry = [];
        Object.entries(res.countries).map((country) => { (newCountry.push(country[1])) })
        setConuntryData(newCountry);
        setCategoriesData(res);
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }


    async function fetchProduct() {
      try {
        const productData = await productService.getAll({});
        let fb = [];
        let insta = [];
        let tikTok = [];
        let youtube = [];
        productData.data.map((item, index) => {
          if (item.name.startsWith("Facebook")) {
            fb.push(item);
          } else if (item.name.startsWith("Insta")) {
            insta.push(item);
          } else if (item.name.startsWith("Tik")) {
            tikTok.push(item);
          } else if (item.parent === 17) {
            youtube.push(item);
          }
        })
        setFacebookProducts(fb);
        setInstaProducts(insta);
        setTikTokProducts(tikTok);
        setYoutubeProducts(youtube);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchCategoriesData();
    fetchProduct();
  }, []);
  const buildCamp = async () => {
    if ((form.facebookDel.length === 0 && form.instagramDel.length === 0 && form.tikTokDel.length === 0 && form.youtubeDel.length === 0)) {
      setError(true);
      addToast({
        error: true,
        msg: "Please select Facebook or Instagram or TikTok or Youtube Product.",
        id: `${Date.now()}-${toastList.length}`
      });
    } else {
      if (form.reach === "" && form.interaction === "") {
        addToast({
          error: true,
          msg: "Please enter Interactions or Reach.",
          id: `${Date.now()}-${toastList.length}`
        });
      } else {
        setError(false);
        try {
          let genderVal = form.gender.length === 0 ? "" : form.gender.map((item, index) =>
            index + 1 !== form.gender.length
              ? item.value + ", "
              : item.value
          )
          let calAgeVal = (calcAge());
          let ageVal = calAgeVal.length > 0 && calAgeVal[0] != '---' ? calAgeVal.map((item, index) =>
            index + 1 !== calAgeVal.length ? item + ", " : item
          ) : '';
          console.log(form.facebookDel);
          let fbProductVal = form.facebookDel.length > 0 ? form.facebookDel.map((item, index) =>
            parseInt(item.id) + ""

          ) : '';
          let instaProductVal = form.instagramDel.length > 0 ? form.instagramDel.map((item, index) =>
            parseInt(item.id) + ""
          ) : '';
          let tikTokProductVal = form.tikTokDel.length > 0 ? form.tikTokDel.map((item, index) =>
            parseInt(item.id) + ""
          ) : '';
          let youtubeProductVal = form.youtubeDel.length > 0 ? form.youtubeDel.map((item, index) =>
            parseInt(item.id) + ""
          ) : '';
          console.log(budgetVal.toString().charAt(0));
          let payloadPrice = budgetVal;
          let payload = {
            budget: form.budget,
            category: form.category,
            reach: parseInt(form.reach),
            interaction: parseInt(form.interaction.replaceAll(",", '')),
            gender: genderVal,
            age: ageVal,
            insta_product_id: instaProductVal,
            fb_product_id: fbProductVal,
            tiktok_product_id: tikTokProductVal,
            youtube_product_id: youtubeProductVal,
            length: budgetLength,
            budget: payloadPrice.toString().charAt(0) === '$' ? payloadPrice.substring(1) : payloadPrice,
            influencers: campInfluencer,
            estreach: estReach,
            estinteraction: estInteraction,

          }
          const productData = await influencerService.saveRecommendation(payload);
          if (productData.status === "success") {
            addToast({
              error: false,
              msg: productData.message,
              id: `${Date.now()}-${toastList.length}`
            });
            navigate('/brand/campaigns', {
              state: {
                data: [{ recommendedId: productData.id }]
              }
            });
          }

        } catch (err) {
          addToast({
            error: true,
            msg: err,
            id: `${Date.now()}-${toastList.length}`
          });
        }

      }


    }

  }
  return (
    <>
      <div className="main">
        <div className="main__inner">
          <div className="main__inner-title">
            <div className="main__inner-title-col extra">
              <h2>Recommendations</h2>
              <p>
                Enter your campaign goals and we will give you an estimated
                budget.
              </p>
            </div>

            <Profile
              type='brand'
              closeCartPanel={closeCartPanel}
              user={user}
              setModal={setModalSwitch}
              NotifModul={NotifModul}
              profiles={profiles}
              setNotifModul={setModalNotification}
              cartItem={cartItem}
            />
          </div>
          <div className="recom">
            {(categoriesData && isLoaded) && <>
              <RecommendForm error={error} form={form} updateForm={updateForm} countries={countryData}
                categoriesData={categoriesData.categories}
                categories={categoriesData.categories.map(
                  cat => cat.category
                )}
                facebookProducts={facebookProducts}
                instaProducts={instaProducts}
                tikTokProducts={tikTokProducts}
                youtubeProducts={youtubeProducts} />
              <RecommendContent budgetLength={budgetLength} setBudgetVal={setBudgetVal} budgetVal={budgetVal} calcAge={calcAge} form={form} updateForm={updateForm} buildCamp={buildCamp} categoriesData={categoriesData.categories} facebookProducts={facebookProducts}
                instaProducts={instaProducts}
                estReach={estReach}
                estInteraction={estInteraction}
              /></>}
          </div>
        </div>
      </div>

      {toastList && (
        <Toast type="rate" list={toastList} setList={setToastList} />
      )}
    </>
  );
}

import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { TextInput, Industries } from "../../../Components";
import { EditorState, convertToRaw, convertFromRaw, ContentState } from "draft-js";
import { utils } from '../../../Helpers/utils';
import { EditorInput } from "../../../Components";
export default function Details({ setInputChkeckbox, isFeatured, error, errorName, form, setInput, updateForm, readOnly,
  categoriesData, categories, engagementId }) {


  const tagsWrapper = useRef(null);

  const inpRef = useRef(null);
  let contentState = "";
  let descHasValidJson = utils.testJSON(form.description);
  if (descHasValidJson === true) {
    //console.log(JSON.parse(form.description));
    contentState = convertFromRaw(
      form.description ? descHasValidJson === true ? JSON.parse(form.description) : JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`) : JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`)
    );
  } else {
    if (form.description !== null) {
      contentState = (ContentState.createFromText(form.description));
    } else {
      contentState = convertFromRaw(JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`));
    }

  }
  // console.log(contentState)
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(contentState)
  );
  //console.log(editorState)
  const updateTextDescription = async (state) => {
    await setEditorState(state);
    const rawContentState = convertToRaw(state.getCurrentContent());
  };
  const setDesc = key => event => {
    updateForm({ ['description']: JSON.stringify(event) });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className="form"
    >
      {(form.hashtags) && (
        <>
          <div className="form__title">
            <h3 className="big">Details {readOnly ? form.id ? '[' + form.id + ']' : engagementId != "" ? '[' + engagementId + ']' : '' : ''}</h3>
          </div>
          <div className="formCampaigns__detail">
            <div className="formCampaigns__detail-desc">
              <TextInput
                value={form.title}
                type="text"
                onChange={setInput("title")}
                label="Campaign name"
                error={error || errorName}
                errorText="Please enter Campaign name"
                readOnly={readOnly}
              />
            </div>
            {isFeatured &&
              <div className="formCampaigns__detail-deliver">
                <input type='checkbox'
                  name='is_featured'
                  onChange={setInputChkeckbox()}
                  checked={form.is_featured === 1 ? true : false}
                  disabled={readOnly} />
                <label >  Is Featured</label>
              </div>
            }
            {/*<div className="formCampaigns__detail-deliver">             
              <input type='checkbox' name='is_featured' onChange={setInputChkeckbox()}/>  <label >Is Featured</label>
      </div>*/}
            <div className="formCampaigns__detail-desc">
              <EditorInput
                value={form.description === "" ? form.description : utils.testJSON(form.description) === true ? JSON.parse(form.description).blocks : ""}
                editorState={editorState}
                label="Campaign overview <br/><span className='smallText'> Provide a brief description of your campaign, product and objectives. (Max length 1500 characters)</span>"
                onEditorStateChange={updateTextDescription}
                error={error}
                errorText="Please enter description"
                onChange={setDesc(convertToRaw(editorState.getCurrentContent()))}
                readOnly={readOnly}
              />
            </div>
            <div className="formCampaigns__detail-hashtags">
              <div className="input__outer">
                <Industries form={form} readOnly={readOnly} updateForm={updateForm} categoriesList={categoriesData} categories={categories} />
              </div>
            </div>
          </div>
        </>
      )
      }
    </motion.div >
  );
}
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { utils } from '../../Helpers/utils';

import {
  SearchInput,
  SelectInput,
  Pagination,
  download,
  plusIcon,
  searchIcon,
  Profile,
  Toast
} from '../../Components';
import OrderItem from './OrderItem';

import { orderService } from '../../Services';
import NotSubscribe from '../../Components/Base/Common/NotSubscribe';
const selectDescList = [
  { id: '30days', value: '30 days' },
  { id: '3month', value: '3 Month' },
  { id: '2025', value: '2025' },
  { id: '2024', value: '2024' }
];

export default function Order({
  setAuth,
  setModal,
  NotifModul,
  user,
  profiles,
  setModalNotification,
  closeCartPanel,
  cartItem,
  goToPricing
}) {
  // eslint-disable-next-line
  const [selectDesc, setselectDesc] = useState(selectDescList[0].value);
  const [isloaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [orderDetails, setOrderDetails] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [searchList, setSearchList] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [disableSearchBox, setDisableSearchBox] = useState(false);

  const [isUserHasSubscription, setUserHasSubscription] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    async function fetchData() {
      try {
        let filter = '';
        if (searchString !== '') {
          if (filter != "") {
            filter += '&filter[timespan]=' + searchString;
          } else {
            filter = 'filter[timespan]=' + searchString;
          }
        } else {

          filter = 'filter[created_at]=' + selectDesc;

        }
        const res = await orderService.getAll({
          page: page,
          pageSize,
          filter: filter
        });
        //console.log(res.data)
        if (
          res['meta'] &&
          res['meta']['pagination']
        ) {
          //console.log("insode")
          setPaginationData(res['meta']['pagination']);
        }
        //console.log(paginationData)
        setOrderDetails(res.data);

        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, [page, searchList]);
  const downloadCsv = async () => {
    try {
      let filter = "";
      if (searchString !== '') {
        if (filter != "") {
          filter += '&filter[' + selectDesc + ']=' + searchString;
        } else {
          filter = 'filter[' + selectDesc + ']=' + searchString;
        }
      } else {
        if (selectDesc === 'lastyr' || selectDesc === '30days') {
          filter = 'filter[created_at]=' + selectDesc;
        }
      }
      const response = await orderService.getAll({
        page: page,
        pageSize,
        filter: filter,
        fileType: 'csv'
      });
      utils.downloadFile(response, "offer_page_" + page + ".csv");
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const onPaginate = page => {
    setPage(page);
  };
  useEffect(() => {
    setUserHasSubscription(user !== null ? user.data.isUserHasSubscription == 1 ? true : false : false)
  }, [user]);

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };


  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        search();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [searchString]);


  const changeMediumDesc = item => {
    setselectDesc(item.id)
    setPage(1);
    if (item.id === 'lastyr' || item.id === '30days') {
      setDisableSearchBox(true);
    } else {
      setDisableSearchBox(false);
    }
    setSearchString("");
    setSearchList(!searchList);
  };

  const setSearchStringText = event => {
    if (event.target.value === '') {
      setSearchList(!searchList);
    }
    setSearchString(event.target.value);
  };

  const search = () => {
    setSearchList(!searchList);
    setPage(1);

  };
  const showOrderDetails = (id) => {
    navigate("/brand/orderDetails/" + id)
  }

  return (
    <>
      {isUserHasSubscription === true &&
        <>
          <div className='main'>
            <div className='main__inner'>
              <div className='main__inner-title'>
                <h2>Orders</h2>
                <Profile
                  type='brand'
                  setAuth={setAuth}
                  setModal={setModal}
                  NotifModul={NotifModul}
                  user={user}
                  profiles={profiles}
                  setNotifModul={setModalNotification}
                  closeCartPanel={closeCartPanel}
                  cartItem={cartItem}
                />
              </div>
              {isloaded && (

                <div className='billing dashboard'>


                  <div className='billing__table-title orderFilter'>
                    <h4>{paginationData.total} orders placed in </h4>
                    <div className=''>
                      <SelectInput
                        list={selectDescList}
                        selected={selectDescList[0]}
                        onChange={changeMediumDesc}
                      />


                    </div>
                  </div>
                  <div className='billing__table'>
                    <table>
                      <thead>
                        <tr>
                          <th>Order Date</th>
                          <th>Amount</th>
                          <th>Offers</th>
                          <th>Status</th>
                          <th>Order No.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(orderDetails.length > 0) ? (
                          <>
                            {orderDetails.map((item, index) => {
                              return (
                                <OrderItem
                                  showOrderDetails={showOrderDetails}
                                  key={index}
                                  {...item}
                                />
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td colSpan='5' style={{ textAlign: 'center' }}>
                              No records
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className='billing__table-footer paginationEnd'>

                    {orderDetails && orderDetails.length > 0 &&
                      <Pagination
                        perPage={paginationData['per_page']}
                        total={paginationData['total']}
                        currentPage={paginationData['current_page']}
                        totalPages={paginationData['total_pages']}
                        onPaginate={onPaginate}
                      />
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
          {toastList && <Toast list={toastList} setList={setToastList} />}
        </>}
      {isUserHasSubscription === false && user !== null && (
        <>
          <NotSubscribe goToPricing={goToPricing}
            type='brand'
            setAuth={setAuth}
            setModal={setModal}
            NotifModul={NotifModul}
            setNotifModul={setModalNotification}
            profiles={profiles}
            closeCartPanel={closeCartPanel}
            user={user}
            cartItem={cartItem}
            title={"Orders"}
            background={"background_orders.png"}>
          </NotSubscribe>
        </>
      )
      }
    </>
  );
}

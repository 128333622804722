import React, { useState, useEffect } from "react";
import { Modal } from '../../..';
import { utils } from '../../../../Helpers/utils';
import ModalRevisionConfirm from "./ModalRevisionConfirm";
import ModalChangeReq from "./ModalChangeReq";
import { AnimatePresence } from 'framer-motion';
import {
  influencerService
} from '../../../../Services';
// import { arrowLeft, trashIcon } from '../../../Components';


export default function ModalShowRevision(props) {
  const [modal, setModal] = useState(null);
  const [toastList, setToastList] = useState([]);
  const [modalData, setModalData] = useState(null);

  const addToast = (data) => {
    const newToastList = [];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  const approveRevision = async () => {
    try {
      setModal("confirm");
      let pageSize = 10;
      const res = await influencerService.getRevisionWithStatus({
        page: 1,
        pageSize,

      }, '', ''
      );
      if (res.meta && res.meta.pagination) {
        // props.setPaginationData(res.meta.pagination);
      }
      props.setInfEngList(res.data);
      props.setLoadList(true);

      //console.log("refreshing");
    } catch (err) {
      //console.log("err....." + err);
      addToast({
        error: true,
        date: new Date(),
        msg: 'Error!' //err.replaceAll(/<\/?[^>]+(>|$)/gi, '')
      });
      props.close();
    }
  };

  const changeRevision = async () => {
    try {
      setModal("changeReq");
      props.setIsLoaded(true);
    } catch (err) {
      console.log("err....." + err);
      addToast({
        error: true,
        date: new Date(),
        msg: 'Error!' //err.replaceAll(/<\/?[^>]+(>|$)/gi, '')
      });
      props.close();
    }
  };
  //console.log(props.influencerFlag);
  return (
    <Modal close={props.close}>
      <div className='modal__opportunity '>
        <div className='modal__opportunity-title'>
          <div className='modal__opportunity-title-image'>
            <img
              src={
                props.modalData.brand_logo
                  ? props.modalData.brand_logo
                  : `${process.env.PUBLIC_URL}/images/bulls-eye.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `${process.env.PUBLIC_URL}/images/bulls-eye.png`;
              }}
              alt='bulls-eye'
            />
          </div>
          <div className='modal__opportunity-title-content'>
            <h3>{props.modalData.brand_name}</h3>
            <div className='modal__opportunity-title-content-row'>
              <h6>{props.modalData.engagement.title}</h6>
            </div>
          </div>
        </div>
        <div className='modal__opportunity-content'>
          <p><strong>{props.modalData.product_name ? props.modalData.product_name : "ff"}</strong></p>
          <div className=''>
            {/* <h4>show Revision</h4> */}
          </div>
          <div className=''>
            <h3>Link</h3>
            <p >
              {props.modalData.link}
            </p>
            <h3>Content</h3>
            <p >
              {props.modalData.content}
            </p>

            <h3>Notes</h3>
            <p >
              {props.modalData.notes}
            </p>

            <h3>Feedback</h3>
            <p >
              {props.modalData.feedback ? props.modalData.feedback : 'N/A'}
            </p>
          </div>
        </div>
        {(props.influencerFlag == 0 && props.modalData.status == 'pending') ?
          <div className='modal__opportunity-footer'>
            <div className='modal__opportunity-footer-btns'>
              <button
                className='button primary'

                onClick={() => {
                  // console.log("save revision");
                  setModalData(props);
                  approveRevision();
                }
                }>
                Approve
              </button>

              <button
                className='button secondary'
                onClick={() => {
                  //console.log("save revision");
                  setModalData(props);
                  changeRevision();
                }
                } >
                Request a change
              </button>
            </div>
          </div>
          : ''}

      </div>
      <AnimatePresence>
        {modal === "confirm" && (
          <ModalRevisionConfirm
            close={() => setModal(null)}
            data={props.modalData}
            addToast={props.addToast}
            setInfEngList={props.setInfEngList}
            setLoadList={props.setLoadList}
            setModal={props.setModal}
            campaignId={props.campaignId}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "changeReq" && (
          <ModalChangeReq
            close={() => setModal(null)}
            data={props.modalData}
            addToast={props.addToast}
            setInfEngList={props.setInfEngList}
            setLoadList={props.setLoadList}
            setModal={props.setModal}
            campaignId={props.campaignId}
          />
        )}
      </AnimatePresence>
    </Modal >
  );
}

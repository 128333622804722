import React from "react";
import { Modal } from "../../../Components";
export default function ModalCampPublish(props) {
  let budgetstr = '';
  let budget = 0;
  let finalBudget = 0;
  const { selectedContent, selectedProduct, selectedInfluencer } = props.selectedItems;

  selectedInfluencer.forEach((influencer) => {
    // Loop through the products array for each influencer
    influencer.products.forEach((product) => {
      let rate = 0;
      if (product.overwrite == 1) {
        rate = parseInt(((product.value).replace('$', '')).replace(',', ''));
      } else {
        rate = parseInt(((product.org_rate).replace('$', '')).replace(',', ''));
      }
      budget = budget + rate;
    });
  });
  if (props.campaignMoney > 0) {
    if (props.campaignMoney > budget) {
      finalBudget = 0;
    } else {
      finalBudget = Math.abs(props.campaignMoney - budget);
    }
  } else {
    finalBudget = budget;
  }

  if (finalBudget != 0) {
    budgetstr = "\nYou are exceeding Total Budget by $" + Math.abs(finalBudget);
  }
  return (
    <Modal addClass="modalCamp" close={props.close}>
      <div className="modal__camp">
        <div className="modal__assetDetail-delete perm long">
          <h4>Are you sure?</h4>
          <p className="sm">
            Creating this engagement will make it active and no longer editable.
            <p style={{ color: 'red' }}>{budgetstr}</p>
          </p>
          <div className="modal__assetDetail-delete-btns">
            <button className="button secondary" onClick={props.close}>
              Cancel
            </button>
            <button className="button primary" onClick={props.publishBtnClick}>
              Create engagement
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

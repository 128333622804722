import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

import {
  TextInput,
  TextArea,
  arrowRight,
  closeIcon,
  plusIcon,
  Toast
} from "../../Components";
import TagInput from "../../Components/Base/Form/TagInput";

import {
  influencerlistService,
  uploadService
} from '../../Services';


export default function ListEdit(props) {
  const tagsWrapper = useRef(null);
  const inpRef = useRef(null);
  const fileRef = useRef(null);
  const editList = useRef(null);
  const [form, setForm] = useState({
    name: props.listDetails.name,

  });
  const [error, setError] = useState(false);
  const [listFile, setListFile] = useState(form.mediafile);
  const [disableUpdateButton, setDisableUpdateButton] = useState(false);
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });

  const addToast = data => {
    const newToastList = [...props.toastList];
    if (data.msg) {
      if (data.msg[data.msg.length - 1] === ".")
        data.msg = data.msg.slice(0, -1);
    }
    newToastList.push({ ...data, id: `${Date.now()}-${props.toastList.length}` });
    props.setToastList(newToastList);
  };


  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        editList.current.click();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [form]);

  useEffect(() => {
    updateForm({ mediafile: listFile });
  }, [listFile]);

  const submitBtnClick = async () => {
    if (form.name === "") {
      setError(true);
      //addToast({ error: true, date: new Date(), msg: 'Enter all details to edit list.' });
    } else {
      try {
        const res = await influencerlistService.update(props.listDetails.id, form);
        props.setTabModal("null");
        props.setListName(form.name)
        // props.setModalDataList(...props.modalDataList, { ['name']: form.name });
        addToast({ error: false, date: new Date(), msg: res.message });
      } catch (err) {
        props.setTabModal("null")
        addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, "") });
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className="assetsAdd"
    >
      <div className="assetsAdd__form">
        <div className="assetsAdd__form-title">
          <h3 className="big">Edit List Name</h3>
        </div>
        <div className="assetsAdd__form-inner">
          <div className="assetsAdd__form-head">
            <TextInput
              value={form.name}
              type="text"
              onChange={setInput("name")}
              label="Name"
              error={error}
              errorText="Please enter name"
              autoFocus
            />
          </div>
        </div>
        <div class="campaignsCreate__btns">
          <div class="campaignsCreate__btns-row"> <button className="button secondary " onClick={() => props.setTabModal('delete')} >
            Delete
          </button></div>
          <button ref={editList} className="button primary " onClick={submitBtnClick} >
            Rename
          </button></div>


      </div>

    </motion.div>
  );
}

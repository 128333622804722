import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ImageSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings}>
      <div className="slider-img-wrap">
        <div className="card-text-top">
          <h4>Adventure</h4>
        </div>
        <div className="card-text">
          <h4>Adventure</h4>
          <p>1 influencer</p>
        </div>
        <img
          alt="Person standing in front of a wall with a statue"
          src="https://img.freepik.com/free-photo/lone-tree_181624-46361.jpg"
        />
      </div>
      <div className="slider-img-wrap">
        <div className="card-text-top">
          <h4>Adventure</h4>
        </div>
        <div className="card-text">
          <h4>Adventure</h4>
          <p>1 influencer</p>
        </div>
        <img
          alt="Person smiling and holding a plate of food"
          src="https://img.freepik.com/free-photo/panoramic-shot-mountains-alps-with-sun-shining-blue-skies_181624-16935.jpg"
        />
      </div>
      <div className="slider-img-wrap">
        <div className="card-text-top">
          <h4>Adventure</h4>
        </div>
        <div className="card-text">
          <h4>Adventure</h4>
          <p>1 influencer</p>
        </div>
        <img
          alt="Person holding a box of skincare products"
          src="https://img.freepik.com/free-photo/beautiful-sunrise-temple-khao-kho-phetchabun-thailand_335224-873.jpg"
        />
      </div>
    </Slider>
  );
}

import React from "react";
import Modal from "../../Components/Base/Modal";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
export default function InnerRatePanelBox(props) {
    console.log(props)
    return (
        <>
            {((props.package == 'all' || props.package == 'insta') && (props.instModul.length > 0)) &&

                props.instModul.map(instaitem => {
                    return <>
                        {
                            instaitem.rates.map((item) => {
                                return < div className="package">
                                    <div className="packge-head-wrap">
                                        <h2>{item.rateName}</h2>
                                        <div className="price divForastrictForPrice">${item.price}{props.influencerUserType != 'registered' &&
                                            <span className="asterisk astrictForPrice">*</span>}</div>
                                    </div>
                                    <p>{item.desc}</p>

                                    <div className="bottom-row">
                                        <div className="socialFollowers" >
                                            <img src="/images/instagram.png" alt="instagram" />{(props.instModul.length > 1 && instaitem.followers_count != "") && instaitem.followers_count + ' Followers'}
                                        </div>
                                        <button className="add-to-cart" onClick={() => props.addToCart(instaitem, item, props.instModul.length)}>Add to Cart</button>
                                    </div>
                                </div >
                            })}
                    </>
                })







            }
            {((props.package == 'all' || props.package == 'tiktok') && (props.tiktokModul.length > 0)) &&
                props.tiktokModul.map(tiktokitem => {
                    return <>
                        {
                            tiktokitem.rates.map((item) => {
                                return < div className="package">
                                    <div className="packge-head-wrap">
                                        <h2>{item.rateName}</h2>
                                        <div className="price divForastrictForPrice">${item.price}{props.influencerUserType != 'registered' &&
                                            <span className="asterisk astrictForPrice">*</span>}</div>
                                    </div>
                                    <p>{item.desc}</p>

                                    <div className="bottom-row">
                                        <div className="socialFollowers">
                                            <img src="/images/tiktok.png" alt="tiktok" />{(props.tiktokModul.length > 1 && tiktokitem.followers_count != "") && tiktokitem.followers_count + ' Followers'}
                                        </div>
                                        <button className="add-to-cart" onClick={() => props.addToCart(tiktokitem, item, props.tiktokModul.length)}>Add to Cart</button>
                                    </div>
                                </div >
                            })}
                    </>
                })}




            {((props.package == 'all' || props.package == 'youtube') && (props.youtubeModul.length > 0)) &&

                props.youtubeModul.map(youtubeitem => {
                    return <>
                        {

                            youtubeitem.rates.map((item) => {
                                return < div className="package">
                                    <div className="packge-head-wrap">
                                        <h2>{item.rateName}</h2>
                                        <div className="price divForastrictForPrice">${item.price}{props.influencerUserType != 'registered' &&
                                            <span className="asterisk astrictForPrice">*</span>}</div>
                                    </div>
                                    <p>{item.desc}</p>

                                    <div className="bottom-row">
                                        <div className="socialFollowers">
                                            <img src="/images/youtube.png" alt="youtube" /> {(props.youtubeModul.length > 1 && youtubeitem.followers_count !== "") && youtubeitem.followers_count + " Followers"}
                                        </div>
                                        <button className="add-to-cart" onClick={() => props.addToCart(youtubeitem, item, props.youtubeModul.length)}>Add to Cart</button>
                                    </div>
                                </div >

                            })}
                    </>
                })}
            {((props.package == 'all' || props.package == 'facebook') && (props.fcbkModul.length > 0)) &&

                props.fcbkModul.map(facebookitem => {
                    return <>
                        {

                            facebookitem.rates.map((item) => {
                                return < div className="package">
                                    <div className="packge-head-wrap">
                                        <h2>{item.rateName}</h2>
                                        <div className="price divForastrictForPrice">${item.price}
                                            {props.influencerUserType != 'registered' &&
                                                <span className="asterisk astrictForPrice">*</span>}</div>
                                    </div>
                                    <p>{item.desc}</p>

                                    <div className="bottom-row">
                                        <div className="socialFollowers">
                                            <img src="/images/facebook.png" alt="facebook" />{(props.fcbkModul.length > 1 && facebookitem.followers_count !== "") && facebookitem.followers_count + " Followers"}
                                        </div>
                                        <button className="add-to-cart" onClick={() => props.addToCart(facebookitem, item, props.fcbkModul.length)}>Add to Cart</button>
                                    </div>
                                </div >
                            })}
                    </>
                })}


        </>

    )
}
import React, { useState, useEffect } from "react";
import { Toast } from "../../Components";
import { Profile } from "../../Components";

import { arrowLeft } from "../../Components";
import {
    tickSymbol,
    cancelCircleicon

} from "../../Components";
import {
    notificationService, tamacoinService
} from '../../Services';
import { utils } from "../../Helpers/utils";
export default function PricingMain({ setToastList,
    toastList, subProductToShow, changePlan, planAmtGrowth, planAmtEssential, planType, planTypeShow, chkLogin }) {
    console.log(subProductToShow)
    return (

        <div class="pricing-container">
            <div className="heading marketingHeading">Flexible Plans, Smarter Influencer Marketing</div>
            <p className="subline">
                TAMA's AI-powered platform connects brands with the right influencers seamlessly and efficiently.
            </p>
            <div className="billing-tabs">
                <div className={"monthly-tab-btn" + (planType === 'monthly' ? ' tab-active' : '')} onClick={() => changePlan('monthly')}>Billed Monthly</div>
                <div className={"yearly-tab-btn" + (planType === 'yearly' ? ' tab-active' : '')} onClick={() => changePlan('yearly')}>Billed Yearly
                    <span className="plan-discount">Save 50%</span>
                </div>
            </div>
            <div class="pricing-container-inner">
                <div className={"pricing-card" + (subProductToShow[0].user_flag != 0 ? ' planactivated' : '')} >
                    <div className="plan-header">
                        <div className="plan-name">{subProductToShow[0].product_name}</div>
                        <p className="start-text">Start at</p>
                        <div className="plan-price" id="essentials-price">${planAmtEssential}<span>{"/"}{planTypeShow}</span></div>
                        <p className="px-25 plan-description"  >
                            {subProductToShow[0].product_description}
                        </p>
                    </div>
                    <div className="plan-footer">
                        <div className="social-icons">
                            <img src="images/instagram.png" alt="instagram" className="" />
                            <img src="images/tiktok.png" alt="tiktok" />
                            <img src="images/youtube.png" alt="Youtube" />
                        </div>
                    </div>
                    {subProductToShow[0].user_flag != 0 ?
                        <div className="purchase-button">
                            <a className="btn purchase-btn" >Active</a>
                        </div> : <div className="purchase-button">
                            <a className="btn purchase-btn" onClick={() => chkLogin(subProductToShow[0])}>Get Started</a>
                        </div>}
                    <ul class="plan-features">
                        <li>
                            <div><span>Access to vetted creators</span>
                                <div class="tooltip">
                                    <span class="question-mark">?</span>
                                    <span class="tooltiptext">Gain access to 1M+ vetted creators from various platforms to find your ideal influencer in a few clicks.
                                    </span>
                                </div></div>
                            <span className="tickClass">{tickSymbol}</span></li>
                        <li>
                            <div>
                                <span>Campaign Monitoring & Analytics</span>
                                <div class="tooltip">
                                    <span class="question-mark">?</span>
                                    <span class="tooltiptext">Track live analytics for your collaborations.</span>
                                </div></div>

                            <span className="tickClass">{tickSymbol}</span></li>
                        <li><div>
                            <span>Fraud Detection Tools</span><div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Advanced fraud detection algorithms automatically scan for fake followers, suspicious engagements, and fraudulent reach, ensuring authentic and effective collaborations.
                                </span>
                            </div></div>
                            <span className="tickClass">{tickSymbol}</span>
                        </li>
                        <li><div>
                            <span>Curate Creator Lists</span> <div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Organize and showcase your favorite creators by adding them to custom lists. Easily track and discover new content based on your interests.
                                </span>
                            </div></div><span className="tickClass">{tickSymbol}</span>
                        </li>
                        <li><div>
                            <span>AI Bulk Outreach</span><div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Automatically send personalized messages to a large group of recipients at once. Save time and increase efficiency with AI-powered outreach tailored to each contact.
                                </span>
                            </div></div> {cancelCircleicon}
                        </li>
                        <li><div><span>Creative Brief Generato</span>

                            <div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Leverage AI to automatically generate detailed, tailored creative briefs. Simply input your project details, and let the AI help you craft a clear and impactful brief in minutes.</span>
                            </div></div>
                            <span>{cancelCircleicon}</span></li>
                        <li><div>
                            <span>Creator Payment Management</span><div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Effortlessly manage and process payments for creators. Ensure timely, secure transactions with easy tracking and automated workflows.</span>
                            </div></div>
                            <span>{cancelCircleicon}</span>
                        </li>
                        <li><div>
                            <span>Tokens</span>
                            <div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Use tokens to access services like collaborations and reports. (1 token per creator offer and collaboration, 1 token per creator insight per day)
                                    .</span>
                            </div></div>
                            <span>600</span>
                        </li>
                        <li><div>
                            <span>Bulk export influencer information</span> <div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Easily download and export influencer data in bulk. Save time by organizing and accessing key details for multiple influencers at once.
                                </span>
                            </div></div><span className="tickClass">{tickSymbol}</span>
                        </li>
                        <li><div><span>1-on-1 Training and Support</span>
                            <div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Get personalized guidance and assistance tailored to your needs. Our experts are here to help you master the tools and achieve your goals.</span>
                            </div></div>
                            <span className="tickClass">{tickSymbol}</span></li>

                    </ul>
                </div >
                <div class={"pricing-card" + (subProductToShow[1].user_flag != 0 ? ' planactivated' : '')}>
                    <div class="plan-header">
                        <div class="plan-name"> {subProductToShow[1].product_name}</div>
                        <p className="start-text">Start at</p>
                        <div class="plan-price" id="growth-price">${planAmtGrowth}<span>{"/"}{planTypeShow}</span></div>
                        <p class="px-25 plan-description" >
                            {subProductToShow[1].product_description}
                        </p>
                    </div>
                    <div class="plan-footer">
                        <div class="social-icons">
                            <img src="images/instagram.png" alt="instagram" />
                            <img src="images/tiktok.png" alt="tiktok" />
                            <img src="images/youtube.png" alt="Youtube" />
                        </div>
                    </div>
                    {subProductToShow[1].user_flag != 0 ?
                        <div className="purchase-button">
                            <a className="btn purchase-btn" >Active</a>
                        </div> : <div className="purchase-button">
                            <a className="btn purchase-btn" onClick={() => chkLogin(subProductToShow[1])}>Get Started</a>
                        </div>}
                    <ul class="plan-features">
                        <li><div><span>Access to vetted creators</span><div class="tooltip">
                            <span class="question-mark">?</span>
                            <span class="tooltiptext">Gain access to 1M+ vetted creators from various platforms to find your ideal influencer in a few clicks.</span>
                        </div></div><span className="tickClass">{tickSymbol}</span></li>
                        <li><div><span>Campaign Monitoring & Analytics</span><div class="tooltip">
                            <span class="question-mark">?</span>
                            <span class="tooltiptext">Track live analytics for your collaborations.</span>
                        </div></div><span className="tickClass">{tickSymbol}</span></li>
                        <li><div>
                            <span>Fraud Detection Tools</span>
                            <div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Advanced fraud detection algorithms automatically scan for fake followers, suspicious engagements, and fraudulent reach, ensuring authentic and effective collaborations.</span>
                            </div></div>
                            <span className="tickClass">{tickSymbol}</span>
                        </li>
                        <li><div>
                            <span>Curate Creator Lists</span> <div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Organize and showcase your favorite creators by adding them to custom lists. Easily track and discover new content based on your interests.
                                </span>
                            </div></div><span className="tickClass">{tickSymbol}</span>
                        </li>
                        <li><div>
                            <span>AI Bulk Outreach</span><div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Automatically send personalized messages to a large group of recipients at once. Save time and increase efficiency with AI-powered outreach tailored to each contact.
                                </span>
                            </div></div><span className="tickClass">{tickSymbol}</span>
                        </li>
                        <li><div><span>Creative Brief Generator</span>
                            <div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Leverage AI to automatically generate detailed, tailored creative briefs. Simply input your project details, and let the AI help you craft a clear and impactful brief in minutes.</span>
                            </div></div><span className="tickClass">{tickSymbol}</span></li>
                        <li><div>
                            <span>Creator Payment Managemen</span><div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext"> Effortlessly manage and process payments for creators. Ensure timely, secure transactions with easy tracking and automated workflows.</span>
                            </div></div> <span className="tickClass">{tickSymbol}</span>
                        </li>
                        <li><div>
                            <span>Tokens </span><div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Use tokens to access services like collaborations and reports. (1 token per creator offer and collaboration, 1 token per creator insight per day)
                                </span>
                            </div></div><span>4800</span>
                        </li>
                        <li><div>
                            <span>Bulk export influencer information</span><div class="tooltip">
                                <span class="question-mark">?</span>
                                <span class="tooltiptext">Easily download and export influencer data in bulk. Save time by organizing and accessing key details for multiple influencers at once.</span>
                            </div></div> <span className="tickClass">{tickSymbol}</span>
                        </li>
                        <li><div><span>1-on-1 Training and Support</span><div class="tooltip">
                            <span class="question-mark">?</span>
                            <span class="tooltiptext">Get personalized guidance and assistance tailored to your needs. Our experts are here to help you master the tools and achieve your goals.</span>
                        </div></div><span className="tickClass">{tickSymbol}</span></li>

                    </ul>
                </div>
            </div >
        </div >);
}
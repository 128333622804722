import { useEffect, useState } from 'react';
import Modal from "../../../../Components/Base/Modal";

import { influencerlistService } from '../../../../Services';


export default function ModalAddInfToList(props) {

    //const [toastList, setToastList] = useState([]);
    const [isLoadedList, setIsLoadedList] = useState(false);
    const [listDetails, setListDetails] = useState([])
    const addToast = (data) => {
        const newToastList = [...props.toastList];
        newToastList.push({ ...data, id: `${Date.now()}-${props.toastList.length}` });
        props.setToastList(newToastList);
    };
    const showAddListModal = () => {
        props.setModal(null);
        props.setModal('addList');
    }
    const addInfluencerToList = async (list) => {

        const id = props.selectedInfluencer.id;
        const inftype = props.selectedInfluencer.inf_user_type;
        try {
            let option = { 'listId': list.id, 'influencer': id, 'action': 'assign', 'infType': inftype }
            const res = await influencerlistService.assignToList(option);
            //console.log(res.message)
            props.handleSearch();
            addToast({
                error: false,
                msg: res.message,
                id: `${Date.now()}-${props.toastList.length}`
            });
            props.setModal(null);

        } catch (err) {
            addToast({
                error: true,
                msg: err,
                id: `${Date.now()}-${props.toastList.length}`
            });
        }
    }

    useEffect(() => {
        async function getAllList() {
            try {

                const res = await influencerlistService.getAll({});
                //console.log(res);
                setListDetails(res.data)
                setIsLoadedList(true)
            } catch (err) {
                addToast({
                    error: true,
                    msg: err,
                    id: `${Date.now()}-${props.toastList.length}`
                });
            }
        }
        getAllList();
    }, []);
    return (
        <Modal addClass="modalCamp" close={props.close}>
            <div className="filterModal modal__camp">
                <div className="modal__assetDetail-delete perm long lock-feture-screen">

                    <div className="modal-add-list">
                        <div className="list-wrapper create-list" onClick={showAddListModal}>
                            <div className="list-img-wrapper">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    fill="#FFFFFF"
                                    className="bi bi-plus-lg"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                                    />
                                </svg>
                            </div>
                            <div className="list-info-wrapper" >
                                <div className="list-title" >Create new list</div>
                            </div>
                        </div>
                        {listDetails.length > 0 &&

                            listDetails.map(list => {
                                return <div className="list-wrapper add-list" data-id="17105" onClick={() => addInfluencerToList(list)}>
                                    <div className="list-img-wrapper">
                                        <img
                                            className="list-img"
                                            src={
                                                list.image && list.image !== ''
                                                    ? list.image
                                                    : `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`
                                            }
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`;
                                            }}
                                            alt='avatar'

                                        />
                                    </div>
                                    <div className="list-info-wrapper">
                                        <div className="list-title">{list.name}</div>
                                        <div className="list-subtitle">{list.infcount} influencer</div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
}

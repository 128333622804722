import React, { useState, useEffect } from 'react';
import { Modal } from '../../..';
import {
  influencerService
} from '../../../../Services';

export default function ModalRevisionConfirm(props) {
  const [data, setData] = useState(props);
  const [error, setError] = useState(false);

  const onConfirm = async () => {
    try {
      const param =
      {
        status: "approved"
      };

      //console.log(props);
      await influencerService.upadateRevisionDetails(data.data.id, param);
      props.close();
      props.addToast({ error: false, date: new Date() });
      let pageSize = 10;
      let filterStr = 'campaign_id=' + props.campaignId;
      const res = await influencerService.getRevisionWithStatus({
        page: 1,
        pageSize,

      }, '', filterStr
      );
      if (res.meta && res.meta.pagination) {
        // props.setPaginationData(res.meta.pagination);
      }
      props.setInfEngList(res.data);
      props.setLoadList(true);
      props.setModal(null);
    } catch (err) {
      props.close();
      props.addToast({
        error: true,
        date: new Date(),
        msg: 'Error!' //err.replaceAll(/<\/?[^>]+(>|$)/gi, '')
      });

    }
  };
  return (
    <Modal addClass='modalRateConnect' close={props.close}>
      <div className='modal__rate'>
        <h6 className='mb'>
          Are you sure you want approve this revision?
        </h6>
        <div className='modal__rate-btns'>
          <button className='button secondary' onClick={props.close}>
            Cancel
          </button>
          <button
            className='button primary'
            onClick={() => onConfirm()}
          >
            Approve
          </button>
        </div>
      </div>
    </Modal>
  );
}

import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import { format } from 'date-fns';
import {

  reportService
} from '../../Services';
import { utils } from '../../Helpers/utils';
import { EditorInput, playIconReport, pauseIconReport, closeIcon } from "../../Components";
import { TextInput, SelectInputFilter, DateInput, calendarIcon } from "../../Components";
import { EditorState, convertToRaw, convertFromRaw, ContentState } from "draft-js";


import AddInfluencer from "./AddInfluencer";
const initForm = {
  title: '',
  description: "",
  start_date: '',
  end_date: '',
  status: '',
  platform_id: '',
  user_id: '',
  influencer_report: [],
};

export default function CreateReport({ tokenAvailable, platform, restoreReport, statusActivePaused, setConfirmFor, currentStatus, updateStatus, tab, addToast, setTab, setToastList, toastList, selectedReport }) {
  const [data, setData] = useState(selectedReport ? selectedReport.influencer_report : [{ key: Date.now(), 'infHandle': '', 'handle_id': '', 'hash_tag': '', 'token': "-" }]);
  const [error, setError] = useState(false);
  const [errorName, setErrorName] = useState(false);

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [form, setForm] = useState(selectedReport ? selectedReport : initForm);
  const [readonly, setReadOnly] = useState(false);
  const [infError, setInfError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  let contentState = "";
  let descHasValidJson = utils.testJSON(form.description);
  //console.log(descHasValidJson)
  if (descHasValidJson === true) {
    //console.log(JSON.parse(form.description));
    contentState = convertFromRaw(
      form.description ? descHasValidJson === true ? JSON.parse(form.description) : JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`) : JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`)
    );
  } else {
    if (form.description !== null) {
      contentState = (ContentState.createFromText(form.description));
    } else {
      contentState = convertFromRaw(JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`));
    }

  }
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState)
  );
  useEffect(() => {
    setConfirmFor("");
    if (selectedReport && tab == 'edit') {
      //setData(selectedReport.influencer_report)
    }
    if (selectedReport !== "" && tab == 'edit') {
      if (selectedReport.status == 'archived') {
        setReadOnly(true);
      }
    }
  }, []);

  useEffect(() => {
    //setData(data)
    updateForm({ influencer_report: data })
  }, [data]);

  const updateTextDescription = async (state) => {
    await setEditorState(state);
    const rawContentState = convertToRaw(state.getCurrentContent());
  };
  const setDesc = key => event => {
    updateForm({ ['description']: JSON.stringify(event) });
  };






  const updateForm = (data) => {

    setForm({ ...form, ...data });
  };
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });



  const chkEditorVal = (item) => {
    let editorError = false;
    if (item === "") {
      return editorError = true;
    } else {

      //  let isValidJson = utils.testJSON(item);
      let isValidJson = true;
      if (isValidJson) {
        if (JSON.parse(item).blocks[0].text === "") {
          return editorError = true;
        } else {
          return false;
        }
      }
    }
  }

  const validateReport = () => {

    let isFieldsEmpty = false;
    let lengthError = false;
    let lengthOfDescription = isFieldsEmpty === false && form.description !== "" ? (convertFromRaw(JSON.parse(form.description)).getPlainText().length) : 0;
    const dt1 = new Date(form.start_date);
    const dt2 = new Date(form.end_date);
    const dt3 = new Date();

    let findInStartDate = Math.floor(
      (Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) -
        Date.UTC(dt3.getFullYear(), dt3.getMonth(), dt3.getDate())) /
      (1000 * 60 * 60 * 24)
    );
    let findDiffInDays = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
    );
    setInfError(false)
    let isInformationOfInf = false;
    data.map(item => {
      if (item.hash_tag == "" || item.infHandle == "") {
        isInformationOfInf = true;
        setInfError(true)
      }
    })

    //console.log("==" + isInformationOfInf)
    if (
      form.title === "" ||
      form.platform_id == "" ||
      chkEditorVal(form.description) === true ||
      form.start_date === "" ||
      form.end_date === "" ||
      isInformationOfInf == true
    ) { isFieldsEmpty = true; }
    let msgError = "";

    if (lengthOfDescription > 1500 || form.title.length > 60) {
      lengthError = true;
    }

    if (isFieldsEmpty === true || (findInStartDate < 0) || findDiffInDays < 0 || lengthError === true || form.platform_id == "") {

      if (isFieldsEmpty === true) {
        setError(true);
        msgError = "Please enter all required field to add/edit report.";
      } else {
        if (lengthError === true) {
          if (form.title.length > 60) {
            msgError = "Title length of title is greater then 60 character.";
            setError(true);
          }
        } else if (findDiffInDays < 0) {
          msgError = "Start date must be a date before end date.";
          setError(true);
        }
        else if (findInStartDate < 0) {
          msgError = "Start date can not be date before today's date.";
          setError(true);
        }
        else if (lengthOfDescription > 1500) {
          msgError = "Description content length is greater then 1500.";
          setError(true);
        }



      }
      addToast({ error: true, date: new Date(), msg: msgError });
    } else {
      setError(false);
      msgError = "";
      if (tab == 'edit') {
        updateReport()
      } else {
        saveReport()
      }
      //console.log(form)
    }
  }
  async function updateReport() {
    try {
      let param = {
        title: form.title,
        description: form.description,
        start_date: form.start_date ? format(new Date(form.start_date), 'yyyy-MM-dd') : '',
        end_date: form.end_date ? format(new Date(form.end_date), 'yyyy-MM-dd') : '',
        status: form.status == "" ? 'active' : form.status,
        ReportItem: form.influencer_report,
        platform_id: form.platform_id,
      }

      const res = await reportService.updateReport(param, selectedReport.id);
      addToast({
        error: false,
        msg: res.message,
        id: `${Date.now()}-${toastList.length}`
      });
      setTab("main");
      setIsLoaded(true)
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  async function saveReport() {
    try {
      let param = {
        title: form.title,
        description: form.description,
        start_date: form.start_date ? format(new Date(form.start_date), 'yyyy-MM-dd') : '',
        end_date: form.end_date ? format(new Date(form.end_date), 'yyyy-MM-dd') : '',
        status: form.status == "" ? 'active' : form.status,
        ReportItem: form.influencer_report,
        platform_id: form.platform_id,


      }
      //console.log(param)
      const res = await reportService.saveReport(param);
      addToast({
        error: false,
        msg: res.message,
        id: `${Date.now()}-${toastList.length}`
      });
      setTab("main")

      setIsLoaded(true)
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const changeSelectedPlatform = (item) => {
    updateForm({ platform_id: item.id })
  }
  function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }
  return (
    <>
      {(true) && (
        <>
          <div className="campaignsCreate">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              exit={{ opacity: 0, y: 30 }}
              className="form"
            >
              <div className="reportMain1">
                <div className="big reportInfo">Tokens: Available {tokenAvailable ? tokenAvailable.available : 600} | Used 0</div>
                <div className="reportMain1">
                  {form.status !== 'archived' &&
                    <><div>
                      <button className="reportPausePlay" disabled={tab == 'create' ? true : false}><div onClick={() => updateStatus('active')}>{playIconReport}</div></button>
                      <button className="reportPausePlay" disabled={tab == 'create' ? true : false}> <div onClick={() => updateStatus('paused')}>{pauseIconReport}</div></button>
                    </div></>
                  }
                  <div className="big reportInfo minStatusDiv"> Status {statusActivePaused && statusActivePaused != "" ? "- " + capitalizeFirstLetter(statusActivePaused) : form.status !== "" ? "- " + capitalizeFirstLetter(form.status) : '-'}</div>

                </div>
                {/*} */}

              </div>
              <div className="form__title reportMain1">

              </div>
              <div className="formCampaigns__detail">
                <div className="formCampaigns__detail-desc">
                  <TextInput
                    value={form.title}
                    type="text"
                    onChange={setInput("title")}
                    label="Title"
                    error={error || errorName}
                    errorText="Please enter Title"
                    readOnly={readonly}
                  />
                </div>
                <div className="formCampaigns__detail-deliver">
                  <SelectInputFilter
                    selected={platform.filter(item => item.id === form.platform_id).length > 0 ? platform.filter(item => item.id === form.platform_id)[0] : ''}
                    onChange={changeSelectedPlatform}
                    list={platform}
                    value={platform.filter(item => item.id === form.platform_id).length > 0 ? platform.filter(item => item.id === form.platform_id)[0].name : ''}
                    error={error || errorName}
                    errorText="Please select Platform"
                    label="Platform"
                    setIsOpen={setIsOpen}
                    readOnly={readonly}
                    setGenderIsOpen={() => { }}
                  />
                </div>
              </div>
              <div className="formCampaigns__detail">
                <div className="formCampaigns__detail-desc">
                  <EditorInput
                    value={form.description === "" ? form.description : utils.testJSON(form.description) === true ? JSON.parse(form.description).blocks : ""}
                    editorState={editorState}
                    label="Description"
                    onEditorStateChange={updateTextDescription}
                    error={error}
                    errorText="Please enter description"
                    onChange={setDesc(convertToRaw(editorState.getCurrentContent()))}
                    readOnly={readonly}
                  />
                </div>
              </div>
              <div className="formCampaigns__detail">
                <div className="formCampaigns__detail-desc">
                  <div className="formCampaigns__comp-main-dates widthReportDate">
                    <div className="formCampaigns__comp-main-date reportDate">
                      <DateInput
                        startDate={form.start_date}
                        placeholder="MM / DD / YYYY"
                        onChange={(date) => updateForm({ start_date: date })}
                        label="Start date"
                        icon={calendarIcon}
                        error={error}
                        value={form.start_date}
                        errorText="Please enter Start date"
                        readOnly={readonly}
                        minDate={new Date()}

                      />
                    </div>
                    <div className="formCampaigns__comp-main-date reportDate">
                      <DateInput
                        startDate={form.end_date}
                        placeholder="MM / DD / YYYY"
                        onChange={(date) => updateForm({ end_date: date })}
                        label="End date"
                        icon={calendarIcon}
                        value={form.end_date}
                        error={error}
                        errorText="Please enter End date"
                        readOnly={readonly}
                        minDate={form.start_date}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="formCampaigns__detail">

                <div className="addInf">
                  <div><hr className="custom-hr" ></hr>
                  </div>
                  <div>
                    <AddInfluencer status={form.status} infError={infError} error={error} data={data} setData={setData} setInput={setInput} startDate={form.start_date} endDate={form.end_date} />
                  </div>
                  <div></div>
                </div>

              </div></motion.div><div class="campaignsCreate__btns">
              <div class="campaignsCreate__btns-row">
                <button class="button secondary" onClick={() => setTab('main')}>Cancel</button>

              </div>
              {form.status !== 'archived' ?
                <button class="button primary" onClick={() => validateReport()}>

                  {tab == "create" ? "Create Report" : 'Save'}</button> :
                <button class="button primary" onClick={restoreReport}>

                  Restore Report</button>
              }
            </div>

          </div ></>

      )
      }
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Modal } from "../../../../Components";
import {
  arrowLeft,
  arrowRight,
  chevronBottom,
  trashIcon,
} from "../../../../Components";

export default function ModalAssetDetail(props) {
  //console.log(props);
  const [showMore, setShowMore] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [modul, setModul] = useState(props.campaigns ? props.campaigns.slice(0, 3) : []);
  const [tabModal, setTabModal] = useState("main");
  useEffect(() => {
    if (viewAll) {
      setModul(props.campaigns ? props.campaigns : []);
    } else {
      setModul(props.campaigns ? props.campaigns.slice(0, 3) : []);
    }
  }, [viewAll]);
  const delBtnClick = () => {
    props.delBtnClick();
    props.close();
  };
  return (
    <Modal addClass="modalAsset" close={props.close}>
      <div
        className={
          "modal__asset " +
          (props.modal === "assetDetail" ||
            props.modal === "assetDetailGrid" ||
            props.modal === "assetDetailGood" ||
            props.modal === "assetDetailGridGood"
            ? "pt24"
            : "")
        }
      >
        {(props.modal === "assetDetail" ||
          props.modal === "assetDetailGrid" ||
          props.modal === "assetDetailGood" ||
          props.modal === "assetDetailGridGood") &&
          tabModal === "main" && (
            <div className="modal__asset-back">
              <button className="button tertiary big" onClick={props.backClick}>
                {arrowLeft} Back
              </button>
            </div>
          )}
        <div className="modal__asset-inner">
          {tabModal === "main" && (
            <div className="modal__assetDetail">
              {/*} <button
                className="button tertiary big grey"
                onClick={() => setTabModal("delete")}
              >
                {trashIcon} Delete
          </button>*/}
              <div className="modal__assetDetail-head">
                <h5>{props.titleModal}</h5>
                <h3>{props.title}</h3>
              </div>
              <div
                className="modal__assetDetail-media"
                onClick={props.mediaClick}
              >
                <div className="modal__assetDetail-media-outer">
                  <div className="modal__assetDetail-media-inner">
                    {props.media_type_id === 2 && (
                      <img src={process.env.PUBLIC_URL + props.thumbnail} alt="" />
                    )}
                    {props.media_type_id === 1 && (
                      <video src={process.env.PUBLIC_URL + props.mediafile}></video>
                    )}
                  </div>
                </div>

                <div className="modal__assetDetail-media-content">
                  <div className="modal__assetDetail-media-content-item">
                    <h6>File type</h6>
                    <p>{props.file_type && props.file_type.toUpperCase()}</p>
                  </div>
                  <div className="modal__assetDetail-media-content-item">
                    <h6>File Size</h6>
                    <p>{props.file_size && (props.file_size / 1048576).toFixed(2)} MB</p>
                  </div>
                  {props.assetType === "video" && (
                    <div className="modal__assetDetail-media-content-item">
                      <h6>Resolution:</h6>
                      <p>150x150</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal__assetDetail-content">
                <h5>{props.titleModal}</h5>
                <h3>{props.title}</h3>
                <div className="modal__assetDetail-content-desc">
                  <h5>Description</h5>
                  <p>
                    {showMore
                      ? props.description
                      : props.description.toString().substring(0, 305) + "..."}
                    {props.desc && props.desc.length > 305 && (
                      <button
                        type="button"
                        className="button tertiary"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? "Show less" : "Show more"}
                      </button>
                    )}
                  </p>
                </div>
                <div className="modal__assetDetail-content-date">
                  <h6>Upload date:</h6>
                  <p>{props.created_at}</p>
                </div>
                <div className="modal__assetDetail-campaign">
                  <h5 className={props.campaigns.length <= 0 ? "mb" : ""}>
                    Campaigns using this asset:{" "}
                    <span>{props.campaigns.length <= 0 && "None"}</span>
                  </h5>
                  {props.campaigns.length > 0 && (
                    <>
                      <div className="modal__assetDetail-campaign-items">
                        {modul.map((item, index) => {
                          return (
                            <div
                              className="modal__assetDetail-campaign-item"
                              key={index}
                            >
                              <h6>{item.title}</h6>
                              <button className="button tertiary big right">
                                View campaign details {arrowRight}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                      {props.campaigns.length > 3 && (
                        <div className="modal__assetDetail-campaign-view nomb">
                          <button
                            className={
                              "button tertiary big right " +
                              (viewAll ? "active" : "")
                            }
                            onClick={() => setViewAll(!viewAll)}
                          >
                            {viewAll ? "View less" : "View all"}
                            {chevronBottom}
                          </button>
                          {!viewAll && (
                            <p className="sm">
                              And {props.campaigns.length} others
                            </p>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {tabModal === "delete" && (
            <div className="modal__assetDetail-delete">
              <h4>Are you sure you want to delete this asset?</h4>
              <div className="modal__assetDetail-delete-btns">
                <button
                  className="button secondary"
                  onClick={() => setTabModal("main")}
                >
                  Cancel
                </button>
                <button className="button primary" onClick={delBtnClick}>
                  Delete asset
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

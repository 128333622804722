import React, { useState, useEffect, useRef } from "react";
import { Toast, Profile } from "../../Components";
import BrandProfilePass from "./BrandProfilePass";
import BrandProfileCompany from "./BrandProfileCompany";
import BrandPointOfContact from "./BrandPointOfContact";
import {
  profileService,
  accountService,
  influencerService
} from '../../Services';
/*const initForm = {
  companyName: "",
  file: "",
  firstName: "",
  lastName: "",
  jobTitle: "",
  email: "",
  phone: "",
  contactMethod: "",
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};*/

export default function BrandProfile({ closeCartPanel, cartItem, setAuth, setModal, NotifModul, profiles, user, setModalNotification }) {
  const [form, setForm] = useState({});
  const [toastList, setToastList] = useState([]);
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [isSelectBoxIsSelected, setIsSelectBoxIsSelected] = useState(false);
  const userData = accountService.userValue;
  const userId = userData.data.id;
  let refCompany = useRef(null);
  let refPoc = useRef(null);
  let refPass = useRef(null);
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };

  const setInput = (section, key) => (event) =>
    updateForm({ [section]: event.target.value });

  const setInputBrand = (section, key) => (event) =>
    updateForm({ [section]: { ...form.brand, [key]: event.target.value } });

  const setInputPointOfContact = (section, key) => (event) =>
    updateForm({ [section]: { ...form.pointofContact, [key]: event.target.value } });

  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        enterCalled();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [form]);
  const enterCalled = () => {
    let company = document.activeElement.parentElement.closest('.form__company');
    let poc = document.activeElement.parentElement.closest('.form__point');
    let passwordEntered = document.activeElement.parentElement.closest('.form__brandpass');
    if (company) {
      refCompany.current.click();
    }
    if (poc || isSelectBoxIsSelected) {
      refPoc.current.click();
    }
    if (passwordEntered) {
      refPass.current.click();
    }
  }

  const saveChanges = (type) => {
    if (type === "company") {
      if (form.brand.brand_name === "" || form.brand.photo === "") {
        addToast({
          error: true,
          msg: "Please enter Company name and upload file to save",
          date: new Date(),
        });
      } else {
        saveBrandDetails(userId, {
          request_type: "user_profile",
          brand_name: form.brand.brand_name,
          brand_photo: form.brand.photo ? form.brand.photo.substring(form.brand.photo.lastIndexOf("/") + 1) : "",
          photo: form.brand.photo ? form.brand.photo.substring(form.brand.photo.lastIndexOf("/") + 1) : "",
          firstName: form.brand.first_name,
          lastName: form.brand.last_name,
          email: form.email,
          title: form.title ? form.title : "",
        });
      }
    } else if (type === "point") {
      if (
        !form.pointofContact.first_name ||
        form.pointofContact.first_name === "" ||
        !form.pointofContact.last_name ||
        form.pointofContact.last_name === "" ||
        form.pointofContact.job_title === "" ||
        !form.pointofContact.job_title ||
        !form.pointofContact.email ||
        form.pointofContact.email === "" ||
        !form.pointofContact.contact_no ||
        form.pointofContact.contact_no === "" ||
        !form.pointofContact.preferred_contact_type ||
        form.pointofContact.preferred_contact_type === ""
      ) {
        addToast({
          error: true,
          msg: "Please enter all details to save",
          date: new Date(),
        });
      } else {
        form.pointofContact.request_type = "contact_info";
        saveBrandDetails(userId, form.pointofContact);
      }
    } else if (type === "pass") {
      if (
        form.currentPassword === "" ||
        form.newPassword === "" ||
        form.confirmNewPassword === "" ||
        form.currentPassword.length < 8 ||
        form.newPassword < 8 ||
        form.confirmNewPassword < 8
      ) {
        addToast({ error: true, date: new Date(), msg: 'Password details required' });
      } else {
        var passwordFormat = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (form.newPassword.match(passwordFormat)) {
          if (form.newPassword !== form.confirmNewPassword) {
            addToast({ error: true, date: new Date(), msg: 'New Password and Confirm Password is not same' });
          } else {
            saveBrandDetails(form.id, {
              request_type: "password",
              password: form.currentPassword,
              confirmPassword: form.newPassword,
            });
          }
        } else {

          addToast({
            error: true,
            msg: "Password should have atleast one uppercase, one lowercase letter, one number and one special character",
            successText: '',
            date: new Date()
          });
        }
      }
    }
  };
  const saveBrandDetails = async (userId, reqBody) => {
    try {
      await profileService.updateBrandDetails(userId, reqBody);
      await accountService.refreshToken(function () { });
      addToast({ error: false, date: new Date() });
      setIsSelectBoxIsSelected(false)
    } catch (err) {
      addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, "") });
    }
  };
  useEffect(() => {
    async function fetchBrandData() {
      try {
        const res = await profileService.getBrandDetails(userId);
        res.data.brand = res.data.brands ? Object.assign({}, res.data.brands[0]) : {};
        res.data.pointofContact = res.data.contact_info ? Object.assign({}, res.data.contact_info[0]) : {};

        res.data.currentPassword = '';
        res.data.newPassword = '';
        res.data.confirmNewPassword = '';
        delete res.data.brands;
        delete res.data.contact_info;
        setForm(res.data);
        setIsFormLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          date: new Date()
        });
      }
    }
    fetchBrandData();
  }, []);
  useEffect(() => {
    const windowClick = ({ target }) => {
      if (target.classList.contains('select__selected') == true || target.classList.contains('select__option') == true) {
        setIsSelectBoxIsSelected(true);
      }
    };
    window.addEventListener("click", windowClick);
    return () => window.removeEventListener("click", windowClick);
  }, [form]);
  return (
    <>
      <div className="main">
        <div className="main__inner">
          <div className="main__inner-title">
            <h2>Brand profile</h2>
            <Profile
              type="brand"
              setAuth={setAuth}
              setModal={setModal}
              NotifModul={NotifModul}
              profiles={profiles}
              user={user}
              closeCartPanel={closeCartPanel}
              setNotifModul={setModalNotification}
              cartItem={cartItem}
            />
          </div>
          {isFormLoaded &&
            <div className="brandprofile">
              <BrandProfileCompany
                form={form}
                setInput={setInputBrand}
                updateForm={updateForm}
                saveChanges={saveChanges}
                setToastList={setToastList}
                toastList={toastList}
                refCompany={refCompany}
              />
              <BrandPointOfContact
                form={form}
                setInput={setInputPointOfContact}
                updateForm={updateForm}
                saveChanges={saveChanges}
                refPoc={refPoc}
              />
              {isFormLoaded && form.reset_password === 1 && (
                <BrandProfilePass
                  setInput={setInput}
                  form={form}
                  saveChanges={saveChanges}
                  refPass={refPass}
                />
              )}
            </div>
          }
        </div>
      </div>

      {toastList && <Toast list={toastList} setList={setToastList} />}
    </>
  );
}

import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { utils } from '../../Helpers/utils';
import NotSubscribe from '../../Components/Base/Common/NotSubscribe';
import {
  Profile,
  Toast
} from '../../Components';
import OfferItem from './OfferItem';

import { offerService, orderService } from '../../Services';
import OrderCompleteDetails from './OrderCompleteDetail';
import { useSearchParams, useParams } from 'react-router-dom';
const selectDescList = [
  { id: '1', value: 'All Offer' },
  { id: 'status', value: 'Status' },
  { id: 'type', value: 'Offer type' },
  { id: 'rate', value: 'Rate' }
];

export default function OrderDetails({
  setAuth,
  setModal,
  NotifModul,
  user,
  profiles,
  setModalNotification,
  closeCartPanel,
  cartItem,
  orderCreated,
  setOrderCreated,
  goToPricing
}) {
  // eslint-disable-next-line


  const [isOrderloaded, setOrderIsLoaded] = useState(false);
  const [isOfferloaded, setOfferIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderOfferDetails, setOrderOfferDetails] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [toastList, setToastList] = useState([]);
  const [searchList, setSearchList] = useState(false);
  const [disableSearchBox, setDisableSearchBox] = useState(false);
  const [offerDetails, setOfferDetails] = useState([]);

  const [isUserHasSubscription, setUserHasSubscription] = useState(false);
  // const urlOrderId = searchParams.get('id') ? searchParams.get('id') !== null || searchParams.get('id') !== undefined ? searchParams.get('id') : null : null;
  //const urlOrderId = 14;
  //let params = useParams()
  const params = useParams();
  const urlOrderId = params.id; // Access the 'id' parameter

  const location = useLocation();
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };

  useEffect(() => {
    async function fetchOrderData() {

      try {

        const res = await orderService.getOrderById({
          id: urlOrderId,

        });
        if (res.data) {
          setOrderDetails(res.data);

        }
        setOrderIsLoaded(true);
        // setOrderCreated('')
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    async function fetchOfferData() {
      console.log(orderCreated)
      try {

        const res = await offerService.getOfferByOrderId(urlOrderId);
        console.log(res);
        if (res.length > 0) {
          setOfferDetails(res);


        }
        setOfferIsLoaded(true);
        // setOrderCreated('')
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }

    fetchOrderData();
    fetchOfferData();

  }, []);
  //let successMessage = searchParams.get('success') !== null ? searchParams.get('success') == 1 ? "Plan  is successfully subscribe." : '' : '';
  useEffect(() => {
    orderCreated &&
      addToast({
        error: false,
        msg: "Order Created successfully.",
        id: `${Date.now()}-${toastList.length}`
      });
    setOrderCreated(false);

  }, []);

  useEffect(() => {
    setUserHasSubscription(user !== null ? user.data.isUserHasSubscription == 1 ? true : false : false)
  }, [user]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        search();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);





  const search = () => {
    setSearchList(!searchList);
    setPage(1);

  };

  return (
    <>
      {isUserHasSubscription === true && <>
        <div className='main'>
          <div className='main__inner'>
            <div className='main__inner-title'>
              <h2>Order</h2>
              <Profile
                type='brand'
                setAuth={setAuth}
                setModal={setModal}
                NotifModul={NotifModul}
                user={user}
                profiles={profiles}
                setNotifModul={setModalNotification}
                closeCartPanel={closeCartPanel}
                cartItem={cartItem}
              />
            </div>
            {isOrderloaded && isOfferloaded && offerDetails.length > 0 ? (
              <div className='billing dashboard'>
                <div className='billing__table-title'>
                  <h4>Order Details</h4>
                </div>
                {isOfferloaded && isOrderloaded && offerDetails.length > 0 &&
                  < OrderCompleteDetails orderCreated={orderCreated} order={orderDetails} offer={offerDetails} profiles={profiles}></OrderCompleteDetails>}
              </div>) :
              (offerDetails.length == 0 && isOfferloaded && isOrderloaded) &&
              <div className="order-details">
                <div className='billing dashboard'>
                  <div className='billing__table-title'>
                    <h4>Order Details</h4>
                  </div>
                  <div className="order-card">
                    <div className="">
                      Please check order Id is correct. We are not getting any order with Order Id  {"- " + urlOrderId}
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div >
        {toastList && <Toast list={toastList} setList={setToastList} />
        }</>
      }
      {isUserHasSubscription === false && user !== null && (
        <>
          <NotSubscribe
            goToPricing={goToPricing}
            type='brand'
            setAuth={setAuth}
            setModal={setModal}
            NotifModul={NotifModul}
            setNotifModul={setModalNotification}
            profiles={profiles}
            closeCartPanel={closeCartPanel}
            user={user}
            cartItem={cartItem}
            title={"Order"}>
          </NotSubscribe>
        </>
      )
      }
    </>
  );
}

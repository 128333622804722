import { BehaviorSubject } from 'rxjs';
import { fetchWrapper, history, storageHandler } from '../Helpers';
import { format } from 'date-fns';
const userSubject = new BehaviorSubject(null);
const baseUrl = `${process.env.REACT_APP_API_URL}/users`;

export const accountService = {
  login,
  validateEmail,
  loginViaToken,
  logout,
  refreshToken,
  register,
  registerInfluencer,
  tamayouInfluencerRegister,
  verifyEmail,
  forgetPassword,
  validateResetToken,
  resetPassword,
  getUserTerms,
  saveUserTerms,
  getUserTermsByRole,
  getAll,
  getById,
  create,
  update,
  switchProfile,
  getProfiles,
  sendMail,
  forgotPassworkCheck,
  getRole,
  impersonateUser,
  getImpersonateData,
  userImpersonateEnd,
  impersanationTimeExpires,
  redirectToActiveUser,
  resendValidateEmail,
  delete: _delete,
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  }
};

function login(email, password, subsProductId) {
  return fetchWrapper
    .post(`${process.env.REACT_APP_API_URL}/login`, { email, password, subsProductId })
    .then(user => {
      if (user['data']['roles']) {
        if (user['data']['roles'].length > 0) {
          user.data.role = user['data']['roles'].filter(
            item => item['id'] == user['data']['active_role']
          )[0]['name'];
        }
        userSubject.next(user);
        storageHandler.setToken(userSubject.value.access_token);

        return user;
      } else {
        return user;
      }

    });
}

function validateEmail(validateId) {
  return fetchWrapper
    .post(`${process.env.REACT_APP_API_URL}/validateEmail`, { validateId })
    .then(validateEmailData => {
      return (validateEmailData);
    });
}
function resendValidateEmail(userId) {
  return fetchWrapper
    .post(`${process.env.REACT_APP_API_URL}/resendValidateEmail`, { userId })
    .then(res => {
      return (res);
    });
}
function logout(callback) {
  // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
  fetchWrapper.post(`${process.env.REACT_APP_API_URL}/logout`, {});
  storageHandler.clearStorage();
  userSubject.next(null);
  if (callback) {
    callback();
  } else {
    window.location = '/#/account/login';
  }
}

function refreshToken(callback) {
  return fetchWrapper
    .post(`${process.env.REACT_APP_API_URL}/refresh-token`, {})
    .then(
      user => {
        if (user['data']['roles'].length > 0) {
          user.data.role = user['data']['roles'].filter(
            item => item['id'] == user['data']['active_role']
          )[0]['name'];
        }
        // publish user to subscribers and start timer to refresh token
        userSubject.next(user);
        storageHandler.setToken(userSubject.value.access_token);

        callback();
        // return user;
      },
      () => {
        callback();
      }
    );
}

function register(params) {
  return fetchWrapper.post(`${process.env.REACT_APP_API_URL}/register`, params);
}

function registerInfluencer(params) {
  return fetchWrapper.post(
    `${process.env.REACT_APP_API_URL}/influencer/invitation`,
    params
  );
}

function tamayouInfluencerRegister(params) {
  return fetchWrapper.post(
    `${process.env.REACT_APP_API_URL}/tamayou_influencers`,
    params
  );
}

function verifyEmail(id) {
  return fetchWrapper.put(
    `${process.env.REACT_APP_API_URL}/billing/token/${id}`
  );
}

function forgetPassword(email) {
  return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/reset-password`, {
    email
  });
}

function validateResetToken(token) {

  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/validate-reset-token/${token}`);
}
function sendMail(param) {
  return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/user/sendUserEmail`, { param });
}

function resetPassword(token, password, confirmPassword) {
  return fetchWrapper.put(
    `${process.env.REACT_APP_API_URL}/reset-password/token/${token}`,
    {
      password: password,
      confirmPassword: confirmPassword
    }
  );
}

function getAll(options) {
  let filterString = '';
  if (options.filter) {
    filterString = options.filter;
  }
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }

  const url =
    filterString.length > 0 ? `${baseUrl}?${filterString}` : `${baseUrl}`;
  return fetchWrapper.get(url);
}

function getAllPaging(options, filterString) {
  filterString += filterString.length > 0 ? `&` : '';
  filterString += `page[number]=${options.page}`;
  if (options.pageSize) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[size]=${options.pageSize}`;
  }
  if (options.fileType) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[filetype]=${options.fileType}`;
  }
  return filterString;
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/userprofile/${id}`, params).then(user => {
    // update stored user if the logged in user updated their own record
    if (user.id === userSubject.value.id) {
      // publish updated user to subscribers
      user = { ...userSubject.value, ...user };
      userSubject.next(user);
    }
    return user;
  });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`).then(x => {
    // auto logout if the logged in user deleted their own record
    if (id === userSubject.value.id) {
      logout();
    }
    return x;
  });
}

function loginViaToken(token) {
  storageHandler.setToken(token);
  return fetchWrapper
    .get(`${process.env.REACT_APP_API_URL}/userInfo`)
    .then(user => {
      if (user['data']['roles'].length > 0) {
        user.data.role = user['data']['roles'].filter(
          item => item['id'] == user['data']['active_role']
        )[0]['name'];
      }
      userSubject.next(user);

      return user;
    }).catch(e => {
      // console.log("err. " + e);
    });
}

function getUserTerms() {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/termsbyuser`);
}

function saveUserTerms(payload) {
  return fetchWrapper.post(
    `${process.env.REACT_APP_API_URL}/saveuserterms`,
    payload
  );
}

function getUserTermsByRole(id) {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/termsbyrole/${id}`);
}
function getRole(id) {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/role`);
}
function switchProfile(params, callback) {
  return fetchWrapper
    .post(`${process.env.REACT_APP_API_URL}/switch`, params)
    .then(
      user => {
        if (user['data']['roles'].length > 0) {
          user.data.role = user['data']['roles'].filter(
            item => item['id'] == user['data']['active_role']
          )[0]['name'];
        }
        // publish user to subscribers and start timer to refresh token
        userSubject.next(user);
        storageHandler.setToken(userSubject.value.access_token);

        callback();
      },
      () => { }
    );
}

function getProfiles(params, callback) {
  return fetchWrapper.post(`${process.env.REACT_APP_API_URL}/profiles`, {});
}
function forgotPassworkCheck(params) {
  return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/forgotpass`, params);
}

function impersonateUser(params) {

  return fetchWrapper
    .post(`${process.env.REACT_APP_API_URL}/user/impersonateUser`, params)
    .then(
      user => {
        if (user['data']['roles'].length > 0) {
          user.data.role = user['data']['roles'].filter(
            item => item['id'] == user['data']['active_role']
          )[0]['name'];
        }
        // publish user to subscribers and start timer to refresh token
        userSubject.next(user);
        storageHandler.setToken(userSubject.value.access_token);
        if (user.impersonate_info_array) {
          //ToDo =>  we need duration to add from the response duration and not hardcoded.
          let newLocalTimeHr = user.impersonate_info_array.duration;
          console.log("I am here in impersonate user ********" + newLocalTimeHr);
          // const localExpirationTime = new Date(Date.now() + newLocalTimeHr * (60 * 60 * 1000));
          let expiryDate = new Date(Date.now() + newLocalTimeHr * (1000));
          user.impersonate_info_array.duration = format(expiryDate, 'yyyy-MM-dd HH:mm:ss');
          storageHandler.setImpersonation(JSON.stringify(user.impersonate_info_array));
          return user;
        }
      },
      () => {
      }
    );
}

function getImpersonateData() {
  let impersonationData = storageHandler.getImpersonation();
  let newImpersonationData = JSON.parse(impersonationData);
  let impersonateBarString = false;
  if (newImpersonationData) {
    impersonateBarString = "User " + newImpersonationData.fromUserName + " [" + newImpersonationData.fromUserId + "] impersonating " + newImpersonationData.targetUserName + " [" + newImpersonationData.targetUserId + "] Remaining time:";
  }
  return impersonateBarString;
}

function userImpersonateEnd() {
  return fetchWrapper
    .post(`${process.env.REACT_APP_API_URL}/user/userImpersonateEnd`)
    .then(
      user => {
        if (user['data']['roles'].length > 0) {
          user.data.role = user['data']['roles'].filter(
            item => item['id'] == user['data']['active_role']
          )[0]['name'];
        }
        // publish user to subscribers and start timer to refresh token
        userSubject.next(user);
        storageHandler.deleteImpersonationDate();
        storageHandler.setToken(userSubject.value.access_token);
        return user;
      },
      () => { }
    );
}
function impersanationTimeExpires() {
  return fetchWrapper
    .post(`${process.env.REACT_APP_API_URL}/user/userImpersonateEnd`)
    .then(
      user => {
        if (user['data']['roles'].length > 0) {
          user.data.role = user['data']['roles'].filter(
            item => item['id'] == user['data']['active_role']
          )[0]['name'];
        }
        // publish user to subscribers and start timer to refresh token
        userSubject.next(user);
        storageHandler.deleteImpersonationDate();
        storageHandler.setToken(userSubject.value.access_token);
        if (user['data']['active_role'] && user['data']['active_role'] == 1) {
          window.location = '/#/influencer/dashboard';
        }
        if (user['data']['active_role'] && user['data']['active_role'] == 3) {
          window.location = '/#/brand/dashboard';
        }
        if (user['data']['active_role'] && user['data']['active_role'] == 2) {
          window.location = '/#/admin/admin-users';
        }
      },
      () => { }
    );
}
function redirectToActiveUser() {
  let user = this.userValue;
  if (user['data']['active_role'] && user['data']['active_role'] == 1) {
    window.location = '/#/influencer/dashboard';
  }
  if (user['data']['active_role'] && user['data']['active_role'] == 3) {
    window.location = '/#/brand/dashboard';
  }
  if (user['data']['active_role'] && user['data']['active_role'] == 2) {
    window.location = '/#/admin/dashboard';
  }
}